import React, { useState, useCallback,useReducer,useEffect } from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { CommonHeading } from "../../common/commonHeading";
import Viewer from './Viewer'
import {
  append,
  compose,
  isEmpty,
  map,
  reject,
  intersperse,
  evolve,
  max,
  mapObjIndexed,values, type
} from 'ramda'
import moment from "moment";
import { getTranslatedText } from  "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";

import { Slider,Tooltip } from 'antd';
import {ReactComponent as Play} from '../../assets/images/circle-play.svg'
import {ReactComponent as Pause} from '../../assets/images/circle-pause.svg'
import LocationIcon from '../../assets/images/location.png'
import ManIcon from '../../assets/images/regularworker-purple.png'
import CHristMasManIcon from '../../assets/images/dailyworker-yellow.png'
// import mqttService from '../../components/mqttService';

import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import spinnerLoader from "../../assets/images/Spinner Loader.gif";

import ProductiveIcon from  "../../assets/images/locationProductive.png"
import DefaultIcon from  "../../assets/images/locationDefaultIcon.png"
import nonProductiveIcon from "../../assets/images/locationNonProductive.png";
import lessProductiveIcon from "../../assets/images/locationLessProductive.png";
import NewIcon from "../../assets/images/locationLessProductive2.png";
import { getCategoryList} from '../actionMethods/actionMethods'

import {getLocationCordinate} from '../actionMethods/actionMethods';
//import {getEmployeeList} from '../../manPowerManagement/actionMethods/actionMethods'
import { getDepartment,getSubDepartment } from '../../productivity/actionMethods/actionMethods';

import {getPproductivitylist} from '../../productivity/actionMethods/actionMethods'
import {getAllEmpProductivityInfo,getCycleCountPlan,getLiveData,getOnBoardEmployee,getLocationType} from '../actionMethods/actionMethods';
import { getRoleDetail } from '../../common/actionMethods';
import {ReactComponent as Eye} from '../../assets/images/eye.svg';
import ReactModal from 'react-modal';

import Scrollbars from 'react-custom-scrollbars';

import '../style/style.scss'
import { Select } from 'antd';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {ReactComponent as CircleArrow} from '../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../assets/images/caret-down.svg';

import {ReactComponent as PlayCircle} from '../../assets/images/play-circle.svg';
import {ReactComponent as PauseCircle} from '../../assets/images/pause-circle.svg';
import * as d3 from 'd3';

import CMGBulky from '../../assets/cmg_bulky.json'
import CMGManual from '../../assets/cmg_manual.json'
import ReactDOMServer from 'react-dom/server';
import { CodeSandboxSquareFilled } from '@ant-design/icons';

let dataSocket=[];   
let oldLocation=[]
//let dataSocketAll=[];   
let batch30=[];
let dataRemove =false;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    maxWidth: "80%",
    height: "650",
  },
};


const mystyle = {
  display: "none !important",
};

let layerPublish=0;
let fetchHit =0;
const FacilityVideo = (props) => {

  const { Option } = Select;
  const INITIAL_MODE = '3d'  
  const [mode, setMode] = useState('3d')
  // const [mode, setMode] = useState(INITIAL_MODE)
  const [activeTab, SetActiveTab]= useState('');
  const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
    mode
  ]);
  const [color1, setColor1] = useState('#ff0000');
  // const [color1, setColor1] = useState('#F87537');
  // const [color2, setColor2] = useState('#FBA81F');
  const [color2, setColor2] = useState('#FBA81F');
  const [color3, setColor3] = useState('#79fc03');
  // const [color3, setColor3] = useState('#ff0000');
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

    let resetTime= moment().isSame(date, 'day')?30:5;  
    const [live,SetLive]=useState(moment().isSame(date, 'day')?true:false);
  const [nextUpdate,SetNextUpdate] = useState(resetTime);

    const [permission,SetPermission] =useState([])
    const [permissionAPICall,SetPermissionAPICall] = useState(false);
    const [locationListDefault,SetLocationListDefault] =  useState([]);
    const [reloadInc,SetReloadInc] = useState(0);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [dates, setDates] = useState({start:moment.utc(),end:moment.utc()});
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const [localTime,SetLocalTime] = useState([]);
    const autoElevation = map(
      evolve({ position: { elevation: noElevationIn2D } })
    )
      
  const [space,setSpace] = useState()
  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  // let GeoWidth= userDetails && (parseInt(userDetails.org_id) == 31|| parseInt(userDetails.org_id) == 32)?1.3:4;
  // let GeoElevation= userDetails && (parseInt(userDetails.org_id) == 31|| parseInt(userDetails.org_id) == 32)?3:5;
    let GeoWidth= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?2:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34)?2.5:(userDetails && parseInt(userDetails.org_id) == 35) || (userDetails && parseInt(userDetails.org_id) == 38) || (userDetails && parseInt(userDetails.org_id) == 37)?1:4;
  let GeoElevation= userDetails && (parseInt(userDetails.org_id) == 31|| parseInt(userDetails.org_id) == 32)?3:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34) || (userDetails && parseInt(userDetails.org_id) == 35)|| (userDetails && parseInt(userDetails.org_id) == 38)||(userDetails && parseInt(userDetails.org_id) == 37)?3:5;
  
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
  let prefixURL = process.env.REACT_APP_URL;
  let userSession = userDetails ? userDetails.session:props.match.params.session?props.match.params.session:'';
  let org_id = userDetails ? userDetails.org_id : props.match.params.org_id?props.match.params.org_id:'';
  const role = props.match.params.role?props.match.params.role:null; 
  const canvasRange = {
    topRight: { x: 91.01915303072015, z: 28.32575004981593 },
    topLeft: { x: -35.2750555742273, z: 23.835903322863807 },
    bottomLeft: { x: -16.32471441542863, z: -51.90536740912293 },
    bottomRight: { x: 91.01915303072015, z: -51.90536740912293 }
  };
  const [spaceID,SetSpaceID]=useState('');
  const [locationCordinate,SetLocationCordinate]= useState([]);  
  const [employeeALL,SetEmployeeAll] =useState([]);
  const [employeeDefault,SetEmployeeDefault] =useState([]);
  const [department,SetDeparment] = useState([]);
  const [sub_department,SetSubDeparment] = useState([]);
  const [activeDepartment,SetActiveDepartment] = useState([]);
  const [activeSubDepartment,SetActiveSubDepartment] = useState([]);
  const [apiCall,SetAPICall]=useState(false)
  const [activeAccord,SetActiveAccord] =useState([]);
  const [locationList,SetLocationList] =  useState([]);
  const [loader,SetLoader]=useState(true);
  const [loaderEmp,SetLoaderEmp] =useState(false);
  const [slug,SetSlug]=useState('');
  const [modalView,SetModalView]=useState(false);
  const [modalData,SetModalData ]= useState([]);                   
  const [inc,SetInc]=useState(0)
  const [time,SetTime] =useState('');      
  const [delayTimer,SetDelayTimer]= useState(false);
  const [departmentName,SetDepartmentName]=useState([]);
  const [subDepartmentName,SetSubDepartmentName]=useState([]);
  const [playPosition,SetPlayPosition]= useState(0);
  const [currentData,SetCurrentData]= useState([]);
  const [maxValue,SetMaxValue] = useState(null);
  const onReady = useCallback(space => setSpace(space), [])
  const [timeOptions, setTimeOptions] = useState([]);
  const [startTime,SetStartTime] = useState('');
  const [displayTime,SetDisplayTime] = useState('');
  const [autoplay,SetAutoPlay] = useState(true);
  const [hoverValue, setHoverValue] = useState(null);
  const [productiveType,SetProductiveType] = useState([]);
  let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1
  const [points, dispatchPoint] = useReducer((points, action) => {
    switch (action.type) {
      case 'add':
        return [...points, action.point]
      case 'update':
        return points.map(pt =>
          pt.id === action.id ? { ...pt, ...action.updates } : pt
        )
      case 'remove':
        return reject(r => r.id === action.id)(points)
      default:
    }
  },[])

  //setting dates
  useEffect(()=>{
      var date = moment(); 
      var timeAndDate = moment(date).set({"hour": parseInt(props.workday.start_hour.split(":")[0]), "minute": parseInt(props.workday.start_hour.split(":")[1]) || 0});
    
      let dt={start:null,end:null}
      if(timeAndDate <= moment() ){        
        setDates((prev) => ({ ...prev, [`end`]:  moment(date).add(addDay,'days').toDate()})); 

      }else {  
        setDates((prev) => ({ ...prev, [`start`]:moment(date).subtract(addDay,'days').toDate()  }));
        setDates((prev) => ({ ...prev, [`end`]:  date.toDate()}));
      }
        getLocationType(userSession).then(res=>{
        if(res.status===200){

          let obj = {};
          obj.productive= res.data.find(el=>el.loc_type=="productive").id
          obj.less_productive= res.data.find(el=>el.loc_type=="less_productive").id
          obj.non_productive= res.data.find(el=>el.loc_type=="non_productive").id

          SetProductiveType(obj);
        }
      })


    },[])

    useEffect(()=>{
      if(!space){
        return
      }
      let val= generateTimeOptions(selectedDate);
      setTimeOptions(val.utc);
      SetLocalTime(val.local);


      
      let ind = 2;
        if(val.utc[val.utc.length - ind].split(":")[2]==='30'){
          ind = 3;
        }
       
      if(moment().utc().format('YYYY-MM-DD') === moment(selectedDate).utc().format('YYYY-MM-DD')){
        

        SetStartTime(val.utc[val.utc.length - ind]);
        SetDisplayTime(val.local[val.local.length - ind]);
        SetPlayPosition((prev)=>{
          return val.utc.length - ind;
        });
        
      }else{

        SetStartTime('00:00:00');
        SetPlayPosition(0);

      }
    },[selectedDate,space])

  //fetch category
  useEffect(()=>{ 
    
    let requestBody2={};
    requestBody2.session=userSession
    requestBody2.org_id=org_id

    getCategoryList(requestBody2).then(res2=>{
        if(res2.status == 200){
          let arrCat=res2.data;
          let requestBody={}
          requestBody.session= userSession;
          requestBody.org_id= org_id;
          let arr=[];

        for(let i=0;i<res2.data.length;i++){
          if(res2.data[i].location.length > 0){
            let loc = res2.data[i];
            loc.count = res2.data[i].location.length;
            arr.push(loc);
          // }
          }
        }

        SetLocationList(arr); 
        SetLocationListDefault(arr)
      
        }else{
            SetLocationList([]);                
            SetLocationListDefault([])

        }
    SetLoader(false);
})
},[])  
  
  
  const timerLive = ()=>{
    SetTime(moment().format('HH:mm:ss'));
  }
  function GetRandomDigit(min=0,max=10){
   return  Math.floor(Math.random() * (max - min + 1)) + min;
  }  


  async function processLocationCordinates(dataSocket,playPosition,maxValue,type) {
    
    for(let i=0;i<locationCordinate.length ;i++)  {
    
        let location = locationCordinate[i];
         
          try {
                  let pos = location;
                  let val = 0;
                  let svg =null;
                  let formatAr={}                     
                  let prod =0;      
                  let nonprod =0;      
                  let lessprod =0; 
                  if(dataSocket.filter(el => el.loc_id === location.tag_serial).length > 0){
                    val = ( dataSocket.filter(el => el.loc_id === location.tag_serial).length / maxValue);
                    formatAr.count= dataSocket.filter(el => el.loc_id === location.tag_serial).length
                    //here is count
                    formatAr.productive = (dataSocket.filter(el=> el.loc_id === location.tag_serial && el.productivity ==type['productive'] ).length/formatAr.count) *100; 
                    formatAr.non_productive = (dataSocket.filter(el=> el.loc_id === location.tag_serial && el.productivity == type['non_productive']).length/formatAr.count) *100; 
                    formatAr.less_productive = (dataSocket.filter(el=> el.loc_id === location.tag_serial && el.productivity == type['less_productive']).length/formatAr.count) *100; 

                  }
                  formatAr.value = val;      
                  formatAr.id= randomString(32);
                  formatAr.unique_loc_id = location.unique_loc_id
                  formatAr.type= 'add';
                  formatAr.name = location.name
                  // formatAr.value=val
                  if(pos){
                    formatAr.tag_serial =pos.tag_serial;          
            
                    formatAr.position = {
                        "levelIndex": pos?pos.position.levelIndex:0,
                        "x": pos.position.x ,
                        "z": pos.position.z ,
                        "elevation": 2
                    }
                    formatAr.time= moment().unix(); 
                    // formatAr.rssi= data.rssi; 
                    formatAr.tag_serial= location.tag_serial;
                    formatAr.loc_id= location.tag_serial;         
                  }
              // dataSocket.push(formatAr);

        if(formatAr.value > 0){
          
          let svgShape =<svg id="sw-js-blob-svg" viewBox="0 0 100 100" width={120} height={120} xmlns="http://www.w3.org/2000/svg">
        <defs>
          <radialGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
            {updateGradientReturn(formatAr.non_productive,formatAr.less_productive,formatAr.productive)}            
          </radialGradient>
        </defs>
        <path
          fill={`url(#sw-gradient)`}
          d={generatePath(2 + (20*formatAr.value),10) }
          width="100%"
          height="100%"
          transform="translate(50 50)"
          style={{ transition: 'all 0.3s ease 0s' }}
          strokeWidth="0"
        ></path>
     </svg>

          let svgString = ReactDOMServer.renderToString(svgShape);
          svg = await svgToDataURL(svgString);

            // let unID= playPosition+1;            
            space.addDataLayer({
              id: formatAr.unique_loc_id+playPosition,
              type: 'icon',
              data: [formatAr],
              tooltip: d => d.name+": "+formatAr.count ,
              icon:  {
                    url:svg,
                    width: 135,
                    height: 135,
                  },
              alpha:1,     
              width: 25,
              anchor: 'bottom',
            })
          space.removeDataLayer(formatAr.unique_loc_id+(playPosition - 1))
        }else{
          space.removeDataLayer(formatAr.unique_loc_id+(playPosition - 1))
        }
                } catch (err) {         
                }
    }

      }

      function datetime(year, month, date, hour=0,minute=0,second=0,millisecond=0){
        return 0
      }

 

useEffect(() => {
  if(!space || locationCordinate.length === 0 || permission.length === 0 && maxValue == null || startTime === "" || !autoplay) {
    return;
  }


    const abortController = new AbortController();
    const { signal } = abortController;

    let start = '';
    let end = moment(selectedDate).format('YYYY-MM-DD 23:59:59');

    if (moment(selectedDate).utc().format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      start = moment(selectedDate).utc().format('YYYY-MM-DD');
      end = '';
    } else {
      start = moment(selectedDate).set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD');
      end = moment(selectedDate).format('YYYY-MM-DD 23:59:59');
    }

    let st = startTime.split(":");
    let stime = st[0] + ":" + st[1] + ':00';

    const eventSource = new EventSource(`${prefixURL}/recording_data?session=${userDetails.session}&org_id=${userDetails.org_id}&start_time=${start} ${stime}&end_time=${end}&sub_org_id=${userDetails.sub_org_id ? userDetails.sub_org_id : 0}`, { signal });

    eventSource.onmessage = async (res) => {
      let data = JSON.parse(res.data).map(el => ({ ...el, tag_serial: el.tag_id }));
    
      dataSocket = data;
      if (layerPublish !== 1) {
        if(moment(selectedDate).utc().format('YYYY-MM-DD') === moment().utc().format('YYYY-MM-DD') ){

          if(data.length > 0){
            
              setTimeOptions((tOption)=>{
                var originalTime = tOption[tOption.length - 1];
                var date = new Date('2023-01-01 ' + originalTime);
                date.setSeconds(date.getSeconds() + 30);
                var updatedTime = date.toLocaleTimeString('en-US', { hour12: false });
                //tOption.push(updatedTime)
                var utcDate = moment.utc('2023-01-01 ' + originalTime, 'YYYY-MM-DD HH:mm:ss');
                var localDate = moment(utcDate).local();
                let index = tOption.indexOf(moment(data[0].contact_time).subtract(7,'hours').format('HH:mm:ss'));
                if(index > -1){
                  SetPlayPosition(index);
                  return tOption
                }else{
                                    
                  SetLocalTime((prev)=>[...prev,localDate.add(30, 'seconds').format('HH:mm:ss')]); 
                  SetPlayPosition((prev)=>prev + 1);
                  return ([...tOption,updatedTime])
                }
              })
              
          }

        }else{
          SetPlayPosition((prev) => prev + 1);
        }

        

        // SetDisplayTime(localTime[localTime.length + 1])
      }

      if (data.length > 0) {
        layerPublish++;
        SetDelayTimer(false);
        SetCurrentData(data);
        SetNextUpdate(30);
        await processLocationCordinates(dataSocket, layerPublish, maxValue,productiveType);
      }
      if (res.data) {
        // dataSocket = dataSocket.filter(item => item.position);
      }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      // eventSource.close();
    };

    return () => {
      abortController.abort();
      // eventSource.abort();

      eventSource.close();
    };

}, [space, permission, locationCordinate, selectedDate, startTime, autoplay,productiveType]);

    useEffect(() => {
      let pagePerm= props.pages.filter(el=>el.page_name=="Geo Map Management")
      
      SetPermission(pagePerm);
      SetPermissionAPICall(true)
      
    },[props.pages]);


  function AccordionHandler(val,type='secondary'){
    let ActivList = [...activeAccord];
    let index = ActivList.indexOf(val)
    if(index !== -1){  
        ActivList.splice(index, 1);
        SetActiveAccord(ActivList);      
    }
    else if(type=='primary'){
        SetActiveAccord([val]);
 }     
    else {  
        ActivList.push(val);
        SetActiveAccord(ActivList);      
    }
    
}

  useEffect(()=>{
    if(permission.length > 0){
      let geoLocation=[];
      getLocationCordinate(userSession,org_id,sub_org_id).then(res=>{
        if(res.status==200){

        if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
            SetSpaceID("8caa8c30-c866-436e-a03c-962c1879841e")
        }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
            SetSpaceID("334db83f-5994-48e5-b08f-b05587c3d143")
        }else{
            SetSpaceID(res.data.space_id)  
        }
                  if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
                    SetSpaceID("8caa8c30-c866-436e-a03c-962c1879841e")
                    for(let i=0;i<CMGBulky.length;i++){
                      let dataFind= res.data.location.find(el=>el.name.toLowerCase().trim() ==CMGBulky[i]['Location  Name'].toLowerCase().trim() )
                     
                      if(dataFind){
                        
                        geoLocation.push(dataFind);
                      }

                    }
                  }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
                      SetSpaceID("334db83f-5994-48e5-b08f-b05587c3d143")
                      for(let i=0;i<CMGManual.length;i++){

                      let dataFind= res.data.location.find(el=> el.name.toLowerCase().trim() ==CMGManual[i]['Location  Name'].toLowerCase().trim() )
                      if(dataFind){
                        
                        geoLocation.push(dataFind);
                      }
                    }

                  }else{
                    SetSpaceID(res.data.space_id);

                    for(let i=0;i<res.data.location.length;i++){
                      let d =generatePath(Math.random()*10,Math.random()*10);
                      geoLocation.push({...res.data.location[i],d});
                    }
                    //  = res.data.location
                  
                  }

                  SetLocationCordinate(geoLocation);
          
 
          getCycleCountPlan(userSession,org_id,moment().utc().format('YYYY-MM-DD')).then(res2=>{
              if(res2.status==200){
                  
                for(let i=0;i<res.data.location.length;i++){
                    let ctPlan = moment().format('HH');
              
                      let data = res2.data[res.data.location[i].tag_serial]
                      data =  data?data.find(item=>item.hour == ctPlan+":00"):undefined; 
                      let result =0;
                      if(data){
                          res.data.location[i].planned = Math.ceil(data.cycle_count);
                      }else{
                        res.data.location[i].planned = '-';
                      }
                      //res.data.location[i].planned = GetRandomDigit(7,15);
                  }
                  

              }else{
               
                for(let i=0;i<res.data.location.length;i++){
                    res.data.location[i].planned = '-';
                  }
                  
                  SetLocationCordinate(res.data.location);
              }
            })
        }
      })

      getDepartment(userSession,org_id,sub_org_id).then(res=>{
        if(res.status==200){
          SetDeparment(res.data);  
        }
      });
      
      SetLoaderEmp(true);
      
    }

  },[permission])   

//Past date
  useEffect(()=>{
    if(permission.length > 0){
   let requestBody={}
    requestBody.start_date= moment(dates.start).subtract(1,'days').format('YYYY-MM-DD')
    requestBody.end_date=   moment(dates.start).format('YYYY-MM-DD')
    //requestBody.slug= activeEmp;
    requestBody.slug= 'employee';
    let cat=[];
    let catDaily=[];
    let empList= [];
          

     getOnBoardEmployee(requestBody,userSession,org_id,sub_org_id).then(res=>{
      // res=ONBOARD
      if(res.status==200){
        if(res.data.daily){        
          empList.push(...res.data.daily);
        }
        if(res.data.regular){
            empList.push(...res.data.regular);
        }

SetMaxValue(empList.length);
      }
     })

    }
},[permission])


useEffect(()=>{
    if(permission.length > 0){
      addPoint([])
    addPointDaily([]);
   let requestBody={}
    requestBody.start_date= moment(dates.start).subtract(1,'days').format('YYYY-MM-DD')
    requestBody.end_date=   moment(dates.end).format('YYYY-MM-DD')
    //requestBody.slug= activeEmp;
    requestBody.slug= 'employee';
    let cat=[];
    let catDaily=[];
    let empList= [];
          
     SetLoaderEmp(true);

     getOnBoardEmployee(requestBody,userSession,org_id,sub_org_id).then(res=>{
      // res=ONBOARD
      if(res.status==200){
        if(res.data.daily){
        
          
          // res.data.daily = res.data.daily.filter(item=>item.check_out == null)
 
        for(let i=0;i<res.data.daily.length;i++){
            res.data.daily[i].emp_name= res.data.daily[i].name;
            res.data.daily[i].department= res.data.daily[i].department_name;
            res.data.daily[i].dept_name= res.data.daily[i].department_name;
            res.data.daily[i].sub_dept_name= res.data.daily[i].sub_department_name;
            res.data.daily[i].sub_department= res.data.daily[i].sub_department_name;          
            res.data.daily[i].emp_name= res.data.daily[i].name;
            res.data.daily[i].emp_type= "daily_worker";
            empList.push(res.data.daily[i]);
        }

        }

                if(res.data.regular){
// res.data.regular = res.data.regular.filter(item=>item.check_out == null)

          for(let i=0;i<res.data.regular.length;i++){
            res.data.regular[i].emp_name= res.data.regular[i].name;
            res.data.regular[i].department= res.data.regular[i].department_name;
            res.data.regular[i].dept_name= res.data.regular[i].department_name;
            res.data.regular[i].sub_dept_name= res.data.regular[i].sub_department_name;
            res.data.regular[i].sub_department= res.data.regular[i].sub_department_name;          
            res.data.regular[i].emp_name= res.data.regular[i].name;
            res.data.regular[i].emp_type= "regular";
            empList.push(res.data.regular[i]);
        }
      }
            SetEmployeeAll(empList);
            SetEmployeeDefault(empList)
            SetLoaderEmp(false);
      }
     })

    }
},[permission,reloadInc])

  useEffect(()=>{
  SetActiveDepartment([]);
  let type= slug==''?'':slug=='employee'?'regular':'daily_worker';
  let emp = employeeDefault.filter(item=>item.emp_type.toString().includes(type)); 
    SetEmployeeAll(emp);   
  },[slug])



useEffect(()=>{
  SetActiveSubDepartment([]);
  let type= slug==''?'':slug=='employee'?'regular':'daily_worker';

  
  let emp = employeeDefault.filter(item=>item.emp_type.toString().includes(type)); 
  let filterEmp = employeeDefault.filter(item => departmentName.indexOf(item.department) > -1 ); 
  dataSocket=[];
  SetEmployeeAll(filterEmp);
  
  
  SetSubDeparment([]);     

  if(activeDepartment.length== 1){
  getSubDepartment(userSession,activeDepartment[0],org_id,sub_org_id).then(res=>{
    if(res.status==200){
      SetSubDeparment(res.data);
    }
  })
 
}

//SetEmployeeAll(filterEmp);
removeFrame()

 
},[activeDepartment])



useEffect(()=>{
  let type= slug==''?'':slug=='employee'?'regular':'daily_worker';

  if(activeSubDepartment=='all'){
    let filterEmp = employeeDefault.filter(item => 
        departmentName.indexOf(item.department) > -1  
        
        && item.emp_type.toString().includes(type)
      )  

dataSocket=[];
    SetEmployeeAll(filterEmp);
 


  }else if(activeSubDepartment.length == 0){
  let filterEmp = employeeDefault.filter(item => departmentName.indexOf(item.department) > -1 ); 
  dataSocket=[];
  SetEmployeeAll(filterEmp);   
  }else{
    let filterEmp = employeeDefault.filter(item => 
        subDepartmentName.indexOf(item.sub_department) > -1 && item.emp_type.toString().includes(type)
      )  
    SetEmployeeAll(filterEmp);
  }
  removeFrame()
 
  

},[activeSubDepartment])

function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}


useEffect(()=>{

  SetAPICall(false)
  removeFrame()

},[employeeALL])



  function isInt(value) {
    return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
  }



  useEffect(()=>{
      if(permission.facilities=='True'){

        SetTime(moment().format('HH:mm'));
      if (!space) {
        return
      }
     // SetClient(mqttService.getClient(errorHandler));
    }

  

    },[space,permission])
    
    function randomString(length) {
        let chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) {
            if(i==24){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==16){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==12){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==8){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }else{
              result += chars[Math.floor(Math.random() * chars.length)];
            }
          
        }
        return result;
      }  
    function handleDateSelect(date) {
    updateSelectedDate(date);
    layerPublish++;
    processLocationCordinates([],layerPublish,100,productiveType)

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));
    //setChartDates({ start: startDate, end: endDate });
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function addPoint(points){
      if(space){
      space.addDataLayer({
        id: 'points',
        type: 'icon',
        data: autoElevation(points),
        icon:  {
          url:ManIcon,
          width: 30,
          height: 50,
        },
        width: GeoWidth,
        anchor: 'bottom',
        tooltip: d => d.name ,  
      })
    }

  }

  function addPointDaily(points){
  
    if(space){
      space.addDataLayer({
        id: 'points2',
        type: 'icon',
        data: autoElevation(points),
        icon:  {
          url:CHristMasManIcon,
          width: 30,
          height: 50,
        },
        width: GeoWidth,
        anchor: 'bottom',
        tooltip: d => d.name,
      })
    }
  }
  


  const removeFrame = () => {
    if(!space){
      return
    }
    let data=[...dataSocket];

      if(data.length >0){
          for(let i =0; i < data.length; i++){
              let dt = data[i];
             dispatchPoint({ type: 'remove', id: dt.id });
              //space.removeDataLayer(data[i].id); 
              dataSocket.splice(i,1)
          }
          
         }
        dataSocket=[];

  }
  
  function updateDataLayer(data,url){


    if(space){

    if(url){
      space.updateDataLayer({
        id: data.unique_loc_id,
        data: [{
          position: data.position, 
        }],
        icon: {
          url: url,
          width: 96,
          height:96
        },
        width: GeoWidth,
        anchor: 'top',
        tooltip: data => data.name,
      })    
    }
  }

  }


  function AddDataLayer(data){
    if(space){
    space.addDataLayer({
      id: data.id,
      type: 'icon',
      data: [{
        position: data.position, 
      }],
      icon: {
        url: data.url,
        width: data.width,
        height: data.width
      },
      width: GeoWidth,
      anchor: 'top',
      tooltip: d => data.name,
    })
  }
}

useEffect(() => {
  if(!space){
    return 
  }
  let cinc=0;
  // dataSocket = []
  const id3 = setInterval(timerLive, 1000);

  const id4 =setInterval(function(){
      SetNextUpdate((prev)=>{
          let val=prev
          if(prev == 0){
            cinc++;
          //  resetPlotAppendTime(selectedDate,playPosition,timeOptions)
            val= 0;
          }else{
            cinc++;
            val = prev - 1
          }
          return val;
        })
  },1000)
  

return () =>{
  clearInterval(id3);
  clearInterval(id4);
  }   
},[space,employeeALL,selectedDate,playPosition]);

useEffect(()=>{
  if(!space){
    return
  }
    const tOpt = setInterval( function(){
      pushLastSecond(timeOptions,selectedDate)
  },30000);
return () =>{
  clearInterval(tOpt)
  } 
},[space,timeOptions])


function pushLastSecond(timeOptions,selectedDate){
      if(moment(selectedDate).utc().format('YYYY-MM-DD') === moment().utc().format('YYYY-MM-DD') ){
          let tOption= [...timeOptions];
        
          var originalTime = tOption[tOption.length - 1];
          var date = new Date('2023-01-01 ' + originalTime);
          date.setSeconds(date.getSeconds() + 30);
          var updatedTime = date.toLocaleTimeString('en-US', { hour12: false });
          tOption.push(updatedTime)
          
          var utcDate = moment.utc('2023-01-01 ' + originalTime, 'YYYY-MM-DD HH:mm:ss');
          var localDate = moment(utcDate).local();
          // setTimeOptions((prev)=>[...prev,updatedTime]);
          // SetLocalTime((prev)=>[...prev,localDate.add(30, 'seconds').format('HH:mm:ss')]);                
        }
}

// function resetPlotAppendTime(selectedDate,playPosition,timeOptions){
  
//   // for(let i=0;i<locationCordinate.length;i++){
//   //   space.removeDataLayer(locationCordinate[i].unique_loc_id);
//   // }
  

//   //only live append
//   if(moment(selectedDate).utc().format('YYYY-MM-DD') === moment().utc().format('YYYY-MM-DD') ){
    

    

//       let tOption= [...timeOptions];
//       //appending 30 second after last time;
//       var originalTime = tOption[tOption.length - 1];
//       var date = new Date('2023-01-01 ' + originalTime);
//       date.setSeconds(date.getSeconds() + 30);
//       var updatedTime = date.toLocaleTimeString('en-US', { hour12: false });
//       tOption.push(updatedTime)
//       setTimeOptions(tOption)
      
      
//   if(moment().subtract(60,'seconds').utc() < moment.utc(getDateFormat(selectedDate)+' '+tOption[playPosition +1],"YYYY-MM-DD HH:mm:ss")){
//     resetTime=30;
//     SetLive(true);
//   }else{
//     resetTime=5;
//     SetLive(false);
//   }


//   }else{
//     resetTime =30
//   }
//   SetCurrentData([])
// SetPlayPosition(playPosition +1);
//   dataSocket=[];
//   dataRemove =true;

// }
    
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);

    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }

    function changeTab(name){
      SetActiveTab(name);
    }

    function ModalFormatData(hourData,tag_serial){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea' key={"ModalFormatData"+tag_serial.toLowerCase()}> 
         <h4>{tag_serial.toLowerCase()} {displayTime}  <button className='alignRightFixed' onClick={()=>{SetModalView(false);SetAutoPlay(true)}}>x</button></h4>
        </div>
      )

      let inner =[];      
      for(let i=0;i<hourData.length;i++){
        if(i==1){

        }
        let emp= employeeDefault.find(item=>item.tag_serial == hourData[i].tag_id );
        if(emp){
          inner.push(emp)   
        }
      
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
      //return 'hello';

    }
    function ModalFormatcategory(hourData,categoryname){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea' key={"ModalFormatcategory"+categoryname.toLowerCase()}> 
         <h4>{categoryname.toLowerCase()}: {displayTime} <button className='alignRightFixed' onClick={()=>{SetModalView(false);SetAutoPlay(true)}}>x</button></h4>
        </div>
      )

      let inner =[];   
       
      for(let i=0;i<hourData.length;i++){
        if(i==1){

        }
        
        let emp= [...employeeDefault].find(item=>item.tag_serial == hourData[i].tag_id );

        if(emp){inner.push(emp)  } 
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
      //return 'hello';

    }


    function ModalFormatLocation(hourData){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea' key={"ModalFormatLocationTitle"}> 
         <h4>Total employees: {displayTime}<button className='alignRightFixed' onClick={()=>{SetModalView(false);SetAutoPlay(true)}}>x</button></h4>
        </div>
      )

      let inner =[];   

      for(let i=0;i<hourData.length;i++){
        if(i==1){

        }
        let emp= employeeDefault.find(item=>item.tag_serial == hourData[i].tag_id );

      if(emp){inner.push(emp)  } 
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
      //return 'hello';

    }

    function onChangeDepartment(val){
    let nameVal=[]
      dataSocket=[]
      if(val.indexOf('all')> -1){
          let value =[];

          for(let i=0;i<department.length;i++){
            value.push(department[i].department_id)
            nameVal.push(department[i].name)
          }
          if((val.length -1) == value.length ){
            SetActiveDepartment([]);  
            SetDepartmentName([])
          }else{
            SetDepartmentName(nameVal)
            SetActiveDepartment(value);
            
          }
    
          
      }else{
        
        nameVal = val.map(item=>department.find(el=>el.department_id == item)?.name)
        SetDepartmentName(nameVal)
        SetActiveDepartment(val);
      }


    }

    function onChangeSubDepartment(val){
      //val=[val] 
        dataSocket=[]
    let nameVal=[]
      if(val.indexOf('all')> -1){
        let value =[];
        for(let i=0;i<sub_department.length;i++){
          value.push(sub_department[i].sub_department_id)
          nameVal.push(sub_department[i].sub_dept_name)
        }
        if((val.length -1) == value.length ){
        
          SetSubDepartmentName([])                    
          SetActiveSubDepartment([]);  
        }else{
        
          SetSubDepartmentName(nameVal)
          SetActiveSubDepartment(value);

        }

    
        
    }else{
      nameVal = val.map(item=>sub_department.find(el=>el.sub_department_id == item)?.sub_dept_name);

      
       SetSubDepartmentName(nameVal)
      SetActiveSubDepartment(val);
    }

    }
    
    function AGGridEMptyMEssage(key){
      let msg;
          msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
      return msg
    }
    
    function viewIconRenderer(params){
      var eGui = document.createElement('div');
      eGui.innerHTML = '<span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 352c-52.93 0-96-43.06-96-96s43.07-96 96-96c52.94 0 96 43.02 96 96.01C384 308.9 340.1 352 288 352zM572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM543.2 260.2C492.3 376 394.5 448 288 448c-106.5 0-204.3-71.98-255-187.3C32.58 259.6 32.05 256.9 31.1 256.2c.0547-1.146 .5859-3.783 .7695-4.363C83.68 135.1 181.5 64 288 64c106.5 0 204.3 71.98 255 187.3c.3945 1.08 .9238 3.713 .9785 4.443C543.9 256.9 543.4 259.6 543.2 260.2z"/></svg></span>';
       return eGui;
    }

    function colsValueFormatter(params){
      var eGui = document.createElement('div');
        
      eGui.innerHTML = '<div><div class="titleFont">'+params.data.emp_name+'</div> <span class="smallerfont smallerfontDepartment">'+params.data.dept_name+'</span> <span class="smallerfont">'+params.data.sub_dept_name+'</span> </div>';
                     return eGui;          
      
    }

    function AGTable(data){

    
      if(data  && window.innerWidth > 767){
        
        let widthCal= 0;
    
        if( ((window.innerWidth * 80)/100) > 1100){
          widthCal=((1100 - 205)/4) ;
        }else{
          widthCal=(( (window.innerWidth * 80)/100) - 205)/4 ;
        }
        let arr;

        arr=<div className={"keyAGTable"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={35}
              autoGroupColumnDef={{
                  headerName: 'Name',minWidth: 200,field: 'emp_name',
      
              }}
              headerHeight={35}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
                onCellClicked={ (event) =>
              {
               
                if(event.data.emp_type == 'daily_worker'){
                window.open(`/productivity/daily_worker/:${event.data.worker_id}`, "_blank")
                }else{
                window.open(`/productivity/employee/:${event.data.worker_id}`, "_blank")

                }
              }
                }

              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
            
          <AgGridColumn
              field="sno"
              headerName={"S.No"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
  
              width={75}
          />
            <AgGridColumn
              field="emp_name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value.toLowerCase()}
              cellClass={'textCapitalize textCap'}
          />
                      <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>params.value.toLowerCase()}
              cellClass={'textCapitalize textCap'}
          />
          <AgGridColumn 
              field="dept_name" 
              headerName="Department" 
              valueFormatter={(params) =>params.value.toLowerCase()}    
              cellClass={'textCapitalize textCap'}
             >
          </AgGridColumn> 
           <AgGridColumn
             field="sub_dept_name"
             headerName="Sub Department"
             cellClass={'textCapitalize textCap'}
             valueFormatter={(params) =>params.value!=''?params.value.toLowerCase():'-'}    headerComponentParams =  {{
          }} ></AgGridColumn> 
          <AgGridColumn
             field="sub_dept_name"
             headerName="View"
             width={80}
             cellClass={'textCapitalize textCap'}
             cellRenderer= {(params)=>viewIconRenderer(params)} >
            </AgGridColumn> 
      </AgGridReact>
      </div></div>
      
      return arr
    }else if(data  && window.innerWidth < 767){
      let widthCal= 0;
    
        widthCal=window.innerWidth - 200/1 ;
      let arr;

      arr=<div className={"keyAGTable keyAGTableResponsiveBiggerHeight"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
        <AgGridReact
            rowHeight={45}
            autoGroupColumnDef={{
                headerName: 'Name',minWidth: 200,field: 'name',
    
            }}
  
         defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
            //pagination={true}
            rowData={data}
            key={'keyAGTable'}
            onCellClicked={ (event) =>
              {
            
                if(event.data.emp_type == 'daily_worker'){
                window.open(`/productivity/daily_worker/:${event.data.tag_serial}`, "_blank")
                }else{
                window.open(`/productivity/employee/:${event.data.tag_serial}`, "_blank")

                }
              }
                }
            overlayNoRowsTemplate={
                AGGridEMptyMEssage('')
              }        
         >
          <AgGridColumn
            field="emp_name"
            headerName={"Name"}
            valueFormatter={(params)=>params.value.toLowerCase()}
            cellRenderer= {(params)=>colsValueFormatter(params)}
            cellClass={'textCapitalize textCap'}
        />
        <AgGridColumn
           field="sub_dept_name"
           headerName="View"
           width={80}
           cellClass={'textCapitalize textCap'}
           cellRenderer= {(params)=>viewIconRenderer(params)} >
          </AgGridColumn> 
    </AgGridReact>
    </div></div>
    
    return arr
    }else{
      return ;
    }
    
    }

    function modalViewShow(loc,playPosition){

      let data = currentData.filter(item=>item.loc_id==loc.tag_serial);
      let arr =[];
      arr = ModalFormatData(data,loc.location_name);
     SetAutoPlay(false);
     SetStartTime(timeOptions[playPosition]);
     SetDisplayTime(localTime[playPosition]);
     SetModalData(arr);
     SetModalView(true)
     
    }

    function categorymodalview(Category_data,playPosition){
     SetAutoPlay(false);
     SetStartTime(timeOptions[playPosition]);
      let arr =[];
      for(let i=0;i<Category_data.location.length;i++){
        
        let data = [...currentData].filter(item=>item.loc_id == Category_data.location[i].tag_serial);
       
        for(let j=0;j<data.length;j++){
          arr = [...arr, data[j]]
        } 
      }
      let arr1=[]
     arr1 = ModalFormatcategory(arr,Category_data.name);  
     SetDisplayTime(localTime[playPosition]);
       SetModalData(arr1);
       SetModalView(true)
    }


    function categorymodalview1(location,playPosition) {
           SetAutoPlay(false);
     SetStartTime(timeOptions[playPosition]);
      const arr = location.reduce((acc, loc) => {
        const data = currentData.filter(item => item.tag_serial === loc.tag_serial);
        return [...acc, ...data];
      }, []);
    
      const arr1 = ModalFormatLocation(arr);
      SetDisplayTime(localTime[playPosition]);
      SetModalData(arr1);
      SetModalView(true);
    }

     function changeGeoMapSpace(val){

      space.remove();
      SetSpaceID(val);



     }
     function locNameRender(params){
  
      var op =document.createElement('div');

      var eGui = document.createElement('div');
    
      eGui.innerHTML= '<div class="elementCell textCap">'+params.value.toLowerCase()+'</div>';

return eGui;

          
  }

  function ShowCardListLocation(){
    let data =[...locationList];   
    let arr=[];

    if(data.length>0){
      for (let i = 0; i < data.length; i++) {
      
        let index = i + 1;
       
              const plannedCount = data[i].location.reduce((acc, loc) => {

                const locData = locationCordinate.find(
                  (cord) => cord.tag_serial === loc.tag_serial && cord.name === loc.location_name
                );

                const planned = locData && locData.planned  && locData.planned!='-'? parseInt(locData.planned) : 0;
                return acc + planned;
              }, 0);

              const validPlannedCount = isNaN(plannedCount) ? 0 : plannedCount;

              arr.push(                 
          
                <Row key={"contentCategory" + data[i].name.toLowerCase()}className={`contentGroup${activeAccord.indexOf(data[i].name) !== -1 ? ' active' : ''}${data[i].location && data[i].location.length > 0 ? ' contentCategory' : ''}`}>
                  <div className={`Category-header group-header`}> 
                            <Col lg={3} className={`align-middle align-wrapper`}>
            {data[i].location && data[i].location.length > 0 && (
              <span onClick={() => AccordionHandler(data[i].name, 'primary')}>
                {activeAccord.indexOf(data[i].name) !== -1 ? (
                  <CircleArrowDown style={{ height: 15 }} />
                ) : (
                  <CircleArrow style={{ height: 15}} />
                )}
              </span>
            )}
            {data[i].location && data[i].location.length > 0 && (
              <h4 style={{ fontSize: "12px",color:"#908fab", margin: 0, marginLeft: 8 }}>
                {data[i].name.charAt(0).toUpperCase() + data[i].name.slice(1)}
              </h4>
            )}
          </Col>


              <Col lg={2} className="align-middle" style={{  fontSize: "9px",marginRight:30 }}>
              <span style={{ fontSize: "10px",color:"#908fab"}}>{data[i].location.length > 0 && validPlannedCount}</span>
            </Col>
          <Col lg={2} className="align-middle" style={{ textAlign: "center", fontSize: "10px",color:"#908fab" }}>
        {data[i].location.length > 0 && (
          <>
            <span style={{ fontSize: "10px", fontWeight: "bold" }}></span>
            {data[i].location.reduce((acc, loc) => {
              
              const actual = currentData.filter(el=>el.loc_id === loc.tag_serial).length;

              return acc + actual;
            }, 0)}
          </>
        )}
      </Col>

            <Col lg={3} className="align-middle" style={{textAlign:"center",fontSize:"10px"}}>
            {data[i].location.length > 0 ? (
                data[i].location.reduce((acc, loc) => {
                  
                  const actual = currentData.filter(el=>el.loc_id === loc.tag_serial).length;
                  return acc + actual;
                }, 0) > 0 ? (
                  <React.Fragment key={data[i].id}>
                    <div className="legendRightAreaProd" onClick={() => categorymodalview(data[i],playPosition)}>
                      <Eye style={{ height: 13, cursor: "pointer" }} />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={data[i].id}>
                    <div className="legendRightAreaProd disabled nonCLickable">
                      <Eye style={{ height: 13 }} />
                    </div>
                  </React.Fragment>
                )
              ) : null
            }

    </Col>
            </div>
            <div  className={activeAccord.indexOf(data[i].name)!==-1 ?'Category-content active':'Category-content'}>
              <div className="ag-theme-alpine" style={{  width: '100%'}}>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr>
                    {/* Header cells */}
                  </tr>
                </thead>
                <tbody>
                  {data[i].location.map((loc) => {
                  const locData = locationCordinate.find(
                    (cord) => cord.tag_serial === loc.tag_serial && cord.name === loc.location_name
                  );
                  const planned = locData?.planned || "0";
                    const actual = currentData.filter(el=>el.loc_id === loc.tag_serial).length;
                    const eye =
                      currentData.filter(el=>el.loc_id == loc.tag_serial).length > 0 ? (
                        <div className="legendRightAreaProd" onClick={() => modalViewShow(loc,playPosition)}>
                          <Eye style={{ height: 13, cursor: "pointer",marginLeft:23}} />
                        </div>
                      ) : (
                        <div className="legendRightAreaProd disabled nonCLickable">
                          <Eye style={{ height: 13 ,marginLeft:23}} />
                        </div>
                      );
                    return (
                     <tr key={loc.tag_serial} style={{}}>
                      <td className="locationName">{loc.location_name}</td>
                      <td style={{ padding: 3, fontSize: "10px", color: planned <= actual ? "black" : "grey", width: "20%", textAlign: "right", }}>
                        {planned}
                      </td>
                      <td style={{ padding: 3, fontSize: "10px", color: actual <= planned ? "black" : "grey", width: "20%", textAlign: "right" }}>
                        {actual}
                      </td>
                      <td style={{ width: "30%"}}>{eye}</td>
                    </tr>
                    );
                  })}
                </tbody>
              </table>
              </div>                 
            </div>
          </Row>
        )
      }
    }
    return arr;
  }
  
//svg Regarding all thing
  function generateSVGHeatmap(angle,curve,value,d){

    return <svg
        id="sw-js-blob-svg"
        viewBox="0 0 100 100"
        width={120}
        height={120}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <radialGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
            {updateGradientReturn(value,0,100)}            
          </radialGradient>
        </defs>
        <path
          fill={`url(#sw-gradient)`}
          d={d?d:generatePath(angle, curve)}
          width="100%"
          height="100%"
          transform="translate(50 50)"
          style={{ transition: 'all 0.3s ease 0s' }}
          strokeWidth="0"
        ></path>
      </svg>
  }

  const updateGradientReturn = (r,g,b) => {

    r= isNaN(r)?0:r;
    g=isNaN(g)?0:g;
    b=isNaN(b)?0:b;
 const gradient = <>
                    <stop id="stop1" stopColor={color1} offset={r+"%"}></stop>
                    <stop id="stop2" stopColor={color2} offset={(r + g)+"%"}></stop>
                    <stop id="stop3" stopColor={color3} offset={(r + g+ b)+"%"}></stop>
                  </>
  return gradient;
};
  const generatePath = (angle, curve) => {
    const initialData = generateData(angle, curve);
    return roundPath(generateBlobShape(initialData) + 'Z');
  };
    const generateData = (complexity, contrast) => {
    const scale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([50 - (50 / 12) * contrast - 0.01, 50]);
    return d3.range(complexity).map(() => scale(Math.random()));
  };
  const roundPath = (path) => {
    // You can add any rounding logic if needed
    return path;
  };

    const generateBlobShape = (data) => {
    const shapeGenerator = d3
      .radialLine()
      .angle((d, i) => (i / data.length) * 2 * Math.PI)
      .curve(d3.curveBasisClosed)
      .radius((d) => d);
    return shapeGenerator(data.map((d) => Math.abs(d)));
  };

  async function svgToDataURL(svgString,where) {  
  // Ensure that the image is loaded before processing
    return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.onload = function () {
          canvas.width = 120;
          canvas.height = 120;

          ctx.drawImage(img, 0, 0);

          const dataURL = canvas.toDataURL('image/png');
          resolve(dataURL);
 
        };

        img.src = 'data:image/svg+xml,' + encodeURIComponent(svgString);
      });
  };

const generateTimeOptions = (date) => {
  const utcTimeOptions = [];
  const localTimeOptions = [];

  const currentDate = moment().utc();
  const inputDate = moment(date).utc();
  const startOfDay = moment(inputDate).startOf('day').utc();
  const endOfDay = moment(inputDate).endOf('day').utc();
  const currentHour = currentDate.hour();

  let endTime;

  if (inputDate.isSame(currentDate, 'day')) {
    endTime = moment(currentDate);
  } else if (inputDate.isBefore(currentDate, 'day')) {
    // If the input date is in the past, set the end time to the end of the day
    endTime = endOfDay;
  } else {
    // Default: Set the end time to the end of the day
    endTime = endOfDay;
  }

  let currentTime = startOfDay;

  while (currentTime.isBefore(endTime) || currentTime.isSame(endTime)) {
    // Push UTC time to the utcTimeOptions array
    utcTimeOptions.push(currentTime.format('HH:mm:ss'));
    
    // Convert UTC time to local time and push to localTimeOptions array
    const localTime = moment.utc(currentTime).local();
    localTimeOptions.push(localTime.format('HH:mm:ss'));
    currentTime = currentTime.add(30, 'seconds');
  }
  return { utc: utcTimeOptions, local: localTimeOptions };
};


useEffect(()=>{
  let dateTime = timeOptions[playPosition]?timeOptions[playPosition]:timeOptions[playPosition - 1]?timeOptions[playPosition - 1]:timeOptions[timeOptions.length - 1] 
    if(moment().subtract(120,'seconds').utc() < moment.utc(getDateFormat(selectedDate)+' '+dateTime,"YYYY-MM-DD HH:mm:ss")){
        SetLive(true);
    }else{
        SetLive(false);
    }
},[playPosition,timeOptions])

  const handleChange = (newValue,selectedDate,layerPublish) => {
    SetStartTime(timeOptions[newValue]);
    SetPlayPosition(newValue);
    SetDisplayTime(localTime[newValue]);
    //processLocationCordinates([],layerPublish,100)
    //resetPlotAppendTime(selectedDate)
    for(let i=0;i<locationCordinate.length;i++){
      space.removeDataLayer(locationCordinate[i].unique_loc_id+(layerPublish -1));
    }
    layerPublish = 0;
    if(moment().subtract(60,'seconds').utc() < moment(getDateFormat(selectedDate)+" "+timeOptions[newValue])){
      resetTime = 30
      SetLive(true);
    }else{
      resetTime= 5
      SetLive(false);
    }
    SetNextUpdate(resetTime);
  
  };


  return (<>
      {permissionAPICall?permission.length>0? 
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <Row>
      {role? <Col lg={12} className="headerFontSpaceLess"> <CommonHeading title="Facility Tracker" /> </Col>:""}

        <Col lg={6} style={{display:role?'none':'block'}}>

                 <div className='tabHeader'>
              
                <div className='tabHeaderList' onClick={()=>props.history.push('/employee-tracker')}>
                <CommonHeading title="Employee Tracker"/>
                </div>   
                <div className='tabHeaderList active'>
                <CommonHeading title="Facility Tracker" />
                </div>
                <div className='tabHeaderList ' onClick={()=>props.history.push('/movement-analyzer')}>
                  <CommonHeading title="Movement Analyzer" />
              </div>
           </div>   
    </Col>
        <Col lg={6} className="text-right" style={{display:role?'none':'block'}}>
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="commonHeadingDateMainDivParent">
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
                              weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
            />
          </div>
        </Col>
      </Row>

      <Row className={role?"":"m-t"} >
        <Col lg={12} >
          <div className='manStyle' style={{backgroundColor:"#fff",width:"100%",float:"left"}}>
            <div className='inlineBlockFilter'>
              <h5 style={{marginBottom:0,lineHeight:"35px"}}>Filter</h5>
            </div>
        
            <div className='inlineBlockFilter'>
            <Select
                placeholder="Select Department"
                optionFilterProp="children"
                onChange={(val)=>{onChangeDepartment(val)}}
                value={activeDepartment}
                key="departmentFilter"
                style={{width:250}}
                disabled={!space}
                mode="multiple"
                dropdownClassName={'smallerDrop'}
            >
              <Option value='all' key={"Department All"}>All</Option>
                {
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.department_id} key={"Department"+item.department_id}>{item.name}</Option>
                }):""
                }
            </Select>
            </div>
            <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Sub Department"
                    optionFilterProp="children"
                    style={{width:250}}
                    onChange={(val)=>{onChangeSubDepartment(val)}}
                    disabled={activeDepartment.length==0 || activeDepartment.length>1?true:false}
                    value={activeSubDepartment}
                    key="SubdepartmentFilter"
                    dropdownClassName={'smallerDrop'}
                    mode="multiple"
                >
                  <Option value='all' key={"Sub Department All"}>All</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item.sub_department_id} key={"Sub Department"+item.sub_department_id}>{item.sub_dept_name}</Option>
                    }):""
                    }
                </Select>
            </div>
            {space?<>
              {live?<div className={delayTimer?'greenLive disabledClick':'greenLive'} style={{textTransform:"capitalize",border:"0px",fontWeight:"normal",cursor:"not-allowed",pointerEvents:"none"}}>Next Update:  <strong style={{color:"#ef5e8c"}}>{nextUpdate}</strong></div>:""}
              <div className='timerLive' style={{marginBottom:"15px"}}><span className='currentDate'>{moment(selectedDate).format('DD-MM-YYYY')}</span><span className='toptime'>{localTime[playPosition]}</span></div></>:""}
            </div>
        </Col>
      </Row>

      <Row className="m-t">
        <Col lg={9} className='tabletfull' >

        {loaderEmp && !apiCall? <div className='loaderStyle' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4>
                    </div>
                  </div>:""} 
                            <div className='manStyle' style={{maxWidth:"100%",margin:"0 auto"}}>



             {apiCall? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:spaceID!=''? 
                                
                                <><Viewer onReady={onReady} spaceID={spaceID} changeTab={changeTab} /*cameraPlacement={cameraPlacements}*//>
                                          
                                          
                                          {!space?"":
                                          <div className='liveDataPlay' style={{height: "60px",background: "rgba(0, 0, 1, 0.08)",position: "relative"}}>
                                            <div className='playPauseThing' style={{background:autoplay?"#ef5e8c":"grey"}} onClick={()=>{SetAutoPlay(!autoplay);SetStartTime(timeOptions[playPosition])}  } >
                                        {autoplay?<PauseCircle/>:<PlayCircle/>}
                                      </div>
                                    <div className='liveDataRightArea'>
                                    <div className='liveDataPlayInner' style={{ position: 'relative', width: '96%',margin:"0 auto",borderRadius:"20px", height: '20px',top:"10px", backgroundColor:'rgba(71, 59, 59, 0.19)' }}>

                                          {/* <Slider
      min={0}
      max={timeOptions.length - 1}
      step={1}
      value={playPosition}
      onChange={(val)=>handleChange(val,selectedDate,layerPublish)}
      tooltipVisible
      tipFormatter={(index) => displayTime!=""?displayTime:timeOptions[index]}
    /> */}
      <Slider
        min={0}
        max={timeOptions.length - 1}
        step={1}
        value={playPosition}
        onChange={(val) => handleChange(val, selectedDate, layerPublish)}
        tipFormatter={(index) =>  localTime[playPosition]}
        tooltipVisible
        tipProps={{ placement: 'top', visible: true }}
        trackStyle={{ background: 'linear-gradient(to right, #108ee9 0%, #108ee9 ' + (playPosition / (timeOptions.length - 1)) * 100 + '%, #ccc ' + (playPosition / (timeOptions.length - 1)) * 100 + '%, #ccc 100%)' }}
       
      />
            {/* <TimeSlider date={selectedDate} playPosition={playPosition} timeOptions={timeOptions} changeSlide={onChangePlotTime} SetPlayPosition={()=>SetPlayPosition} /> */}
            {/* {playPosition} */}

</div>
</div>
</div> 
}  


                                
                                </>:<h2>Please Setup Geo Map or contact to administrator</h2>}
          </div>
        </Col>
        <Col lg={3} className="p-l-0 tabletfull">
          <div className="legendArea manStyle" style={{height:'100%',background:"#fff"}}>
            <div className='legendStart'>
              <div className={!space?'legendList legendHeader disable':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Location</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Plan Count</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Actual Count</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Worker List</strong></div>
              </div>
              <div className='legendLeftAreaProd'>{ShowCardListLocation()}</div>
              <div className={!space?'legendList  legendHeader legendFooter disable ':'legendList legendHeader'}style={{borderBottom: "0px"}}>
              <div className="customContainer contentGroup contentCategory row" style={{borderBottom: "1px solid #ddd"}}>
                <div className="Category-header group-header">
  <div className="customLeftArea align-middle align-wrapper col-lg-3" style={{marginLeft:0}} ><strong>Total</strong></div>

  <div className="customPlanned align-middle col-lg-2" style={{fontSize:10}}>{isNaN(locationCordinate.filter(el=>el.planned!=='-').reduce((partialSum, a) => (parseInt(partialSum) +  parseInt(a.planned)) , 0))?0:locationCordinate.filter(el=>el.planned!=='-').reduce((partialSum, a) => (parseInt(partialSum) +  parseInt(a.planned)) , 0)  }</div>
  <div className="customDataLength align-middle col-lg-2" style={{fontSize:10,textAlign:'center'}}>{currentData.length}</div>
  <div className="customRightArea align-middle col-lg-3" style={{marginTop:3,textAlign:"center"}}>
    {dataSocket.length > 0 ? (
      <div className='legendRightAreaProd' onClick={() => categorymodalview1(locationCordinate,playPosition)} key={locationCordinate.tag_serial}>
        <Eye style={{height:13}} />
      </div>
    ) : (
      <div className='legendRightAreaProd disabled nonClickable' key="total-eye-icon">
        <Eye  style={{height:13}}/>
      </div>
    )}
  </div>
  </div>
</div>
</div>
  </div>

          </div>
        </Col>
      </Row>
    </Container>

    <ReactModal
                isOpen={modalView}
                style={customStyles}
                onRequestClose={()=>{SetModalView(false);SetAutoPlay(true)}}
                shouldCloseOnOverlayClick={false}
                
            >
                <div className={"modal-cyclecount"}>
                  {modalData}
                </div>
            </ReactModal>

  </div>:<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>
  )
}

export default FacilityVideo