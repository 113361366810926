import React, { useEffect, useState } from 'react';
import { Modal, Input, Select, DatePicker, Button, List, Row, Col, Divider } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment';

const { RangePicker } = DatePicker;

const EditJob = ({ visible, onCancel, onSubmit, location, employee,jobDetail }) => {
  const [jobName, setJobName] = useState('');
  const [locationSerialsList, setLocationSerialsList] = useState([]);
  const [worker_id, SetWorkerID] = useState('');
  const [jobDuration, setJobDuration] = useState([]);
  const [toDoList, setToDoList] = useState([]);
  const [remark,SetRemark]= useState([]);
  const [currentTask, setCurrentTask] = useState('');

  const [errors, setErrors] = useState({}); // Store validation errors
    
  const addTask = () => {
    if (currentTask.trim()) {
      setToDoList([...toDoList, currentTask]);
      setCurrentTask('');
    }
  };


  useEffect(()=>{
    setJobName(jobDetail.job_name);
    setToDoList(jobDetail.todo_list);
    if(jobDetail.planned_start_time &&  jobDetail.planned_end_time){
      setJobDuration([moment(jobDetail.planned_start_time.replaceAll(" GMT","")),moment(jobDetail.planned_end_time.replaceAll(" GMT",""))])
    }
    
    console.log("jobDetail.worker_id",jobDetail?.worker_id)
    if(jobDetail.worker_id){
      SetWorkerID(jobDetail?.worker_id.replaceAll("{","").replaceAll("}",""));
    }
    SetRemark(jobDetail.remarks)
    setLocationSerialsList(jobDetail.location_serial);
  },[jobDetail])
  const editTask = (index, value) => {
    const updatedList = [...toDoList];
    updatedList[index] = value;SetWorkerID(jobDetail?.worker_id.replaceAll("{","").replaceAll("}",""));
    setToDoList(updatedList);
  };

  const removeTask = (index) => {
    const updatedList = toDoList.filter((_, i) => i !== index);
    setToDoList(updatedList);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!jobName.trim()) newErrors.jobName = 'Please enter a job name!';
    if (!locationSerialsList.length) newErrors.locationSerialsList = 'Please select at least one location!';
    if (!worker_id) newErrors.worker_id = 'Please select worker!';
    if (!jobDuration.length) newErrors.jobDuration = 'Please select start and end dates!';
    // if (toDoList.length === 0) newErrors.toDoList = 'Please Add To Do List';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = () => {
    console.log('payload jobDuration',jobDuration);
    if (validateForm()) {
      const payload = {
        jobName,
        locationSerialsList,
        worker_id,
        jobDuration,
        toDoList,
        remark
      };
      console.log('payload', payload,jobDuration);
      onSubmit(payload);
    }
  };

  const resetForm = () => {
    setJobName('');
    setLocationSerialsList([]);
    SetWorkerID('');
    setJobDuration([]);
    setToDoList([]);
    setCurrentTask('');
    setErrors({});
  };

  return (
    <Modal
      title="Edit Job"
      visible={visible}
      onCancel={onCancel}
      width={800}
      className={"roundedCorner"}
      footer={[
        <Button key="submit" type="primary" onClick={handleFormSubmit}>
          Update Job
        </Button>,
      ]}
    >
      <Row gutter={24}>
        {/* Left Column */}
        <Col span={12}>
          <div>
            <label>Job Name</label>
            <Input
              placeholder="Enter Job Name"
              value={jobName}
              onChange={(e) => setJobName(e.target.value)}
            />
            {errors.jobName && <div style={{ color: 'red' }}>{errors.jobName}</div>}
          </div>

          <div style={{ marginTop: '10px' }}>
            <label>Location</label>
            <Select
              placeholder="Select Location"
              mode="multiple"
              value={locationSerialsList}
              onChange={(value) => setLocationSerialsList(value)}
              style={{ width: '100%',border:"1px solid #ddd" }}
            >
              {location.map((el) => (
                <Select.Option key={el.tag_serial} value={el.tag_serial}>
                  {el.location_name}
                </Select.Option>
              ))}
            </Select>
            {errors.locationSerialsList && <div style={{ color: 'red' }}>{errors.locationSerialsList}</div>}
          </div>

          <div style={{ marginTop: '10px' }}>
            <label>Worker</label>
            <Select
              placeholder="Select Worker"
              value={worker_id}
              onChange={(value) => SetWorkerID(value)}
              style={{ width: '100%',border:"1px solid #ddd" }}
            >
              {employee.map((el) => (
                <Select.Option key={el.worker_id} value={el.worker_id}>
                  {el.name}
                </Select.Option>
              ))}
            </Select>
            {errors.worker_id && <div style={{ color: 'red' }}>{errors.worker_id}</div>}
          </div>

          <div style={{ marginTop: '10px' }}>
            <label>Job Duration</label>
            <RangePicker
              style={{ width: '100%' }}
              value={jobDuration}
              onChange={(dates) => setJobDuration(dates)}
             
                showTime={{ format: 'HH:mm' }} // Enables time selection with hour and minute
                format="YYYY-MM-DD HH:mm" //
            />
            {errors.jobDuration && <div style={{ color: 'red' }}>{errors.jobDuration}</div>}
          </div>
        </Col>

        {/* Right Column */}
        <Col span={12}>
          <h6>To-Do List</h6>
          <Divider style={{ margin: '10px 0px' }} />
          <div>
            <label>To Do</label>
            <Input
              placeholder="Add a task"
              value={currentTask}
              onChange={(e) => setCurrentTask(e.target.value)}
              onPressEnter={addTask}
            />
            {errors.toDoList && <div style={{ color: 'red' }}>{errors.toDoList}</div>}
            <Button type="primary" style={{ marginTop: '8px' }} onClick={addTask}>
              Add Task
            </Button>
          </div>

          <Scrollbars style={{ height: 100, marginTop: '10px' }}>
            <List
              dataSource={toDoList}
              renderItem={(item, index) => (
                <List.Item>
                  <Input
                    value={item}
                    onChange={(e) => editTask(index, e.target.value)}
                    style={{ width: '90%' }}
                  />
                  <Button
                    type="text"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => removeTask(index)}
                  />
                </List.Item>
              )}
            />
          </Scrollbars>
        </Col>

      </Row>
    </Modal>
  );
};

export default EditJob;
