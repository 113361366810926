import React, { useEffect, useState } from 'react';
import { Modal, Input, Select, DatePicker, Button, List, Row, Col, Divider } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment';

const { RangePicker } = DatePicker;


const Remark = ({ visible, onCancel, onSubmit, location, employee,jobDetail }) => {
  const [jobName, setJobName] = useState('');
  const [locationSerialsList, setLocationSerialsList] = useState([]);
  const [worker_id, SetWorkerID] = useState('');
  const [jobDuration, setJobDuration] = useState([]);
  const [toDoList, setToDoList] = useState([]);
  const [currentTask, setCurrentTask] = useState('');
  const [remark,SetRemark]= useState([]);

  const [errors, setErrors] = useState({}); // Store validation errors
    
  const addTask = () => {
    if (currentTask.trim()) {
      SetRemark([...remark, currentTask]);
      setCurrentTask('');
    }
  };


  useEffect(()=>{
    setJobName(jobDetail.job_name);
    setToDoList(jobDetail.todo_list);
    SetRemark(jobDetail.remarks)
        if(jobDetail.planned_start_time &&  jobDetail.planned_end_time){
          setJobDuration([moment(jobDetail.planned_start_time.replaceAll(" GMT","")),moment(jobDetail.planned_end_time.replaceAll(" GMT",""))])
        }
    console.log("jobDetail.worker_id",jobDetail?.worker_id)
    if(jobDetail.worker_id){
      SetWorkerID(jobDetail?.worker_id.replaceAll("{","").replaceAll("}",""));
    }
    
    setLocationSerialsList(jobDetail.location_serial);
  },[jobDetail])
  const editTask = (index, value) => {
    const updatedList = [...toDoList];
    updatedList[index] = value;SetWorkerID(jobDetail?.worker_id.replaceAll("{","").replaceAll("}",""));
    SetRemark(updatedList);
  };

  const removeTask = (index) => {
    const updatedList = remark.filter((_, i) => i !== index);
    SetRemark(updatedList);
  };

  const validateForm = () => {
    const newErrors = {};
    // if (!jobName.trim()) newErrors.jobName = 'Please enter a job name!';
    // if (!locationSerialsList.length) newErrors.locationSerialsList = 'Please select at least one location!';
    // if (!tagSerial) newErrors.tagSerial = 'Please select worker!';
    // if (!jobDuration.length) newErrors.jobDuration = 'Please select start and end dates!';
    // if (toDoList.length === 0) newErrors.toDoList = 'Please Add To Do List';
    if (remark.length === 0) newErrors.toDoList = 'Please Add Remark';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = () => {
    console.log('payload jobDuration',jobDuration);
    if (validateForm()) {
      const payload = {
        jobName,
        locationSerialsList,
        worker_id,
        jobDuration,
        toDoList,
        remark
      };
      
      console.log('payload', payload,jobDuration);
      onSubmit(payload);
      
    }
  };

  const resetForm = () => {
    setJobName('');
    setLocationSerialsList([]);
    SetWorkerID('');
    setJobDuration([]);
    setToDoList([]);
    SetRemark([])
    setCurrentTask('');
    setErrors({});
  };

  return (
    <Modal
      title="Remark"
      visible={visible}
      onCancel={onCancel}
      width={400}
      className={"roundedCorner"}
      footer={[
        <Button key="submit" type="primary" onClick={handleFormSubmit}>
          Update Remark
        </Button>,
      ]}
    >
      <Row gutter={24}>
        {/* Right Column */}
        <Col span={24}>
           <div className='inlineField'>
            <label>Remark List</label>
            <Input
              placeholder="Add Remark"
              value={currentTask}
              onChange={(e) => setCurrentTask(e.target.value)}
              onPressEnter={addTask}
            />
                        <Button type="primary" style={{ marginTop: '8px' }} onClick={addTask}>
              Add
            </Button>
            {errors.toDoList && <div style={{ color: 'red' }}>{errors.toDoList}</div>}
          </div>
                    <div className='listStyleSmall'>
          <Scrollbars style={{ height: 200, marginTop: '10px' }}>
            <List
              dataSource={remark}
              renderItem={(item, index) => (
                <List.Item>
                  <Input
                    value={item}
                    onChange={(e) => editTask(index, e.target.value)}
                    style={{ width: "calc(100% - 25px)" }}
                  />
                  <Button
                    type="text"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => removeTask(index)}
                    className='extrasmallIcon'
                    
                  />
                </List.Item>
              )}
            />
          </Scrollbars>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default Remark;
