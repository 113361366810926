import React, { useState, useCallback,useReducer,useEffect,useRef,useLayoutEffect } from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { CommonHeading } from "../../common/commonHeading";
import Viewer from '../hooks/Viewer'
//import { cameraPlacements } from  '../hooks/_data'
import spinnerLoader from "../../assets/images/loader.svg";
import moment from "moment";
import { getTranslatedText } from  "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";

import { Switch } from 'antd';

import {
  append,
  compose,
  isEmpty,
  map,
  reject,
  intersperse,
  evolve,
  max,
  mapObjIndexed,values, filter
} from 'ramda'

import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";

import { Slider} from 'antd';
import { Select } from 'antd';
import {ReactComponent as Play} from '../../assets/images/circle-play.svg'
import {ReactComponent as Pause} from '../../assets/images/circle-pause.svg'
import LocationIcon from  '../../assets/images/location.png'

import ProductiveIcon from  "../../assets/images/locationProductive.png"
import nonProductiveIcon from "../../assets/images/locationNonProductive.png";
import lessProductiveIcon from "../../assets/images/locationLessProductive.png";

import {getLocationCordinate} from '../../realtimeTracker/actionMethods/actionMethods';

import { getPproductivitylist } from "../../productivity/actionMethods/actionMethods";
import {getMovementData,getActiveEmployee} from '../actionMethods/actionMethod';

// import TextField from '@mui/material/TextField';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { getPproductivityDetailOverview,
  getIndividualProductivityList } from '../../productivity/actionMethods/actionMethods';

  import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import '../style/style.scss'

import '../../realtimeTracker/style/style.scss'
import Scrollbars from 'react-custom-scrollbars';
import 'antd/dist/antd.css';

import CMGBulky from '../../assets/cmg_bulky.json'
import CMGManual from '../../assets/cmg_manual.json'
import { ToastContainer, toast } from 'react-toastify';




let dataUniqueLocation=[];
let previousTag={};
let biDirectional=[];
let removeLayer=[]
let layer=[];
const MovementAnalyzer = (props) => {
  const { Option } = Select;
  
  const INITIAL_MODE = '3d'  
  console.log("props",props)
  const [mode, setMode] = useState(INITIAL_MODE)
  const [activeTab, SetActiveTab]= useState('');
  const [pointData,SetPointData]=useState([]);
  const [spaceID,SetSpaceID]=useState('');
  const [locationCordinate,SetLocationCordinate]= useState([]);
  const [slug,SetSlug] =useState('');
  const [error,SetError]=useState('');
  const [lagFreeFlag,SetLagFreeFlag]=useState(false);
  const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
    mode
  ])
  
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
//const slideTooltip=useRef()
  const slideTooltip = useRef(null);
  

  const [width, setWidth] = useState(0);
 
  const autoElevation = map(
    evolve({ position: { elevation: noElevationIn2D } })
  )
  
  const [space, setSpace] = useState()
  
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);
  const [department,SetDeparment] =useState([]);
  const [department_id,SetDepartmentID]=useState([]);
  
  const [sub_department,SetSubDeparment] =useState([]);
  const [sub_department_id,SetSubDepartmentID]=useState([]);
    
  const [employeeID,SetEmployeeID]=useState([]);
  const [tag_serial,SetTagSerial] = useState([])
  const [employeeAll,SetEmployeeAll]=useState([]);
  const [employeeDefault,SetEmployeeDefault]=useState([]);
  const [loaderEmp,SetLoaderEmp]=useState(false)
  const [start_time,SetStartTime]=useState(props.match.params && props.match.params.hour?props.match.params.hour:'');
  const [end_time,SetEndTime]=useState('');
  const [isloading,Setloading] =useState(true);
  
  const [autoPlay,SetAutoPlay] =useState(false);
  const [productiveLocation,SetProductiveLocation] =useState([])
  const [nonproductiveLocation,SetNonProductiveLocation] =useState([])
  const [lessproductiveLocation,SetLessProductiveLocation] =useState([])
  const [resultUser,SetResultUser] =useState(0);
  let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  
  
  





  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  //  let GeoWidth= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?2:4;
  // let GeoElevation= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?3:5;
  let GeoWidth= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?2:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34)?2.5:(userDetails && parseInt(userDetails.org_id) == 35) || (userDetails && parseInt(userDetails.org_id) == 38) || (userDetails && parseInt(userDetails.org_id) == 37)?1:4;
  let GeoElevation= userDetails && (parseInt(userDetails.org_id) == 31|| parseInt(userDetails.org_id) == 32)?3:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34) || (userDetails && parseInt(userDetails.org_id) == 35)|| (userDetails && parseInt(userDetails.org_id) == 38)||(userDetails && parseInt(userDetails.org_id) == 37)?3:5;

  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let lastReqId = null
  const [slideMax,SetSlideMax]=useState(0);
  const [shiftStarttime,SetShiftStartTime] =useState('');
  const[ ShiftEndtime,SetShiftEndTime]=useState('');
  const [shiftBasedDropdown,SetShiftBasedDropdown] =useState([]);  
  
  const [filterTimeData,SetFilterTimeData] =useState([]);
  const [sucessMessage,SetSucessMessage] =useState(false);

  const [dailyEmpDefault,SetDailyEmpDefault] =useState([]);
  const [dailyEmpDepartment,SetDailyEmpDepartment] =useState([]);
  const [regularEmpDepartment,SetRegularEmpDepartment] =useState([]);
  const [enable,SetEnable] =useState(false);
  const [startBigger,SetStartBigger]=useState(false);  
  const onReady = useCallback(space => setSpace(space), [])
  const elementRef =useRef(null);
  const [heightMap,SetHightMap]= useState(0);
  const [dualLocationList,SetDualLocationList]=useState([])
  const [selectedEmployee,SetSelectedEmployee]=useState([])
  const [forward,SetForward]=useState(true);
  const [oldPosition,SetOldPosition]=useState(0);
  const [loaderLag,SetLoaderLag]=useState(false);
  
  const [workingHourDate,SetWorkingHourDate]= useState({
    start: null,
    end: null,
  })
  
  useEffect(() => {
    if (!space) {
      return
    }
    setTimeout(function(){
      SetLagFreeFlag(true);
    },3000)
  }, [space]);

  
  useEffect(() => {
    
    if(elementRef && elementRef.current){

      SetHightMap(elementRef.current.getBoundingClientRect().height);
      setWidth(elementRef.current.offsetWidth);
    }
    
    
  }, [elementRef,spaceID,isloading]);

useEffect(()=>{
if(props.match.params && props.match.params.tag ){
  let useDaily= dailyEmpDefault.find(item=>item.tag_serial==props.match.params.tag);  
  if(useDaily){
    SetSlug('daily_worker')
    SetDepartmentID(useDaily.department)
  }else{
    let useRegular = employeeAll.find(item=>item.tag_serial==props.match.params.tag);  
    SetSlug('employee');
    if(useRegular){

      SetDepartmentID(useRegular.department)
  
    }
  }


}
},[props.match.params,employeeAll,dailyEmpDefault])



  const [icons, dispatchIcon] = useReducer((icons, action) => {
    switch (action.type) {
      case 'add':
        return [...icons, action.icon]
      case 'update':
        return icons.map(pt =>
          pt.id === action.id ? { ...pt, ...action.updates } : pt
        )
      case 'remove':
        return reject(r => r.id === action.id)(icons)
      default:

    }
  },[])

  const [time,SetTime] = useState([])
  
  const [polylines, dispatchPolyline] = useReducer((polylines, action) => {
  
    switch (action.type) {

      case 'addCoordinate':

        return polylines.map(r =>
          r.id === action.id
            ? { ...r, coordinates: [...r.coordinates, action.coordinate] }
            : r
          
        )
      case 'updateCoordinates':
        return polylines.map(r =>
          r.id === action.id ? { ...r, coordinates: action.coordinates } : r
        )
      case 'removePolyline':
        return reject(r => r.id === action.id)(polylines)
      default:

    }
  }, [ {
    "id": "9e2f2088-01f9-511b-9740-207f8a28870f",
    "name": "W3",
    "coordinates": []
  }])
  const [selectedPoly,SetSelectedPoly] =useState([]);

  useEffect(() => {
    
    let perm= props.pages.filter(el=>el.sub_page_name && el.sub_page_name =="Movement Analyzer");
   
    if(perm){
        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{
    }
},[props.pages]);

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}
useEffect(()=>{
  
  if(permission.length > 0){
    let geoLocation =[]
    getLocationCordinate(userSession,org_id,sub_org_id).then(res=>{
      if(res.status==200){


        if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
            SetSpaceID("8caa8c30-c866-436e-a03c-962c1879841e")
        }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
            SetSpaceID("334db83f-5994-48e5-b08f-b05587c3d143")
        }else{
            SetSpaceID(res.data.space_id)  
        }
          if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
          
          for(let i=0;i<CMGBulky.length;i++){
            let dataFind= res.data.location.find(el=>el.name.toLowerCase().trim() ==CMGBulky[i]['Location  Name'].toLowerCase().trim() )
          
            if(dataFind){
              geoLocation.push(dataFind);
            }

          }
        }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
          
            for(let i=0;i<CMGManual.length;i++){

            let dataFind= res.data.location.find(el=> el.name.toLowerCase().trim() ==CMGManual[i]['Location  Name'].toLowerCase().trim() )
            if(dataFind){
              geoLocation.push(dataFind);
            }
          }

        }else{
          
          geoLocation = res.data.location
        }
  
                  SetLocationCordinate(geoLocation);
        Setloading(false);

      }

    })
  }
},[permission])

useEffect(()=>{
  var date = moment(selectedDate); 
  let startHour = props.workday.start_hour.split(":");
  let endHour = props.workday.start_hour.split(":");
  var timeAndDate = moment(date).set({"hour": startHour[0], "minute": startHour[1]});

  let dt={start:null,end:null}
  if(timeAndDate <= moment() ){
    SetWorkingHourDate((prev) => ({ ...prev, [`start`]:moment(date).set({"hour": parseInt(props.workday.start_hour.split(":")[0]), "minute": parseInt(props.workday.start_hour.split(":")[1]) || 0})   }));
    SetWorkingHourDate((prev) => ({ ...prev, [`end`]:  moment(date).add(addDay,'days').set({"hour": parseInt(props.workday.end_hour.split(":")[0]), "minute": parseInt(props.workday.end_hour.split(":")[1]) || 0}) }));
    
  }else {
    
    SetWorkingHourDate((prev) => ({ ...prev, [`start`]:moment(date).subtract(addDay,'days').set({"hour": parseInt(props.workday.start_hour.split(":")[0]), "minute": parseInt(props.workday.start_hour.split(":")[1]) || 0})   }));
    SetWorkingHourDate((prev) => ({ ...prev, [`end`]:  date.set({"hour": parseInt(props.workday.end_hour.split(":")[0]), "minute": parseInt(props.workday.end_hour.split(":")[1]) || 0}) }));
  }
 
},[selectedDate])

useEffect(()=>{

// Parse the start and end times into Date objects
let start = new Date(workingHourDate.start);
let end = new Date(workingHourDate.end);

// Array to hold the time slots
let timeSlots = [];

// Loop from start time to end time, incrementing each hour
while (start <= end) {
    let formattedDate = start.toLocaleDateString("en-GB", { day: "2-digit", month: "short" }); // Format as "DD MMM"
    let formattedTime = start.toLocaleString("en-US", { hour: "numeric", hour12: true }); // Format as "07 AM"

    timeSlots.push({
        time: `${formattedDate} ${formattedTime}`,
        value: start.toTimeString().slice(0, 8), // Format as "HH:MM:SS"
        intVal: start.getHours(),
        currentTime: new Date(start), // Set to the same hour and minute
    });

    // Increment the time by one hour
    start.setHours(start.getHours() + 1);
}

console.log(timeSlots);
SetTime(timeSlots);
},[workingHourDate])

/*
useEffect(()=>{
 
  SetDepartmentID([]);
  SetDeparment([]);
  SetSubDeparment([]);
  SetSubDepartmentID([]);
  if(props.match.params.hour == start_time && props.match.params.tag==employeeID){

  }else{
    SetEmployeeID([])
  }
  
  SetDailyEmpDefault([]);
  SetEmployeeAll([])
  SetDailyEmpDepartment([]);
  SetSlug('')
  if(permission.length > 0){
    

    let arr=[];

    let requestBody={};
    requestBody.startdate=getDateFormat(selectedDate)
    requestBody.enddate=getDateFormat(selectedDate)
    requestBody.slug='employee'
    let dept=[]
    let catIndex=0;
    let empList= [];
    getPproductivitylist(requestBody,userDetails.session,org_id,sub_org_id).then(res=>{
     
      if (res.status==200){
      


        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empList.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            dept.push({name:key,sub_department:subdept}) 
          }else{

            dept.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              empList.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }


      // SetShowData(arr);
      // SetShowDataDefault(arr);
      SetEmployeeAll(empList)
      SetEmployeeDefault(empList)
      //SetDeparment(dept)
      SetRegularEmpDepartment(dept)
  
      
    })

    let requestBody2={};
    requestBody2.startdate=getDateFormat(selectedDate)
    requestBody2.enddate=getDateFormat(selectedDate)
    requestBody2.slug='daily_worker'
    let deptDaily=[]
    let catIndexDaily=0;
    let empListDaily= [];
    getPproductivitylist(requestBody2,userDetails.session,org_id,sub_org_id).then(res=>{
     
      if (res.status==200){
      


        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empListDaily.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            deptDaily.push({name:key,sub_department:subdept}) 
          }else{

            deptDaily.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              empListDaily.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }

      SetDailyEmpDefault(empListDaily)
      SetDailyEmpDepartment(deptDaily)
  
      
    })



  }

},[permission,selectedDate])  

*/
useEffect(()=>{
  SetDepartmentID([]);
  SetSubDepartmentID([]);
  SetStartTime('');
  SetEmployeeDefault([]);
  SetEmployeeAll([]);
  if(props.match.params.hour == start_time && props.match.params.tag==employeeID){

  }else{
    SetEmployeeID([])
    SetTagSerial([])
    SetSelectedEmployee([])
  }
  
  let dept = slug=='employee'?regularEmpDepartment:dailyEmpDepartment
  SetDepartmentID([]);

},[slug])
useEffect(()=>{
let start = new Date(workingHourDate.start);
let end = new Date(workingHourDate.end);

// Array to hold the time slots
let timeSlots = [];

// Loop from start time to end time, incrementing each hour
while (start <= end) {
    let formattedDate = start.toLocaleDateString("en-GB", { day: "2-digit", month: "short" }); // Format as "DD MMM"
    let formattedTime = start.toLocaleString("en-US", { hour: "numeric", hour12: true }); // Format as "07 AM"

    timeSlots.push({
        time: `${formattedDate} ${formattedTime}`,
        value: start.toTimeString().slice(0, 8), // Format as "HH:MM:SS"
        intVal: start.getHours(),
        currentTime: new Date(start), // Set to the same hour and minute
    });

    // Increment the time by one hour
    start.setHours(start.getHours() + 1);
}

console.log(timeSlots);
SetTime(timeSlots);
  // SetSlug('');
  SetStartTime('');
  SetDepartmentID([]);
  SetSubDepartmentID([]);
  SetEmployeeID([]);
  SetTagSerial([])
},[workingHourDate])

useEffect(()=>{
  if(permission.length > 0){
    let sub=[];  
    let subDept=[];
    SetSubDeparment([]);
    //SetEmployeeID('')
    if(props.match.params.hour == start_time &&  props.match.params.tag==employeeID){

    }else{
      SetEmployeeID([])
      SetTagSerial([])
      SetSelectedEmployee([])
    }
    SetSubDepartmentID([])

    let sub2= department.filter(item=>department_id.indexOf(item.department) > -1 )
    

    let subCat=[]
    if(sub2.length> 0){

      for(let i=0;i<sub2.length;i++){
        subCat.push(...sub2[i].sub_department)
      }
      SetSubDeparment([...new Set(subCat)]);  
    }    

  }
},[permission,department_id])

useEffect(()=>{
  if(permission.length > 0){
    let sub=[];  
    let subDept=[];
    //SetEmployeeID('')
    if(props.match.params.hour == start_time & props.match.params.tag==employeeID){

    }else{
      SetEmployeeID([])
      SetTagSerial([])
      SetSelectedEmployee([])
    }

  }
},[permission,sub_department_id])




function removeLayer(){
  if(layer.length == 0){
    SetLoaderLag(false);
  }else{
    SetLoaderLag(true);
  }
  setTimeout(function(){
    SetLoaderLag(false);
    for(let i=0;i<layer.length;i++){
      let poly=[];
      space.removeDataLayer(layer[i].id);
      space.removeDataLayer(layer[i].polyID);
    }
    layer =[];
    for(let i= 0;i<locationCordinate.length;i++ ){
      
        updateDataLayer(locationCordinate[i],'','',GeoWidth)        
    }
  },1000)
}

// useEffect(() => {
//   if(slideTooltip.current){
//     setWidth(slideTooltip.current.offsetWidth);
//   }
  
// }, [slideTooltip]);
  function handleDateSelect(date) {
    updateSelectedDate(date);
        SetSlug('');
       SetStartTime([]);
       setCount(0);
           SetSucessMessage(false);
           SetLoaderEmp(false);
       SetDefaultPoly([])


      removeLayer()    

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function AddDataLayer(data){

    if(space){
    space.addDataLayer({
      id: data.id,
      type: 'icon',
    
      data: [{
        position: data.position, 
      }],
      icon: {
        url: data.url,
        width: 96,
        height: 96
      },
      width: GeoWidth,
      anchor: 'top',
      tooltip: d => data.tooltip,
      onClick: d=> filterResultLocation(data.tooltip),
    })
  }
}

  function updateDataLayer(data,url,width,widthSize,inc){
    inc=inc?inc:0;
   
   
    if(space){
    if(url){
      space.updateDataLayer({
        id: data.unique_loc_id,
        data: [{
          position: data.position, 
        }],
        icon: {
          url: url,
          width: width?width:96,
          height: width?width:96
        },
        width: data.widthSize?data.widthSize:GeoWidth,
        anchor: 'top',
        tooltip: d => data.name +" : "+moment.utc(inc*1000*30).format("HH:mm:ss"),
        onClick: d=> filterResultLocation(data.name),
      })
    
    }else{
    
      space.updateDataLayer({
        id: data.unique_loc_id,
        data: [{
          position: data.position, 
        }],
        width: widthSize?widthSize:GeoWidth,
        anchor: 'top',
        tooltip: d => data.name +" : "+moment.utc(inc*1000*30).format("HH:mm:ss"),
        onClick: d=> filterResultLocation(data.name),
      })
    }
  }

    
  }

  function filterResultLocation(name){
/*    
    SetEnable(false)
    SetFilterTimeData(defaultPoly.filter(item=>item.name==name));
*/

  }
  

  
  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(()=>{
    if (!space) {
      return
    }
    let data = [...locationCordinate] 
    if(data.length>0){


      for (let i = 0;i<data.length;i++){
       
        
        let arr={};
        arr.type='add'
        arr.icon={}
        arr.id=data[i].unique_loc_id;
        arr.tooltip=data[i].name 
        arr.position= data[i].position
        // arr.position.elevation=GeoElevation
        if(arr.position && arr.position.elevation){
          arr.position.elevation=GeoElevation
        }
        arr.name = data[i].name
        arr.width = 96

        arr.url = LocationIcon
        if(productiveLocation.indexOf(data[i])!=-1){
         
          arr.url = ProductiveIcon
        }
        else if(nonproductiveLocation.indexOf(data[i])!=-1){
         
          arr.url = nonProductiveIcon
        }
        else if(lessproductiveLocation.indexOf(data[i])!=-1){
         
          arr.url = lessProductiveIcon
        }
        arr.widthSize=GeoWidth
        //dispatchIcon(arr);
        AddDataLayer(arr)

      }
    }    
  },[locationCordinate,spaceID,space])

function convertTimeZone(time){
 const utcTimeString = time;
const utcDateTime = new Date(utcTimeString + "Z"); // Append 'Z' to indicate UTC time
const indonesiaTimeZone = userDetails?.organization_timezone?.organization_timezone_name || 'Asia/Jakarta';  
const indonesiaOptions = { timeZone: indonesiaTimeZone };
const indonesiaTimeString = utcDateTime.toLocaleString("en-US", indonesiaOptions);



return moment(indonesiaTimeString).format('hh:mm:ss A')

} 

  useEffect (() => {
    if(permission.employee=='False'){
      return
    }

    if (!space) {
      return
    }
 
    space.addDataLayer({
      id: 'icons',
      type: 'icon',
      data: autoElevation(icons),
      icon: {
        url: LocationIcon,
        width: 512,
        height: 512
      },
      width: GeoWidth,
      anchor: 'top',
      tooltip: d => d.name,
    })
    
  }, [space, icons, autoElevation,permission])

  



  
  const  [marks,SetMark]  = useState([]) 
  const [marks2,SetMark2] =useState([]) 
  






  const [defaultPoly,SetDefaultPoly] = useState([])

  const [currentCount, setCount] = useState(0);
  const randomcolor = ['#000','#d95780','#51ac30','#101535','#b8cdda','#ffa500','#ff0000']

  useEffect(() => {
    if (!space) {
      return
    }
    let ctPosition  = [...dualLocationList];
    let grayLayer=[{
      "id": "9e2f2088-01f9-511b-9740-207f8a2887-gray",
      "name": "W3",
      "coordinates": []
  }]
    let yellowLayer=[{
      "id": "9e2f2088-01f9-511b-9740-207f8a2887-yellow",
      "name": "W3",
      "coordinates": []
  }]
  let redLayer=[{
    "id": "9e2f2088-01f9-511b-9740-207f8a2887-yellow",
    "name": "W3",
    "coordinates": []
  }]
  if(selectedEmployee.length>0){ 
  
    for(let i=0;i<layer.length;i++ ){
    space.removeDataLayer(layer[i].id);
    space.removeDataLayer(layer[i].polyID);
  }
  layer=[];
  let oldCount = oldPosition
    let locationList = dualLocationList.filter(item => item.position > oldPosition &&  selectedEmployee.indexOf(item.employee_tag)> -1);
    
  
  for(let i=0;i<locationList.length;i++ ){
  
    if(locationList[i].position <=currentCount){
  
      let polyCord= [];
        polyCord.push(locationList[i].old);
        polyCord.push(locationList[i].new);
        
        let count= defaultPoly.length;
        let poly=[{
          "id": "9e2f2088-01f9-511b-9740-207f8a2887"+i,
          "name": "W3",
          "coordinates": polyCord
      }]
  
  
    let countPosition = locationList[i].position;    
    let oldserial = locationList[i].old.tag_serial;    
    let countFilter = ctPosition.filter(item=> item.position <= countPosition);  
  
    if(countFilter.length> 0){
  
     
      countFilter  = countFilter.filter(item=>
          (item.new.tag_serial ==locationList[i].new.tag_serial && oldserial == item.old.tag_serial) ||   
          (item.new.tag_serial ==oldserial && item.old.tag_serial == locationList[i].new.tag_serial)
          ) ;
        }
  
  
      if(countFilter.length<= (( (defaultPoly.length/resultUser)*20)/100) ){
  
        polyCord[0].x=polyCord[0].x
        polyCord[0].y=polyCord[0].y
        polyCord[0].levelIndex=polyCord[0].levelIndex
        polyCord[0].elevation=GeoElevation - 0.5;
      
        polyCord[1].x=polyCord[1].x
        polyCord[1].y=polyCord[1].y
        
        polyCord[1].elevation=GeoElevation - 0.5;
        polyCord[1].levelIndex=polyCord[1].levelIndex
        // space.addDataLayer({
        //   id: 'polylines'+locationList[i].position,
        //   type: 'polyline',
        //   data: compose(
        //     map(
        //       evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
        //     ),
        //     reject(p => isEmpty(p.coordinates))
        //   )(poly),
        //   shape: 'triangle',
        //   cap:true,
        //   stepSize:15,
        //   scale: 0.5,
        //   color:'#413d3d',
        //   tooltip: d => d.name,
        // })
        grayLayer[0].coordinates.push(...polyCord)
      }else if(countFilter.length > (((defaultPoly.length/resultUser)*20)/100) && countFilter.length <=(((defaultPoly.length/resultUser)*40)/100)){
      
        polyCord[0].x=polyCord[0].x
        polyCord[0].y=polyCord[0].y
        polyCord[0].elevation=GeoElevation + 1;
        polyCord[0].levelIndex=polyCord[0].levelIndex
        polyCord[1].x=polyCord[1].x
        polyCord[1].y=polyCord[1].y
        polyCord[1].elevation=GeoElevation + 1
        polyCord[1].levelIndex=polyCord[1].levelIndex
        // space.addDataLayer({
        //   id: 'polylines'+locationList[i].position,
        //   type: 'polyline',
        //   data: compose(
        //     map(
        //       evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
        //     ),
        //     reject(p => isEmpty(p.coordinates))
        //   )(poly),
        //   shape: 'triangle',
        //   cap:true,
        //   stepSize:15,
        //   scale: 0.5,
        //   color:'#cf8c12',
        //   tooltip: d => d.name,
        // })
        yellowLayer[0].coordinates.push(...polyCord)
      }else{
        polyCord[0].x=polyCord[0].x
        polyCord[0].y=polyCord[0].y
        polyCord[0].elevation=GeoElevation + 2;
        polyCord[0].levelIndex=polyCord[0].levelIndex
        polyCord[1].x=polyCord[1].x
        polyCord[1].y=polyCord[1].y
        polyCord[1].elevation=GeoElevation + 2;
        polyCord[1].levelIndex=polyCord[1].levelIndex
        // space.addDataLayer({
        //   id: 'polylines'+locationList[i].position,
        //   type: 'polyline',
        //   data: compose(
        //     map(
        //       evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
        //     ),
        //     reject(p => isEmpty(p.coordinates))
        //   )(poly),
        //   shape: 'triangle',
        //   cap:true,
        //   stepSize:15,
        //   scale: 0.5,
        //   color:'#9c0808',
        //   tooltip: d => d.name,
        // })
        redLayer[0].coordinates.push(...polyCord)
      } 
      }
    }

    if(grayLayer[0].coordinates.length> 0){
      
      
      layer.push({
        "id": grayLayer[0].id,
        "polyID":'polylines-grey',
    
    });  
      space.addDataLayer({
          id: 'polylines-grey',
          type: 'polyline',
          data: compose(
            map(
              evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
            ),
            reject(p => isEmpty(p.coordinates))
          )(grayLayer),
          shape: 'triangle',
          cap:true,
          stepSize:15,
          scale: 0.5,
          color:'#413d3d',
          tooltip: d => d.name,
        })
    }
    if(yellowLayer[0].coordinates.length> 0){      
      layer.push({
        "id": yellowLayer[0].id,
        "polyID":'polylines-yellow',
    });  
      space.addDataLayer({
          id: 'polylines-yellow',
          type: 'polyline',
          data: compose(
            map(
              evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
            ),
            reject(p => isEmpty(p.coordinates))
          )(yellowLayer),
          shape: 'triangle',
          cap:true,
          stepSize:15,
          scale: 0.5,
          color:'#cf8c12',
          tooltip: d => d.name,
        })
    }
    if(redLayer[0].coordinates.length> 0){      
      layer.push({
        "id": redLayer[0].id,
        "polyID":'polylines-red',
    });  
    space.addDataLayer({
        id: 'polylines-red',
        type:'polyline' ,
        data: compose(
          map(
            evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
          ),
          reject(p => isEmpty(p.coordinates))
        )(redLayer),
        shape: 'triangle',
        cap:true,
        stepSize:15,
        scale: 0.5,
        color:'#9c0808',
        tooltip: d => d.name,
    })
    }
  }else{
    for(let i=0;i<layer.length;i++ ){
      space.removeDataLayer(layer[i].id);
      space.removeDataLayer(layer[i].polyID);
    }
  }
    
  
  }, [space,dualLocationList,currentCount,selectedEmployee, noElevationIn2D])


  const timer = () => {
    // setCount(currentCount + 1);    
  }

  useEffect(() => {

        if (!space) {

          return
        }   
        if (currentCount > (defaultPoly.length) || autoPlay===false || loaderEmp) {
        
            return;
        }
        const id = setInterval(timer, 500);

         let line = [...polylines];
         let cord = defaultPoly.slice(0,(currentCount)); 
          let datatimeData = [...filterTimeData] 
          if(defaultPoly[currentCount]){          
            
            
            
            if(enable){
              let previousData = datatimeData[datatimeData.length - 1]?datatimeData[datatimeData.length - 1]:{name:""}

              if(defaultPoly[currentCount].name != previousData.name ){
                defaultPoly[currentCount].time_spent = 30000; 
                
                SetFilterTimeData(current => [...current, defaultPoly[currentCount]]);
              }else{
                let old= [...filterTimeData];
                if(datatimeData[datatimeData.length - 1]){
                  datatimeData[datatimeData.length - 1].time_spent = datatimeData[datatimeData.length - 1].time_spent + 30000;
                  SetFilterTimeData(datatimeData);
                }else{
                
                }
                
              }
            }else{
              SetFilterTimeData(current => [...current, defaultPoly[currentCount]]);
            }  



          let sizeUpdate= cord.filter(item=>item.tag_serial == defaultPoly[currentCount].tag_serial);
          let currentLocation = locationCordinate.find(item=>item.tag_serial==defaultPoly[currentCount].tag_serial);
          if(currentLocation){
            // updateDataLayer(currentLocation,'','',(4+sizeUpdate.length/10),sizeUpdate.length==0?1:sizeUpdate.length + 1)
            let sizeInc= org_id==32?GeoWidth+sizeUpdate.length/30:GeoWidth+sizeUpdate.length/10
            updateDataLayer(currentLocation,'','',(GeoWidth+sizeUpdate.length/10),sizeUpdate.length==0?1:sizeUpdate.length )
            
           }
        }

          dispatchPolyline({
            type: 'updateCoordinates',
            id: line[0].id,
            coordinates:cord
          })
      
       return () => clearInterval(id);
      },[currentCount,space,autoPlay]);

      // useEffect(() => {

      //   if (!space) {

      //     return
      //   }   
      //   if(autoPlay){

      //   }else{
      //     let line = [...polylines];
      //     //let cord = defaultPoly.slice(0,currentCount+1);
      //     let cord = defaultPoly.slice(0,(currentCount));
          
          
          
      //     let filterData = defaultPoly.slice(0,(currentCount));
          
      
      //       //SetFilterTimeData(filterData);

      //       if(enable){
      //         //here formattion occur filterData
      //         let unqiueLoc=[];
      //         let prevName='';
      //         for(let i= 0;i<filterData.length;i++){
                 
      //             if(filterData[i].name!=prevName){
      //               prevName = filterData[i].name
      //               filterData[i].time_spent = 30000;
      //               unqiueLoc.push(filterData[i])
      //             }else{
      //               filterData[i - 1].time_spent = filterData[i - 1].time_spent + 30000;
      //             }
      //         }
      //         SetFilterTimeData(unqiueLoc);

      //       }else{
      //         SetFilterTimeData(filterData);
      //       }


      //       for (let i = 0;i<dataUniqueLocation.length;i++){
      //         let filterLength= locationCordinate.filter(item => item.name == dataUniqueLocation[i]) 
      //         let sizeUpdate= cord.filter(item=>item.name == dataUniqueLocation[i]);
      //         let currentLocation = locationCordinate.find(item=>item.name == dataUniqueLocation[i]);
      //         if(currentLocation){
      //           updateDataLayer(currentLocation,'','',(4+sizeUpdate.length/10),sizeUpdate.length==0?1:sizeUpdate.length + 1)
      //         }
      //       }

            
      //       dispatchPolyline({  
      //         type: 'updateCoordinates',
      //         id: line[0].id,
      //         coordinates:cord
      //       })
      //     }
          
      // },[currentCount,space,autoPlay]);



      function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
    
    
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);

    
  
  
    const handleDate = (date, type) => {
      // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
    };

    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }

    function changeTab(name){
      SetActiveTab(name);
    }
    function HandleRange(e){
      if(currentCount> e){
        SetForward(false);
      }else{
        SetForward(true);
      }
      setCount(e);
      // SetAutoPlay(false);       
    }
   function autoPlayhandler(){
    // SetAutoPlay(!autoPlay)
    if(currentCount > icons.length){
      // setCount(0);
    }else{
      
    }
   }

   useEffect(()=>{
    for(let i= 0;i<locationCordinate.length;i++ ){
      let sizeUpdate= defaultPoly.filter(item=>item.tag_serial == locationCordinate[i].tag_serial && item.position <= currentCount);    
        // updateDataLayer(locationCordinate[i],'','',(4+sizeUpdate.length/(5*employeeID.length)),sizeUpdate.length==0?1:sizeUpdate.length + 1)        
        updateDataLayer(locationCordinate[i],'','',(GeoWidth+sizeUpdate.length/(5*employeeID.length)),sizeUpdate.length==0?1:sizeUpdate.length)        
    }
   },[currentCount])
   function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  

  // function filterResultHandler(tag=employeeID,stime=start_time?start_time:16,bigTimer=startBigger){
  function filterResultHandler(tag=employeeID,stime=start_time,bigTimer=startBigger){

    SetDefaultPoly([]);
    setCount(0)
    let requestBody={}

    requestBody.start_time = moment(start_time).format("HH")+":00:00"; 
    requestBody.end_time =moment(start_time).add(1, 'hour').format("HH")+":00:00";
    requestBody.date= getDateFormat(start_time);
    requestBody.worker_id= employeeID.join(',')
    requestBody.worker_id= employeeID.join(',')
    requestBody.work_day= moment(selectedDate).format('YYYY-MM-DD')

    const diff =  moment(requestBody.end_time) - moment(requestBody.start_time) ;
  


      

  
   
      let aMs = moment(requestBody.start_time, 'HH:mm:ss').format('x')
      let bMs = moment(requestBody.end_time, 'HH:mm:ss').format('x')
  
      
    let difference =   bMs - aMs

 
    
    SetLoaderEmp(true);
    let arr = [];
    let locationPoint = [...locationCordinate];
    //let data = [...dummydata]
    let mk = []
    let mk2 = []
    SetError('');
    SetFilterTimeData([])
    SetSucessMessage(false);
    dataUniqueLocation =[];
    //  let res= movement;
    let previousLocation=[]
    let dualLocation=[]
  
   getMovementData(requestBody,userSession,org_id,sub_org_id).then(res=>{
      let dtCount=0;
      if(res.status==200){
  
        SetLoaderEmp(false);
        
        // let data = res.data['01D046C743458E']?res.data['01D046C743458E']:res.data;

        for(let i=0;i<tag_serial.length;i++){

        let data = res.data[tag_serial[i]]?res.data[tag_serial[i]]:[];
        
        let locName='';
          if(data.length > 0){
            dtCount++;
            data = data.sort((a,b) => {
              let aMs = moment(Object.values(a)[0], 'HH:mm:ss').format('x')
              let bMs = moment(Object.values(b)[0], 'HH:mm:ss').format('x')
      
              return    aMs - bMs
            })
          }
        let incCount=0;
        let empName=employeeDefault.find(item=>item.tag_serial==tag_serial[i]);

        Object.keys(data).forEach((key) => {
  
          let findPOs = locationPoint.find(item=>item.tag_serial == Object.keys(data[key])[0]) 

          
          let polylineFormat = {}
          if(findPOs){
            let indexLoc = dataUniqueLocation.indexOf(findPOs.name);
            mk.push(moment(Object.values(data[key])[0]));
            if(indexLoc === -1){
              dataUniqueLocation.push(findPOs.name);  
            }
          
            polylineFormat.levelIndex=findPOs.position.levelIndex?findPOs.position.levelIndex: 0
            polylineFormat.x=findPOs.position.x  +  ((0.1*(Math.random() - .1) * 10))
            polylineFormat.z=findPOs.position.z +  ((0.1*(Math.random() - .1) * 10))
            polylineFormat.elevation = GeoElevation
            polylineFormat.name=findPOs.name
            polylineFormat.tag_serial=findPOs.tag_serial
            polylineFormat.time=Object.values(data[key])[0]
            polylineFormat.employee_tag=tag_serial[i]
            polylineFormat.position=incCount;
            polylineFormat.emp_name=empName?.name;
            arr.push(polylineFormat);
          

          if(locName==Object.keys(data[key])[0]){

          }else{

            previousLocation.push(polylineFormat);
  
            if(previousLocation.length> 1){
              let oldloc = previousLocation[previousLocation.length - 2];
              let newloc = previousLocation[previousLocation.length - 1];
              let merge = {}
              merge.old={}
              merge.new={}
              merge.old.levelIndex=oldloc.levelIndex?oldloc.levelIndex:0;
              merge.old.x=oldloc.x?oldloc.x:"";
              merge.old.z=oldloc.z?oldloc.z:"";
              merge.old.elevation =oldloc.elevation?oldloc.elevation:"";
              merge.old.name=oldloc.name?oldloc.name:"";
              merge.old.tag_serial=oldloc.tag_serial?oldloc.tag_serial:""
              merge.old.time=oldloc.time?oldloc.time:""
              merge.new.levelIndex=newloc.levelIndex?newloc.levelIndex:0;
              merge.new.x=newloc.x?newloc.x:"";
              merge.new.z=newloc.z?newloc.z:"";
              merge.new.elevation =newloc.elevation?newloc.elevation:""
              merge.new.name=newloc.name?newloc.name:""
              merge.new.tag_serial=newloc.tag_serial?newloc.tag_serial:""
              merge.new.time=newloc.time?newloc.time:""
              merge.position=incCount;
              merge.employee_tag=tag_serial[i];
              merge.worker_id=empName.worker_id;
              dualLocation.push(merge);

            }


            
            locName=Object.keys(data[key])[0];
            mk2.push(moment(Object.values(data[key])[0]));
          } 
          incCount++;       
         }
         });

        arr = arr.sort(function(a,b){
          return new Date(a.time) - new Date(b.time);
        });
        mk = mk.sort(function(a,b){
          return  new Date(a) - new Date(b);
        });
       mk = mk.map((item)=> {return  moment(item).utc().format('hh:mm:ss A') })
       


       // let half=Math.ceil(mk.length / 2);

    //    SetMark(mk);
        //SetMark2(mk.splice(-half));
        
  
      }
    }else{
      SetLoaderEmp(false);
      toast.error("Something went wrong while fetching movement data");
    }

    SetResultUser(dtCount);
    SetDefaultPoly(arr);
    // SetAutoPlay(true);
    SetSucessMessage(true);
    let empWithPoly=[]
    for(let i=0;i<tag_serial.length;i++){
      
      if(dualLocation.filter(item=>item.employee_tag==tag_serial[i]).length>0){
        empWithPoly.push(tag_serial[i])
      }  
    }
   
    SetSelectedEmployee(empWithPoly)
     SetDualLocationList(dualLocation);
     SetOldPosition(0);
     setCount(120);

  })  
  
  

  
  
    
  }

  function onChangeEnable(val){
    
    // SetEnable(val);

    let data= [...filterTimeData];
    let filterData = defaultPoly.slice(0,(currentCount));
   
    if(val){

      let unqiueLoc=[];
      let prevName='';
      for(let i= 0;i<filterData.length;i++){
          if(filterData[i].name!=prevName){
            prevName = filterData[i].name
            filterData[i].time_spent = 30000;
            unqiueLoc.push(filterData[i])
          }else{
            prevName = filterData[i].name
            filterData[i - 1].time_spent = filterData[i - 1].time_spent + 30000;
            
          }
      }
      SetFilterTimeData(unqiueLoc);
    }else{
      SetFilterTimeData(filterData);
    }

  }

  function formatAMPMHour(date) {
    var hours = date;
    var minutes = 0;
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  function onChangeEmployee(val){
    let dept= employeeAll;
    
      if(val.indexOf('all')> -1){
          let value =[];
          for(let i=0;i<dept.length;i++){
            value.push(dept[i].worker_id)
          }
          if((val.length -1) == value.length ){
            SetEmployeeID([]);  
            SetSelectedEmployee([])
          }else{
            SetEmployeeID(value);
            let allTag = value.map(el=>{
              let tag= employeeAll.find(item=>item.worker_id == el)?.tag_serial
              return tag;
            })
            SetTagSerial(allTag)
            SetSelectedEmployee([])
          }
    
          
      }else{
        let allTag = val.map(el=>{
              let tag= employeeAll.find(item=>item.worker_id == el)?.tag_serial
              return tag;
            })
        SetEmployeeID(val);
        SetTagSerial(allTag)
      }
    }
    function onChangeEmployee2(val){
      let dept= employeeID.map((item,i)=>{                   
        if(dualLocationList.filter(item=>item.employee_tag==employeeID[i]).length > 0)
          return item;
      })      
        if(val.indexOf('all')> -1){
            let value =[];
            for(let i=0;i<dept.length;i++){
              value.push(dept[i])
            }
            if((val.length -1) == value.length ){
              SetSelectedEmployee([]);  
            }else{
              SetSelectedEmployee(value);
            }
      
            
        }else{
          SetSelectedEmployee(val);
        }
      }
    function changeDepartment(val){
      let dept= department;
      SetEmployeeID([]);
    
      if(val.indexOf('all')> -1){
          let value =[];
          for(let i=0;i<dept.length;i++){
            value.push(dept[i].department)
          }
          if((val.length -1) == value.length ){
            SetDepartmentID([]);  
            SetEmployeeAll([]);
          }else{

            let empList2 = employeeDefault.filter(item=>value.indexOf(item.department_name) > -1);
          
            SetEmployeeAll(empList2);
            SetDepartmentID(value);
          }
    
          
      }else{
        let empList2 = employeeDefault.filter(item=>val.indexOf(item.department_name) > -1);
    
        SetEmployeeAll(empList2);
        SetDepartmentID(val);
      }
    }
    function changeSubDepartment(val){
      let dept= sub_department;
      let actualSub=[];
      if(val.indexOf('all')> -1){
          let value =[];
          for(let i=0;i<dept.length;i++){
            value.push(dept[i])
          }
          if((val.length -1) == value.length ){
            
            SetSubDepartmentID([]);
            // let empList2 = employeeDefault.filter(item=>department_id.indexOf(item.department_name) > -1);
            // SetEmployeeAll(empList2);
          }else{
            actualSub=value;
            // let empList2 = employeeDefault.filter(item=>department_id.indexOf(item.department_name) > -1 );
            //  SetEmployeeAll(empList2);
            SetSubDepartmentID(value);
          }
    
          
      }else{
        let emp=[]
        let sub=[...val];
        SetSubDepartmentID(sub);
        actualSub=val;
       
        // SetEmployeeAll([...new Set(emp.map(item => item.tag_serial))]);
        
      }
      // for(let i=0;i<department.length; i++){
      
      //   if(department_id.indexOf(department[i].department) > -1 ){
      //     let checkAnyExist=false;

      //     for(let j=0;j<department[i].sub_department.length;j++){
      //      if(actualSub.indexOf(department[i].sub_department[j]) > -1 ){
      //       checkAnyExist=true
      //      } 
      //     }
      //     if(checkAnyExist){
            
      //     }else{
      //       actualSub.push(...department[i].sub_department);
      //     }          
      //   }
      // }

      let empList=employeeDefault.filter(item=>actualSub.indexOf(item.sub_department_name)> -1);
   
      SetEmployeeAll(empList);


    }
    function changeEmployeeList(start,selectedDate,slug){
      let requestBody={};
    
      requestBody.hour= moment(start).format('HH')+":00:00";
      requestBody.date=getDateFormat(start);
      requestBody.slug=slug=='employee'?'regular':'daily';
      SetDepartmentID([]);
      SetSubDepartmentID([]);
      SetEmployeeDefault([]);
      SetEmployeeAll([]);
      
      getActiveEmployee(requestBody,userSession,org_id,sub_org_id).then(res=>{
        if(res.status==200){
          let cat=[];
          
          for(let i=0;i<res.data.length;i++){
            let index= cat.findIndex(item=>item.department == res.data[i].department_name )
            if( index > -1){
              cat[index].sub_department.push(res.data[i].sub_department_name);
            }else{
              cat.push({department:res.data[i].department_name,sub_department:[res.data[i].sub_department_name] })              
            }

          }
          SetDeparment(cat);
          SetEmployeeDefault(res.data);
          SetEmployeeAll(res.data);          
        }
      })
    }
    

  
  return (<>
    {permissionAPICall?permission.length > 0? 
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <ToastContainer/>
      <Row>
        <Col lg={6}>
          
          <div className='tabHeader'>
            
              {props.pages.filter(el=> el && el.sub_page_name =="Employee Tracker" ).length >0? <div className='tabHeaderList ' onClick={()=>props.history.push('/employee-tracker')} >
              <CommonHeading title="Employee Tracker" />
              </div>:""}   
              {props.pages.filter(el=> el && el.sub_page_name =="Facility Tracker" ).length >0?<div className='tabHeaderList ' onClick={()=>props.history.push('/geo-map-management')}>
                <CommonHeading title="Facility Tracker" />
              </div>:""}
              {props.pages.filter(el=> el && el.sub_page_name =="Employee Tracker" ).length >0?<div className='tabHeaderList active' onClick={()=>props.history.push('/movement-analyzer')}>
                  <CommonHeading title="Movement Analyzer" />
              </div>:""}
           </div>   
        </Col>
        <Col lg={6} className="text-right">
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="commonHeadingDateMainDivParent">
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
                              weekDays={props.weekDays}
                holiday={props.holiday}
                workday={props.workday}
            />
          </div>
        </Col>
      </Row>

      <Row className="m-t" >
        <Col lg={12} >
          <div className='manStyle' style={{backgroundColor:"#fff",width:"100%",float:"left"}}>
          <div className='inlineBlockFilter'>
              <h5 style={{marginBottom:0,lineHeight:"35px"}}> Filter</h5>
            </div>
            <div className='inlineBlockFilter'>
            <Select
                placeholder="Selct User Type"
                optionFilterProp="children"
                onChange={(val)=>{SetSlug(val);removeLayer() }}
                //defaultValue={'Select Department'}
                value={slug==''?'Select User Type':slug}
                key="departmentUserType"
                style={{width:140}}
                // disabled={!space || !lagFreeFlag}
                
            >
              <Option value='employee' key={"UserTypeRegular All"}>Regular Worker</Option>
              <Option value='daily_worker' key={"UserTypeDaily All"}>Daily Worker</Option>
              
            </Select>            
          </div>
          <div className='inlineBlockFilter inlineBlockFilterRearrange'>
                  <label style={{width:70}}>Hours</label> <span style={{float:"right"}}></span>
                <Select
                    placeholder="Select Start Time"
                    optionFilterProp="children"
                    style={{width:130}}
                    onChange={(val)=>{SetStartTime(val);changeEmployeeList(val,selectedDate,slug);removeLayer() }}
                    
                    value={start_time}
                    disabled={slug==''|| !space}
                    key="StartTimeV1"
                    dropdownClassName={'smallerDrop'}

                >
                    {
                    time.map((item,index)=>{
                      
                        return <Option value={moment(item.currentTime).format('YYYY-MM-DD HH:mm:ss')} disabled={moment(item.currentTime)> moment()} key={"Employee V1 TIme"+item.time+index}>{ item.time}</Option>
                    })
                    }
      
                </Select>
            </div>


            

            <div className='inlineBlockFilter inlineBlockFilterRearrange'>
              <Select
                placeholder="Select Department"
                onChange={(val)=>{changeDepartment(val);removeLayer() }}
                value={department_id}
                key="departmentFilterv1"
                style={{width:200}}
                dropdownClassName={'smallerDrop'}
                disabled={slug==''|| start_time.length== 0?true:false}
                 mode="multiple"
            >
             <Option value='all' key={"Department v1 All"}>Select All</Option>
                {
                department && department.length > 0? department.map((item,index)=>{
                    return <Option value={item.department} key={"Department V1"+item.department+index}>{item.department}</Option>
                }):""
                }
            </Select>
                </div>
                <div className='inlineBlockFilter inlineBlockFilterRearrange'>
                <Select
                    placeholder="Select Sub Department"
                    optionFilterProp="children"
                    style={{width:200}}
                    onChange={(val)=>{changeSubDepartment(val);removeLayer() }}
                    disabled={department_id=='' || start_time.length==0?true:false}                   
                    value={sub_department_id}
                    key="SubdepartmentFilterv1"
                    dropdownClassName={'smallerDrop'}
                    mode="multiple"
                >
                  <Option value='all' key={"Sub Department v1 All"}>Select All</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item,index)=>{
                        return <Option value={item} key={"Sub Department V1"+item+index}>{item}</Option>
                    }):""
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter inlineBlockFilterRearrange'>
                <Select
                  placeholder="Select Employee"
                  optionFilterProp="children"
                  style={{width:150}}
                onChange={(val)=>{onChangeEmployee(val); }  }
                disabled={department_id.length==0 || sub_department_id.length==0 || start_time.length==0?true:false} 
                //defaultValue={'Select Department'}
                value={employeeID}
                key="employeeFIlterv1"
                 mode="multiple"
                dropdownClassName={'smallerDrop'}
  
            >
            <Option value='all' key={"Select EMployee v2 All"}>Select All</Option>                  
              {
                    employeeAll && employeeAll.length > 0? employeeAll.map((item,index)=>{
                        return <Option value={item.worker_id} key={"Employee V2"+item.name+index+Math.random(0,1)}>{item.name}</Option>
                    }):""
                    }
                    
                </Select>



                </div>
                {/* <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Time"
                    optionFilterProp="children"
                    style={{width:150}}
                    onChange={(val)=>{SetStartTime(val);}}
                    value={start_time!=""||start_time==0?start_time:"Start Time"}
                    key="EndTimeV1"
                    dropdownClassName={'smallerDrop'}
                    disabled={shiftStarttime==''||ShiftEndtime==''}

                    

                >
                    {
                    shiftBasedDropdown.map((item)=>{

                        return <Option value={item} key={"Employee V2"+item}>{item+":00"}</Option>
                    })
                    }
                    
                </Select>
                </div> */}
                <div className='inlineBlockFilter'>
                  <div className="btndiv">
                      <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()}
                       disabled={ department_id.length==0  || start_time.toString()==''  || employeeID.length ==0}
                       >Submit</button>
                      
                  </div>                
                </div>


</div>
</Col>
    </Row>




      <Row className="m-t">
        <Col lg={9} ref={elementRef} className='tabletfull'>
 
 
                    {loaderEmp? <div className='loaderStyle' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4> 
                    
                    

                  </div></div>:''}
                  
                  {sucessMessage && defaultPoly.length >0?<div className='loaderStyle loadStyleSucess' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>Ready 
                  
                      <div className='inlineBlockFilterRight'>
                <Select
                  placeholder="Select Employee"
                  optionFilterProp="children"
                  style={{width:150}}
                onChange={(val)=>{onChangeEmployee2(val) }  }
                // showArrow={true}
                showSearch={false}
                //defaultValue={'Select Department'}
                value={selectedEmployee}
                key="employeeFIlterv2"
                 mode="multiple"
                dropdownClassName={'smallerDrop'}
  
            >
            <Option value='all' key={"Select EMployee v2 All"}>Select All</Option>                  
              {
                    employeeID && employeeID.length > 0? employeeID.map((item,i)=>{
                     
                      
                      if(dualLocationList.filter(item=>item.worker_id==employeeID[i]).length > 0)
                        return <Option value={employeeDefault.find(e=>e.worker_id==item)?.tag_serial || item } key={"Employee V2 ID"+item+i}>{employeeDefault.find(e=>e.worker_id==item)?.name }</Option>
                    }):""
                    }
                    
                </Select>
                      </div>
                      </h4> 
                    
                    

                  </div></div>:sucessMessage && defaultPoly.length ==0?<div className='loaderStyle loadStyleError' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>Oops! This Hour data not available</h4> 
                  </div></div>:""}
                  {isloading? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:spaceID!=''?<>
                                         <div ><Viewer onReady={onReady} spaceID={spaceID} changeTab={changeTab} 
                                          /></div>
                                          <div position='center' my='md'>
        
       
        <Row className='nicerFormatRange'>
          <Col lg={12}>
            <Slider
              //marks={marks}
            
              min={0} 
               max={120}
              value={currentCount}
              onChange={HandleRange}
               disabled={!space}
              tooltipVisible={false}
               tooltip={{
                formatter: value => value 
              }}
            />
            {space && defaultPoly.length > 0 && marks[currentCount]?<div className='sliderLeftTooltip'>
                <div className='tooltipCustom' style={{left:( ((currentCount * width)/ 120) *100)/(width)+"%"}}>
                  <span className='tooltipArea' style={{float:"right"}}>
                   {marks[currentCount]}
                  </span>
                </div>
              {/* left {width} {(currentCount * width)/ 120} {  } */}
            </div>:""}


            {/* <Button disabled={!space} className='autoplayStop btn-smaller' onClick={autoPlayhandler}>{autoPlay&&currentCount<=(icons.length-1)?<Play/>:<Pause/>}</Button>   */}
          </Col>
          <Col lg={2}>
          
          </Col>
          {/* <Col lg={5}>
            <Slider
              marks={marks2}
              defaultValue={currentCount} 
              value={currentCount}
              tooltipVisible={false} 
              onChange={HandleRange}
              disabled={!space}
              key={'range2'}
            />
          </Col> */}
        </Row>
          
      </div>                                
                                    </>:<h2>Please Setup Geo Map or contact to administrator</h2>}








                  </Col>
          <Col lg={3} className="p-l-0 tabletfull">
          <div className="legendArea manStyle legendArea2" style={{height:heightMap + 50,background:"#fff"}}>
            {filterTimeData.length>0?<div className='legendSmallDate'>Date <strong>{startBigger ===true  && start_time < 11 ?moment(selectedDate, "YYYY-MM-DD").add(1, 'days').format("MMM DD"):moment(selectedDate).format("MMM DD")}</strong> ( { formatAMPMHour(start_time)})</div>:""}
            <div className='legendStart'>
            {/* <div className='legendSwitch'>
                
                <Switch defaultChecked={enable} onChange={onChangeEnable} />
                <span className='title'>{enable?"Location Log":"All Log"}</span>
              </div> */}
              <div className={!space?enable?'legendList legendHeader disable three-colsHeader':'legendList legendHeader disable':enable?'legendList legendHeader  three-colsHeader':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Location</strong></div>
                  {enable?<div className='legendLeftAreaTime'><strong>Spend</strong></div>:""}
                  <div className='legendRightAreaProd'><strong>Time</strong></div>
              </div>
              {console.log("on display what wrongs",defaultPoly,selectedEmployee,defaultPoly.filter(item=>item.position <= currentCount && selectedEmployee.indexOf(item.employee_tag) >-1))}
                    <Scrollbars style={{height:(heightMap -50)}}>{defaultPoly.filter(item=>item.position <= currentCount && selectedEmployee.indexOf(item.employee_tag) >-1).map((item,index)=>{
                   
                      return(
                                <div key={"tracker"+item.time+index} className={!space?enable?'legendList  disable three-colsHeader':'legendList  disable':enable?'legendList three-colsHeader':'legendList'}>
                                        <div className='legendLeftAreaProd'>{item.name}<span className='empName'>{item.emp_name}</span></div>
                                        {enable?<div className='legendLeftAreaTime'>{moment.utc(item.time_spent).format("HH:mm:ss")}</div>:""}
                                        <div className='legendRightAreaProd'>{convertTimeZone(item.time)}</div>
                                </div>
                                )
                    })}</Scrollbars>

      {/* <div className={!space?'legendList legendHeader legendFooter disable':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Total</strong></div>
                  <div className='legendRightAreaProd'><strong>{0}</strong></div>
              </div> */}

                </div>

          </div>
        </Col>        

      

      </Row>
    </Container>
    {loaderLag?<div className='lagLoader'><div className='lagInner'><img src={spinnerLoader} /><h5>Processing</h5></div></div>:""}
  </div>:
<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>

  )
}

export default MovementAnalyzer