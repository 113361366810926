import React, { useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { CommonHeading } from "../../common/commonHeading";
import '../../manPowerManagement/style/manpowerManagement.scss';

import CommonDatePicker from "../../common/commonDatePicker";
import spinnerLoader from "../../assets/images/loader.svg";

import moment from "moment";
import {
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import '../../report/style/style.scss';

import {ReactComponent as Image404 } from  '../../assets/images/11132-ai.svg'
import DatePicker from "react-datepicker";
import { Select } from "antd";
import JobCard from "./JobCard";
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import AddJob from "./AddJob";
import { createJobCard, getJobCardList, getJobCardLog, updateJobCard, updateJobCardStart, updateJobCardStop } from "../actionMethods/actionMethods";

import { toast, ToastContainer } from "react-toastify";
import { getCategoryList, getOnBoardEmployee } from "../../realtimeTracker/actionMethods/actionMethods";
import EditJob from "./EditJob";
import ToDoList from "./ToDo";
import Remark from "./Remark";
import { EditOutlined } from '@ant-design/icons';
import Scrollbars from "react-custom-scrollbars";
import { A } from "@mobily/ts-belt";

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
  },
};


function ManpowerPlaning(props) {

  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);

  const [dates, setDate] = useState({

    start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  });

  const [selectedLangValue, updateSelectedLangValue] = useState("en");

  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);

  const [isLoading,SetisLoading] = useState(true);
  const [isLoading2,SetisLoading2] = useState(false)
  const [isLoading3,SetisLoading3] = useState(false)
  const [exportEnable,SetExportEnable] =useState(false);
  const [selectedEmployee,SetSelectedEmployee] = useState(null);
  const [selectedJob,SetSelectedJob]= useState(null)
  const [jobStatus,SetJobStatus]= useState()
  const [modalAddJob,setModalAddJob]=useState(false);


  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;

  const [dataLoad,SetDataLoad] = useState(false);

  const [filterJOB,SetFIlterJOB]= useState(false);
  const [filterPassData,SetFilterPassData]= useState({});
const [jobData,SetJobData] = useState([]);
const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const [isEndModalOpen, setIsEndModalOpen] = useState(false);
  const [jobTitle,SetJobTitle]= useState('')
  const [jobID,SetJobID]= useState('');
  const [worker_id,SetWorkerID]= useState('');
  const [inc,SetInc]= useState(null);
  const [location,SetLocation]= useState([]);
  const [employee,SetEmployee]= useState([]);
  const [allJob,SetAllJob]= useState([]);
  const [joblog,SetJobLog] =useState([]);
  const [logModal,SetLogModal]= useState(false);

  const openStartModal = (job_id,worker_id,job_name) => { SetJobID(job_id);SetWorkerID(worker_id);SetJobTitle(job_name);setIsStartModalOpen(true);}
  const openEndModal = (job_id,worker_id,job_name) => {SetJobID(job_id);SetWorkerID(worker_id);SetJobTitle(job_name);setIsEndModalOpen(true);}

  const [jobDetail,SetJobDetail] = useState({})
  const [modalJobEdit,SetModalJobEdit] = useState(false)
  const [modalToDo,SetModalToDo] = useState(false)
  const [modalRemark,SetModalRemark] = useState(false)
  
  const closeModals = () => {
    setIsStartModalOpen(false);
    setIsEndModalOpen(false);
    SetJobDetail({})
    SetJobTitle('')
    SetJobID('');
    SetWorkerID('');
    SetLogModal(false)
    SetModalJobEdit(false);

  };

  const isWeekday = (date) => {

        let offWeek = props.weekDays.filter(item=>!item.working).map(el=>el.day);
        let holiday=props.holiday?props.holiday:[]
        const day = moment(date).isoWeekday();
        //here sunday
        if(offWeek.indexOf(day) > -1 || holiday.find(item=>moment(item.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') ) ){
            return true     
        }else{
          //here normal days
        return false ;
        }        
  };

  function handleDateSelect(date) {
    updateSelectedDate(date);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));
    setDate({ start: endDate, end: endDate });
    SetExportEnable(false);
  }

  

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(() => {

      let perm= props.pages.filter(el=>el.page_name=="Periscope Management"); 
      if(perm){
          SetPermission(perm)
          SetPermissionAPICall(true)
      }else{
      }
  },[props.pages]);

  useEffect(()=>{
    let requestBody={}
    requestBody.session= userSession;
    requestBody.org_id= org_id;
    getCategoryList(requestBody).then(res=>{
      let loc= [];
      for(let i=0;i<res.data.length;i++){
        loc.push(...res.data[i].location)
      }
      SetLocation(loc);
    })

  },[])

 useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);


  const handleDate = (date, type) => {
    SetDataLoad(false);
    setDate((prev) => ({ ...prev, [`${type}`]: date }));
  };




function FIlterResultHandlerFOP(value,status){

  SetFilterPassData(value);

}
const generateRandomJobList = (count) => {
  const statuses = ["Active", "Completed", "In Progress", "Over Time","Lapse"];
  const tasks = ["Task A", "Task B", "Task C", "Task D", "Task E"];
  const remarks = ["Remark A", "Remark B", "Remark C", "Remark D", "Remark E"];
  

  const getRandomElement = (arr) => arr[Math.floor(Math.random() * arr.length)];
  const getRandomDate = (start, end) =>
    new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

  const jobs = Array.from({ length: count }, (_, index) => {
    const randomStart = getRandomDate(new Date(), new Date(Date.now() + 86400000)); // Random within 24 hours
    const randomEnd = new Date(randomStart.getTime() + Math.random() * 36000000); // Random within 10 hours after start

    return {
      added_at: new Date().toUTCString(),
      job_end: randomEnd.toUTCString(),
      job_id: index + 1,
      job_start: randomStart.toUTCString(),
      location_serial: [
        `02026A7FD2${Math.floor(1000 + Math.random() * 9000)}`,
        `02029${Math.floor(100000 + Math.random() * 900000)}`,
      ],
      org_id: Math.floor(Math.random() * 100) + 1,
      worker_id: `20635f02b${Math.floor(10000 + Math.random() * 90000)}`,
      todo_list: Array.from(
        { length: Math.floor(3 + Math.random() * 3) },
        () => getRandomElement(tasks)
      ),
      remark: Array.from(
        { length: Math.floor(2 + Math.random() * 3) },
        () => getRandomElement(remarks)
      ),
      status: getRandomElement(statuses),
    };
  });

  return jobs;
};

useEffect(() => {
  const fetchAndMergeData = async (startDate, endDate) => {
    const mergedResponse = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      const dateString = getDateFormat(currentDate); // Format date as YYYY-MM-DD
      try {
        const response = await getJobCardList(userSession, org_id, sub_org_id, dateString);
        if (response.status === 200) {
          const enrichedData = await Promise.all(
            response.data.map(async (el) => {
              // Fetch additional data using job_id
              try {
                const additionalInfo = await getJobCardLog(userSession,org_id,sub_org_id, el.job_id);
                console.log("additionalINfor",additionalInfo);
                return {
                  ...el,
                  job_status: el.actual_end_time ? "completed" : el.job_status,
                  log_count:additionalInfo.data?.out_of_range_count, // Add the fetched info to the object
                  log:additionalInfo.data?.out_of_range_log, // Add the fetched info to the object
                };
              } catch (error) {
                console.error(`Error fetching additional info for job_id ${el.job_id}:`, error);
                return { ...el, job_status: el.actual_end_time ? "completed" : el.job_status }; // Return object without additionalInfo in case of an error
              }
            })
          );

          mergedResponse.push(...enrichedData);
        }
      } catch (error) {
        console.error(`Error fetching data for ${dateString}:`, error);
      }

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return mergedResponse;
  };

  // Usage
  if (dates.start && dates.end) {
    SetisLoading(true);
    const startDate = getDateFormat(dates.start);
    const endDate = getDateFormat(dates.end);

    fetchAndMergeData(startDate, endDate).then((mergedData) => {
      console.log("Merged Data:", mergedData);
      SetJobData(mergedData);
      SetAllJob(mergedData);
      SetisLoading(false);
    });
  }
}, [dates, inc]);


useEffect(()=>{
if(dates.start && dates.end){
const startDate = getDateFormat(dates.start);
const endDate =  getDateFormat(dates.end);

  getOnBoardEmployee({start_date:startDate,end_date:endDate},userSession,org_id,sub_org_id).then(res=>{
  console.log("res.data",res.data,res.data.regular);
  let emp=[];
  if(res.data.regular){
    emp.push(...res.data.regular);
  }
   if(res.data.daily){
    emp.push(...res.data.daily);
  }
  console.log("emp",emp);
  SetEmployee(emp);
})
}
},[dates])


function filterResultHandlerNew(){
  SetisLoading(true);
  
  let jobsList=[...allJob]
  console.log("jobsList",jobsList)
  let arr =[]
  if(selectedJob ){  
    arr.push(...jobsList.filter(el=>el.job_id === selectedJob ))
  }
  if(selectedEmployee){
    arr.push(...jobsList.filter(el=>el.worker_id === selectedEmployee ))
  }
  if(jobStatus){
    arr.push(...jobsList.filter(el=>jobStatus.indexOf(el.job_status)>-1 ))
  }
  SetJobData(arr);

  setTimeout(function(){SetisLoading(false)},300)
}

function jobStartHandler(){
  let requestBody={
    "job_id" : jobID,
    "worker_id" : worker_id,
    "org_id" : userDetails.org_id,
    "session" : userSession
    }

    updateJobCardStart(requestBody).then(res=>{
      if(res.status==200){
        toast.success(jobTitle+ " Job started");
        setIsStartModalOpen(false);
        SetInc((prev)=>prev+1);
        setTimeout(closeModals(),2000);
      }else{
        toast.error("Something Went Wrong while starting the job");
      }    
    })  

}

function jobEndHandler(){
  let requestBody={
    "job_id" : jobID,
    "worker_id" : worker_id,
    "org_id" : userDetails.org_id,
    "session" : userSession
    }

    updateJobCardStop(requestBody).then(res=>{
      if(res.status==200){
        toast.success(jobTitle+ " Job Ended successfully!");
        
        setIsEndModalOpen(false);
        SetInc((prev)=>prev+1);
        setTimeout(closeModals(),2000);
      }else{
        toast.error("Something Went Wrong closing the job");
      }    
    })  

}

function createJobHandler(obj){
  
 let requestBody= {
    "worker_id" : obj.worker_id,
    "job_name":obj.jobName,
    "location_serials_list" : obj.locationSerialsList,
    "planned_start_time" : moment(obj.jobDuration[0]).format('YYYY-MM-DD HH:mm')+":00",
    "planned_end_time" : moment(obj.jobDuration[1]).format('YYYY-MM-DD HH:mm')+":00",
    "todo_list" : obj.toDoList,
    "remarks" : [],
    "org_id" : org_id,
    "session" : userSession
}

  createJobCard(requestBody).then(res=>{
    if(res.status == 200){
      toast.success("Job card created successfully")
      SetInc((prev)=>prev+1)
      setModalAddJob(false);
    }else{
      toast.error(res.message?res.message:"Failed to create job card")
    }
  })

}

function updateJobHandler(obj){  
 let requestBody= {
    "worker_id" : obj.worker_id,
    "job_id":jobDetail.job_id,
    "job_name":obj.jobName,
    "location_serials_list" : obj.locationSerialsList,
    "planned_start_time" : moment(obj.jobDuration[0]).format('YYYY-MM-DD HH:mm')+":00",
    "planned_end_time" : moment(obj.jobDuration[1]).format('YYYY-MM-DD HH:mm')+":00",
    "todo_list" : obj.toDoList,
    "remarks" : obj.remark,
    "org_id" : org_id,
    "session" : userSession
}

  updateJobCard(requestBody).then(res=>{
    if(res.status == 200){
      toast.success("Job Card updated successfully!")
      SetInc((prev)=>prev+1)
      SetModalToDo(false);
      SetModalRemark(false);
      SetModalJobEdit(false);
    }else{
      toast.error(res.message?res.message:"Failed to update job card");
    }
  })

}

function jobEditHandler(job,type){
  SetJobDetail(job);
  if(type=="edit"){
    SetModalJobEdit(true);
  }else if(type=="remark"){
    SetModalRemark(true)
  }else if(type=="todo"){
    SetModalToDo(true)
  }
}

function jobGeoMap(job){
  console.log(job);
  const start_time = new Date(job.actual_start_time.replaceAll(" GMT",""));
  const end_time = job.actual_end_time?new Date(job.actual_end_time.replaceAll(" GMT","")):new Date();
  
  let allHour=fetchAllHour(start_time,end_time)
  
  let url =  `/mapbox-employee-tracker/${job.worker_id}/${moment(job.actual_start_time.replaceAll(" GMT","")).format("YYYY-MM-DD")}/${moment(end_time).format("YYYY-MM-DD")}/${allHour.map(el=>el.hour).join(',')}`;
  console.log("url",url,allHour,job.actual_start_time,job.actual_end_time)
  window.open(url,'_blank');
 
}

function fetchAllHour(start,end){
  
  const startDate = start;
  const endDate = end; // Assuming end_time is on the next day
  const hourArray = [];
    while (startDate < endDate) {
      const hourString = moment(startDate).format('H');
      hourArray.push({hour:hourString,date:moment(startDate).format('YYYY-MM-DD')});

      startDate.setHours(startDate.getHours() + 1);
    }
    return hourArray;
    
  }

function JobLogetail(job){
  // let objLog=job.log;

  let locSerial= {}
  for(let i= 0;i<location.length;i++){
    locSerial[location[i].tag_serial]= location[i].location_name;
  }
  console.log("locSerial",locSerial,job.log)
  if(job && job.log){
    let objLog=job.log.map(item =>({location: locSerial[Object.keys(item)[0]] || Object.keys(item)[0],time: Object.values(item)[0]   }) );
    console.log("objLog",objLog)
    objLog.sort((a,b)=>new Date(a.time) - new Date(b.time) )
    SetJobLog(formatData(objLog));
    console.log("formatData(data)",formatData(objLog))
  }else{
    SetJobLog([]);
  }
  
  SetJobID(job.job_id);
  SetJobTitle(job.job_name);
  SetJobDetail(job);
  SetLogModal(true);

  // getJobCardLog(userSession,org_id,sub_org_id,job.job_id).then(res=>{
  //   console.log("res",res);
  // })
}

function formatData(data) {
  let result = [];
  let currentLocation = null;
  let startTime = null;

  for (let i = 0; i < data.length; i++) {
    const { location, time } = data[i];

    if (currentLocation === null) {
      // Initialize tracking for the first location
      currentLocation = location;
      startTime = time;
    } else if (currentLocation !== location) {
      // If the location changes, finalize the previous entry
      const endTime = data[i - 1].time; // End time is the last time of the previous location
      let totalTime = new Date(endTime) - new Date(startTime) + 30000; // Add 30 seconds for transition

      result.push({
        start_time: startTime,
        end_time: endTime,
        location: currentLocation,
        total_time: formatTime(totalTime),
      });

      // Start tracking the new location
      currentLocation = location;
      startTime = time;
    }
  }

  // Finalize the last location entry
  if (currentLocation !== null && startTime !== null) {
    const endTime = data[data.length - 1].time;
    let totalTime = new Date(endTime) - new Date(startTime) + 30000; // Add 30 seconds for transition

    result.push({
      start_time: startTime,
      end_time: endTime,
      location: currentLocation,
      total_time: formatTime(totalTime),
    });
  }

  return result;
}

// Helper function to format milliseconds into hh:mm:ss
function formatTime(milliseconds) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
  const seconds = String(totalSeconds % 60).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}


  return (<>
        {permissionAPICall?
            permission.length > 0?
    <div className="manpowerManagementMainDiv">
      <Container className="header-breadcrumb">
        <Row>
          <Col lg={6}>
            <CommonHeading title="Periscope Job Management" />
          </Col>
          <Col lg={6} className="text-right">
            <div className="commonLangaugeStyleDiv">
              
            </div>

            <div className="commonHeadingDateMainDivParent">
              <CommonDatePicker
                selectedDate={selectedDate}
                handleSelectDate={handleDateSelect}
                weekDays={props.weekDays}
                holiday={props.holiday}
                workday={props.workday}
              />
            </div>
          </Col>
        </Row>

        <Row className="m-t">
          <Col lg={12}>   
           <div className="manpowerManagementEmployeeListMainDiv">
            <div className="filterField" style={{width:"100%",overflow:"auto",marginBottom:"10px"}} >
              <div className="btndiv"><button className="addBtn btn btn-pink btn btn-submit btn-filter"style={{float:"right",fontSize:14}} onClick={()=>setModalAddJob(true)}>Create Job Card</button></div>
              </div>
              <div className="filterField">
                   <Row>

                  <Col lg={2} className="p-r-0 report-filter">
                    <label>
                      {("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={("Date")}
                        maxDate={moment(dates.end).toDate()}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}

                      />
                   
                    </div>
                  </Col>
                    <Col lg={2} className="p-r-0 report-filter">
                    <label>
                      {("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.end}
                        onChange={(date) => {handleDate(date, "end");SetExportEnable(false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={("Date")}
                        maxDate={moment(selectedDate).toDate()}
                        minDate={moment(dates.start).toDate()}
                        
                      />
                   
                    </div>
                  </Col>
                  <Col lg={2} className="p-r-0 report-filter report-filter2">
                      <label>
                        {("Employee")}
                      </label>
                      <Select
                      placeholder="Employee"
                      showSearch
                      style={{width:"100%"}}
                      value={selectedEmployee}
                      onChange={(val)=>SetSelectedEmployee(val)}
                      >
                      {employee.map(el=>{
                        return <Select.Option  value={el.worker_id}>{el.name}</Select.Option>
                      })}
                        
                      </Select>
                  </Col>
                  <Col lg={2} className="p-r-0 report-filter report-filter2">
                      <label>
                        {("Job Name")}
                      </label>
                      <Select
                        placeholder="Job Name"
                        showSearch
                        value={selectedJob}
                        style={{width:"100%"}}
                        onChange={(val)=>SetSelectedJob(val)}
                      >
                      {allJob.map(el=>{
                        return <Select.Option key={"jobs"+el.job_id} value={el.job_id}>{el.job_name}</Select.Option>
                      })}
                      </Select>
                  </Col>
                  <Col lg={2} className="p-r-0 report-filter report-filter2">
                      <label>
                        {("Job Status")}
                      </label>
                      <Select
                        placeholder="Job Status"
                        showSearch
                        value={jobStatus}
                        mode="multiple"
                        style={{width:"100%"}}
                        onChange={(val)=>SetJobStatus(val)}
                      >
                          <Select.Option key={'lapsed'} value={'lapsed'}>Lapsed</Select.Option>
                          <Select.Option key={'active'} value={'active'}>Active</Select.Option>
                          <Select.Option key={'in-progress'} value={'in-progress'}>In Progress</Select.Option>
                          <Select.Option key={'completed'} value={'completed'}>Completed</Select.Option>
                      </Select>
                  </Col>
<Col lg={2} className="">
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter"  disabled={dates.start==''} onClick={()=>filterResultHandlerNew()}>Submit</button>
                <button className="btn btn-submit btn-filter btn-export"  disabled={!filterJOB} >Export</button>
                </div>
</Col>


                </Row>
                </div> 
            </div>
          </Col>
        </Row>
        < Row className="m-t">
                   
                    <Col lg={12} className={props.hideHeading ? 'p-l-0 p-r-0' : ''}>
                        <div className="listingRecordMainDiv manpowerManagementEmployeeListMainDiv" style={{background:"transparent",padding:0}}>              
                            {isLoading?
                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> 
                                  : 
                                <React.Fragment>
                                      
                              <div className="rportArea">
                            
                            <div className="job-list">
        {jobData.map((job) => (
          <JobCard key={job.job_id} job={job} onStart={(job_id,worker_id,job_name)=>openStartModal(job_id,worker_id,job_name)} onEnd={(job_id,worker_id,job_name)=>openEndModal(job_id,worker_id,job_name)} location={location} employee={employee} jobEdit={(job,type)=>jobEditHandler(job,type)} jobGeoMap={(job)=>jobGeoMap(job)} JobLogetail={(job)=>JobLogetail(job)}/>
        ))}
      </div>


                               </div>

                                </React.Fragment> 
                            }

                        </div>
                    </Col>
                </Row>

      </Container>

      <AddJob className={"roundedCorner"} visible={modalAddJob} onCancel={()=>setModalAddJob(false)} onSubmit={(payload)=>createJobHandler(payload)} location={location} employee={employee} />
      <EditJob className={"roundedCorner"} visible={modalJobEdit} onCancel={()=>SetModalJobEdit(false)} onSubmit={(payload)=>updateJobHandler(payload)} location={location} employee={employee} jobDetail={jobDetail}/>

      <ToDoList className={"roundedCorner"} visible={modalToDo} onCancel={()=>SetModalToDo(false)} onSubmit={(payload)=>updateJobHandler(payload)} location={location} employee={employee} jobDetail={jobDetail}/>
      <Remark className={"roundedCorner"} visible={modalRemark} onCancel={()=>SetModalRemark(false)} onSubmit={(payload)=>updateJobHandler(payload)} location={location} employee={employee} jobDetail={jobDetail}/>
                            <ToastContainer/>
<Modal
        title={<><ExclamationCircleOutlined /> {jobTitle}</>}
        visible={isStartModalOpen}
        onCancel={closeModals}
        className="modalStartEndStyle"
        footer={[
          <Button key="yes" type="primary" onClick={() => jobStartHandler()}>
            Yes
          </Button>,
          <Button key="no" type="default" onClick={closeModals}>
            No
          </Button>
        ]}
      >
        <h5>Are you sure to want to  start <strong>{jobTitle}</strong> board?.</h5>
      </Modal>


        <Modal
        title={<>{jobTitle}</>}
        visible={logModal}
        onCancel={closeModals}
        className="modalStartEndStyle"
        footer={null}
        width={800}
      >
        {joblog.length>0?<div className="jobLogList jobLogListHeader">
          <div className="jobDivList">Location</div>
          <div className="jobDivList">Start Time</div>
          <div className="jobDivList">End Time</div>
          <div className="jobDivList">Total Time</div>
        </div>:""}
        <Scrollbars style={{height:(joblog.length *25) > 400 ? 400:(joblog.length*25) + 40}}>
        

      {joblog.length>0? joblog.map((el,index)=>{
       return  (
       <div className="jobLogList" key={"index"+index}>
            <div className="jobDivList">{el.location}</div>
            <div className="jobDivList">{moment(el.start_time).format('YYYY-MM-DD HH:mm:ss')}</div>
            <div className="jobDivList">{moment(el.end_time).format('YYYY-MM-DD HH:mm:ss')}</div>
            <div className="jobDivList">{el.total_time}</div>        
        </div>)
      }):<div className="jobLogList jobLogListNoFound" key={"index"}><span style={{float:"left",width:"100%"}}>No Log Found</span></div>}
      </Scrollbars>
      </Modal>
      

      {/* End Modal */}
      <Modal
        title={<><CheckCircleOutlined /> {jobTitle}</>}
        visible={isEndModalOpen}
        onCancel={closeModals}
        className="modalStartEndStyle"
        footer={[
          <Button key="yes-end" type="primary" onClick={() => jobEndHandler()}>
            Yes End
          </Button>,
          <Button key="no-end" type="default" onClick={closeModals}>
            No
          </Button>
        ]}
      >
        <h5>Are you sure want to end <strong>{jobTitle}</strong> board?</h5>
        
      </Modal>
      
    </div>:<Col><div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
            </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                    <img src={spinnerLoader} />
                                </Col>}</>
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(ManpowerPlaning)
);
