import React, { useState } from 'react';
import '../style/style.css';
import { Row } from 'react-bootstrap';
import { Col } from 'reactstrap';
import { Button, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {ReactComponent as GeoIcon} from '../../assets/images/map-location-regular.svg'
import {ReactComponent as HelpIcon} from '../../assets/images/info-circle.svg';


const statusColors = {
  active: "green",
  completed: "red",
  "in-progress": "orange",
  "over-time": "blue",
  "lapsed": "grey",
  "elapsed": "grey",
};

const JobCard = ({ job,onStart,onEnd,jobEdit,employee,jobGeoMap,JobLogetail }) => {
  console.log("Jobs",job);
  const [toDoList, setToDoList] = useState(job.todo_list);
  const [remarks, setRemarks] = useState(job.remarks);
  const [newToDo, setNewToDo] = useState("");
  const [newRemark, setNewRemark] = useState("");
  const [isAddingToDo, setIsAddingToDo] = useState(false);
  const [isAddingRemark, setIsAddingRemark] = useState(false);

  const handleAddToDo = () => {
    if (newToDo.trim()) {
      setToDoList([...toDoList, newToDo.trim()]);
      setNewToDo(""); // Reset input
      setIsAddingToDo(false); // Close input field
    }
  };

  const handleAddRemark = () => {
    if (newRemark.trim()) {
      setRemarks([...remarks, newRemark.trim()]);
      setNewRemark(""); // Reset input
      setIsAddingRemark(false); // Close input field
    }
  };

  return (
    <div className='jobListStyle'>
        <Row >
          <Col lg={6}>
          <div className={job.actual_end_time?"job-card completed": "job-card "+job.job_status.toLowerCase().replaceAll("-","")}  >
      <div className="job-header">
        <Row>
            <Col lg={6}><h5 style={{margin:0}}>{job.job_name}</h5></Col>

            <Col lg={6} style={{textAlign:"right"}}>
            <Button type="text"className='geoMapButton' icon={<GeoIcon  />} onClick={() => jobGeoMap(job)} disabled={job.actual_start_time===null } style={{marginRight:0}}></Button>
            <Button type="text"className={job.log_count?job.log_count===0?'infoButton':'infoButton infoButtonOrange':'infoButton'} icon={<HelpIcon />} onClick={() => JobLogetail(job)} disabled={job.job_status==="active"} style={{opacity:job.job_status==="active"?0.5:1}} ><span className='circularLog'>{job.log_count?job.log_count:0}</span></Button>
           {job.job_status==="active"? <Button type="text"className='editButton' icon={<EditOutlined />} onClick={() => jobEdit(job,'edit')} disabled={job.job_status.includes("lapse")}></Button>:""}
            
            <span className="statusJobs" style={{ marginLeft:20, backgroundColor:job.actual_end_time?statusColors['completed']: statusColors[job.job_status],borderRadius:10 }} title={job.job_status.toUpperCase()}>{job.actual_end_time?"Completed":job.job_status=="elapsed"?"Lapsed":job.job_status.toUpperCase()}</span></Col>
        </Row>
        
        
      </div>
      <div className="job-content" style={{margin:"30px 0px 0px"}}>
        
            <Col lg={4}><div className='labelHead'>{employee.find(el=>el.worker_id === job.worker_id )?.name}</div><><strong>ID:</strong> {job.worker_id}</></Col>
            <Col lg={4}><div className='labelHead'><strong>Start Time</strong></div>
            <p><>{job.planned_start_time?new Date(job.planned_start_time.replaceAll(" GMT","")).toLocaleString():"-"}<br/></>            
            <><span style={{color:"#00ab9e"}}>{job.actual_start_time?new Date(job.actual_start_time.replaceAll(" GMT","")).toLocaleString():""}</span></>
            </p></Col>
            <Col lg={4}><div className='labelHead'><strong>End Time</strong></div>
            <p><>{job.planned_end_time?new Date(job.planned_end_time.replaceAll(" GMT","")).toLocaleString():"-"}<br/></>
            <><span style={{color:"#00ab9e"}}>{job.actual_end_time?new Date(job.actual_end_time.replaceAll(" GMT","")).toLocaleString():""}</span></>
            </p>
            </Col>
      </div>
</div>
      
      </Col> 
      <Col className='SecondColJob' style={{paddingLeft:0,paddingRight:0}} >
                  {/* To-Do List Section */}
        <div className='job-card'>
          <h5>To-Do List<span style={{float:"right"}}>
            
            </span></h5>
          <ul>
            {toDoList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

      </Col>  
      <Col className='ThirdColJob' style={{paddingRight:0}}>
              {/* Remarks Section */}
        <div className='job-card'>
          <h5>Remarks <span style={{float:"right"}}>
           {job.job_status.includes("lapse") || job.actual_end_time?"": <Button type="text" className='editButton' icon={<EditOutlined />} onClick={() => jobEdit(job,'remark')} disabled={job.job_status=="active" || job.job_status=="lapse" || job.job_status=="completed"} style={{
                opacity:job.job_status=="active" || job.job_status=="lapse" || job.job_status=="completed" ?0.5:1
            }}></Button>}</span></h5>
          <ul>
            {remarks.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

      </Col>  
      <Col lg={1} className='ActionJob'>
            <div className="job-actions">
                {job.job_status === "active" && <button className="start-btn" onClick={()=>onStart(job.job_id,job.worker_id,job.job_name)}>Start</button>}
                {job.job_status.includes("lapse")?"":((job.job_status !== "active" ) && job.actual_end_time===null) && <button className="end-btn" onClick={()=>onEnd(job.job_id,job.worker_id,job.job_name)}>End</button>}
            </div>         
        </Col>  
        </Row>
     </div>
  );
};

export default JobCard;
