import React, { useState, useCallback,useReducer,useEffect,useRef,useLayoutEffect } from 'react'
import Viewer from '../../../employeeTracker/hooks/Viewer';
import {
    append,
    compose,
    isEmpty,
    map,
    reject,
    intersperse,
    evolve,
    max,
    mapObjIndexed,values, filter
  } from 'ramda'
import LocationIcon from   '../../../assets/images/location.png'
import moment from 'moment';

import ProductiveIcon from  "../../../assets/images/locationProductive.png"
import nonProductiveIcon from "../../../assets/images/locationNonProductive.png";
import lessProductiveIcon from "../../../assets/images/locationLessProductive.png";

import {getLocationCordinate} from  '../../../realtimeTracker/actionMethods/actionMethods';
import {getEmployeeTracker} from '../../../employeeTracker/actionMethods/actionMethod';
import spinnerLoader from "../../../assets/images/loader.svg";
import { getIndividualProductivityList } from '../../actionMethods/actionMethods';

import CMGBulky from '../../../assets/cmg_bulky.json'
import CMGManual from '../../../assets/cmg_manual.json'
import { ToastContainer, toast } from 'react-toastify';

let layer=[]
let dataUniqueLocation=[];
let productiveLocation =[]
let nonproductiveLocation =[]
let lessproductiveLocation =[]

function EmployeeTracker(props) {
 
  
    const INITIAL_MODE = '3d'  
    const [mode, setMode] = useState(INITIAL_MODE)
    const [spaceID,SetSpaceID]=useState('');
    const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
        mode
      ])
      
    let date = props.date?new Date(props.date):localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [selectedDate, updateSelectedDate] = useState(date);
    const autoElevation = map(
        evolve({ position: { elevation: noElevationIn2D } })
        )   
    const [space, setSpace] = useState()
    
    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    // let GeoWidth= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?2:4;
    // let GeoElevation= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?3:5;
      let GeoWidth= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?2:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34)?2.5:(userDetails && parseInt(userDetails.org_id) == 35) || (userDetails && parseInt(userDetails.org_id) == 38) || (userDetails && parseInt(userDetails.org_id) == 37)?1:4;
  let GeoElevation= userDetails && (parseInt(userDetails.org_id) == 31|| parseInt(userDetails.org_id) == 32)?3:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34) || (userDetails && parseInt(userDetails.org_id) == 35)|| (userDetails && parseInt(userDetails.org_id) == 38)||(userDetails && parseInt(userDetails.org_id) == 37)?3:5;

    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 0;         
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
    
    const [startBigger,SetStartBigger]=useState(false);  
    const onReady = useCallback(space => setSpace(space), [])
    const [dualLocationList,SetDualLocationList]=useState([])
    const [locationCordinate,SetLocationCordinate]= useState([]);
    const [isloading,Setloading]=useState(true);    
    const [oldPosition,SetOldPosition]=useState(0);
    const [currentCount, setCount] = useState(0);
    const [stop, setStop] = useState(false);
    const [defaultPoly,SetDefaultPoly] = useState([])
    const employeeID =props.employeeID
    const [error,SetError]=useState('');
    const [start_time,SetStartTime]=useState([]);
    const [loaderEmp,SetLoaderEmp]=useState(false)

    
    const [sucessMessage,SetSucessMessage] =useState(false);
    const [loader,SetLoader] =useState(true)
    const categories= [
        '12 AM','01 AM','02 AM','03 AM','04 AM','05 AM','06 AM',
        '07 AM','08 AM','09 AM','10 AM','11 AM','12 PM','01 PM',
        '02 PM','03 PM','04 PM','05 PM','06 PM',
        '07 PM','08 PM','09 PM','10 PM','11 PM',
    ] 
    useEffect(()=>{

        if(props.permission.length > 0){
          let geoLocation=[]
          getLocationCordinate(userSession,org_id,sub_org_id).then(res=>{
            if(res.status==200){
                    if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
                        SetSpaceID("8caa8c30-c866-436e-a03c-962c1879841e")
                    }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
                        SetSpaceID("334db83f-5994-48e5-b08f-b05587c3d143")
                    }else{
                        SetSpaceID(res.data.space_id)  
                    }

              // let data = res.data.location
              //       geoLocation = res.data.location
if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
                    SetSpaceID("8caa8c30-c866-436e-a03c-962c1879841e")
                    for(let i=0;i<CMGBulky.length;i++){
                      let dataFind= res.data.location.find(el=>el.name.toLowerCase().trim() ==CMGBulky[i]['Location  Name'].toLowerCase().trim() )
                      
                      if(dataFind){
                        geoLocation.push(dataFind);
                      }

                    }
                  }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
                      SetSpaceID("334db83f-5994-48e5-b08f-b05587c3d143")
                      for(let i=0;i<CMGManual.length;i++){

                      let dataFind= res.data.location.find(el=> el.name.toLowerCase().trim() ==CMGManual[i]['Location  Name'].toLowerCase().trim() )
                      if(dataFind){
                        geoLocation.push(dataFind);
                      }
                    }

                  }else{
                    SetSpaceID(res.data.space_id)  
                    geoLocation = res.data.location
                  }


                  SetLocationCordinate(geoLocation);
      
              // SetLocationCordinate(res.data.location);
              Setloading(false);
      
            }
      
          })
        }
      },[props.permission])
      useEffect(()=>{
        if (!space || props.tag_serial=="") {
            return
          }

          let data = [...locationCordinate] 
        productiveLocation =props.productivity
        nonproductiveLocation =props.nonproductivity
        lessproductiveLocation =props.lessproductivity
        if(data.length>0){
    
          for (let i = 0;i<data.length;i++){
           
            
            let arr={};
            arr.type='add'
            arr.icon={}
            arr.id=data[i].unique_loc_id;
            arr.tooltip=data[i].name 
            arr.position= data[i].position
            arr.position.elevation= parseInt(userDetails.org_id)== 37 || parseInt(userDetails.org_id)== 38?1:5
            arr.name = data[i].name
            arr.width = 96
    
            arr.url = LocationIcon
            if(productiveLocation.find(item=>item.tag_serial== data[i].tag_serial )){
             
              arr.url = ProductiveIcon
            }
            else if(nonproductiveLocation.find(item=>item.tag_serial== data[i].tag_serial )){
             
              arr.url = nonProductiveIcon
            }
            else if(lessproductiveLocation.find(item=>item.tag_serial== data[i].tag_serial )){
             
              arr.url = lessProductiveIcon
            }
            arr.widthSize=3
            //dispatchIcon(arr);
            AddDataLayer(arr)
    
          }
        }
          
          if(props.start_time.length > 0){
            // if(props.start_time > -1){
                  SetStartTime(props.start_time);
                  let st = props.start_time.map(el=>props.activeHourDropdown.find(item=>item.hour == el) );
                  filterResultHandler(props.employeeID,st,startBigger,date)
            // }

            
            
            
          }
        

      },[props.start_time,props.tag_serial,props.activeHourDropdown,space,locationCordinate])
      

      // useEffect(()=>{
      //   if (!space) {
      //     return
      //   }
            
      // },[locationCordinate,spaceID,space])

      
    function AddDataLayer(data){

        if(space){
        space.addDataLayer({
          id: data.id,
          type: 'icon',
        
          data: [{
            position: data.position, 
          }],
          icon: {
            url: data.url,
            width: 96,
            height: 96
          },
          width: GeoWidth,
          anchor: 'top',
          tooltip: d => data.tooltip,
        //   onClick: d=> filterResultLocation(data.tooltip),
        })
      }
    }

    function filterResultHandler(tag=employeeID,stime=start_time,bigTimer=props.startBigger){
      
     
        SetLoader(true);
        dataUniqueLocation =[];
        
        let previousLocation=[]
        let dualLocation=[]
        let inc=0;
        let incCount=0;
        SetLoaderEmp(true);
        props.SetLoaderEmpHandler(true,false);
        let arr = [];
        let locationPoint = [...locationCordinate];
      
        //let data = [...dummydata]
        let mk = []
        let mk2 = []
        SetError('');

        SetSucessMessage(false);
        for(let i=0;i<layer.length;i++){
            let poly=[];
            space.removeDataLayer(layer[i].id);
            space.removeDataLayer(layer[i].polyID);
            setCount(0)
          }
      for(let i=0;i<stime.length;i++){
      
      
          let requestBody={}
          requestBody.worker_id= employeeID
          console.log("stime[i]",stime[i],stime[i].date,getDateFormat(selectedDate))
          requestBody.date= stime[i] && stime[i].date?stime[i].date:getDateFormat(selectedDate)

          let stTimeBody = stime[i].hour !=null?parseInt(stime[i].hour):parseInt(start_time[i]);
             
       let endTime = stTimeBody+ 1;
          requestBody.start_time = stTimeBody+":00:00"
          requestBody.end_time =(stTimeBody==23?"00":stTimeBody + 1)+":00:00";
          requestBody.work_day = moment(selectedDate).format("YYYY-MM-DD")
          const diff =  moment(endTime) - moment(stTimeBody) ;
          let aMs = moment(stTimeBody, 'HH:mm:ss').format('x')
          let bMs = moment(endTime, 'HH:mm:ss').format('x')
        
            
          let difference =   bMs - aMs
      
          getEmployeeTracker(requestBody,userSession,org_id,sub_org_id).then(res=>{
            inc++;
            if(res.status==200){
        
                let data = res.data[props.tag_serial]?res.data[props.tag_serial]:res.data;
      //  let data = res.data['01D89D47E1BEB7']?res.data['01D89D47E1BEB7']:res.data;
              let locName='';
              if(data.length> 0){
                data = data.sort((a,b) => {
                  let aMs = moment(Object.values(a)[0])
                  let bMs = moment(Object.values(b)[0])
          
                  return    aMs - bMs
                })
              }
       
      
              Object.keys(data).forEach((key) => {
        
                let findPOs = locationPoint.find(item=>item.tag_serial == Object.keys(data[key])[0]) 
               
      
                
                let polylineFormat = {}
                if(findPOs){
                  mk.push(moment(Object.values(data[key])[0]));
                  let indexLoc = dataUniqueLocation.indexOf(findPOs.name);
                  if(indexLoc === -1){
                    dataUniqueLocation.push(findPOs.name);  
                  }
                
                  polylineFormat.levelIndex=findPOs.position.levelIndex?findPOs.position.levelIndex:0
                  polylineFormat.x=findPOs.position.x +  ((0.1*(Math.random() - .1) * 10))
                  polylineFormat.z=findPOs.position.z +  ((0.1*(Math.random() - .1) * 10))
                  polylineFormat.elevation =5
                  polylineFormat.name=findPOs.name
                  polylineFormat.tag_serial=findPOs.tag_serial
                  polylineFormat.time=Object.values(data[key])[0]
                  polylineFormat.position=incCount;
                  arr.push(polylineFormat);
                  
                
      
                if(locName==Object.keys(data[key])[0]){
      
                }else{
      
                  previousLocation.push(polylineFormat);

        
                  if(previousLocation.length> 1){
                    let oldloc = previousLocation[previousLocation.length - 2];
                    let newloc = previousLocation[previousLocation.length - 1];
                    let merge = {}
                    merge.old={}
                    merge.new={}
                    merge.old.levelIndex=oldloc.levelIndex?oldloc.levelIndex:0;
                    merge.old.x=oldloc.x?oldloc.x:"";
                    merge.old.z=oldloc.z?oldloc.z:"";
                    merge.old.elevation =oldloc.elevation?oldloc.elevation:"";
                    merge.old.name=oldloc.name?oldloc.name:"";
                    merge.old.tag_serial=oldloc.tag_serial?oldloc.tag_serial:""
                    merge.old.time=oldloc.time?oldloc.time:""
                    merge.new.levelIndex=newloc.levelIndex?newloc.levelIndex:0;
                    merge.new.x=newloc.x?newloc.x:"";
                    merge.new.z=newloc.z?newloc.z:"";
                    merge.new.elevation =newloc.elevation?newloc.elevation:""
                    merge.new.name=newloc.name?newloc.name:""
                    merge.new.tag_serial=newloc.tag_serial?newloc.tag_serial:""
                    merge.new.time=newloc.time?newloc.time:""
                    merge.position=incCount;
                  
                    dualLocation.push(merge);
      
                
                  }
      
      
                  
                  locName=Object.keys(data[key])[0];
                  mk2.push(moment(Object.values(data[key])[0]));
                } 
                incCount++;       
              
            }
          });
      

       if(inc == stime.length){
         SetDefaultPoly(arr);
         
         arr = arr.sort(function(a,b){
          return new Date(a.time) - new Date(b.time);
        });
        mk = mk.sort(function(a,b){
          return  new Date(a) - new Date(b);
        });
         mk = mk.map((item)=> {return  moment(item).utc().format('hh:mm:ss A') })

       
         SetDualLocationList(dualLocation);
         SetSucessMessage(true);
         SetOldPosition(0)     
        //  setCount(0);  
         setCount(arr.length);  
         SetLoader(true);
        //  SetMark(mk);
          SetLoaderEmp(false);
          props.SetLoaderEmpHandler(false,arr.length>0?true:false);
        //  SetSelectedStartTime(stime);
       }
      
      
            }else{
              SetLoaderEmp(false)
              toast.error('Something went wrong');
                        props.SetLoaderEmpHandler(false,false);
            }
      
      
        })  
        
      } 
      
         
          
        }


    function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
      useEffect(() => {
        if (!space) {
          return
        }
        let ctPosition  = [...dualLocationList];
        let grayLayer=[{
          "id": "9e2f2088-01f9-511b-9740-207f8a2887-gray",
          "name": "W3",
          "coordinates": []
      }]
        let yellowLayer=[{
          "id": "9e2f2088-01f9-511b-9740-207f8a2887-yellow",
          "name": "W3",
          "coordinates": []
      }]
      let redLayer=[{
        "id": "9e2f2088-01f9-511b-9740-207f8a2887-yellow",
        "name": "W3",
        "coordinates": []
      }]
        
      for(let i=0;i<layer.length;i++ ){
        space.removeDataLayer(layer[i].id);
        space.removeDataLayer(layer[i].polyID);
      }
      layer=[];
      let oldCount = oldPosition
        let locationList = dualLocationList.filter(item => item.position > oldPosition);
        
      for(let i=0;i<locationList.length;i++ ){
      
        if(locationList[i].position <=currentCount){
      
          let polyCord= [];
            polyCord.push(locationList[i].old);
            polyCord.push(locationList[i].new);
            
            let count= defaultPoly.length;
            let poly=[{
              "id": "9e2f2088-01f9-511b-9740-207f8a2887"+i,
              "name": "W3",
              "coordinates": polyCord
          }]
        let countPosition = locationList[i].position;    
        let oldserial = locationList[i].old.tag_serial;    
        let countFilter = ctPosition.filter(item=> item.position <= countPosition);  
      
        if(countFilter.length> 0){
      
         
          countFilter  = countFilter.filter(item=>
              (item.new.tag_serial ==locationList[i].new.tag_serial && oldserial == item.old.tag_serial) ||   
              (item.new.tag_serial ==oldserial && item.old.tag_serial == locationList[i].new.tag_serial)
              ) ;
            }
      
      
          if(countFilter.length<= (( (120)*5)/100) ){
      
            polyCord[0].x=polyCord[0].x
            polyCord[0].y=polyCord[0].y
            polyCord[0].elevation=userDetails.org_id==38||userDetails.org_id==37?1.5:4.5;
            polyCord[0].levelIndex=polyCord[0].levelIndex;
          
            polyCord[1].x=polyCord[1].x
            polyCord[1].y=polyCord[1].y
            polyCord[1].elevation=userDetails.org_id==38||userDetails.org_id==37?1.5:4.5;
            polyCord[1].levelIndex=polyCord[1].levelIndex;
            grayLayer[0].coordinates.push(...polyCord)
          }else if(countFilter.length > ((120*5)/100) && countFilter.length <=((120*10)/100)){
            polyCord[0].x=polyCord[0].x
            polyCord[0].y=polyCord[0].y
            polyCord[0].elevation=userDetails.org_id==38||userDetails.org_id==37?2.2:6;
            polyCord[0].levelIndex=polyCord[0].levelIndex;

            polyCord[1].x=polyCord[1].x
            polyCord[1].y=polyCord[1].y
            polyCord[1].elevation=userDetails.org_id==38||userDetails.org_id==37?2.2:6
            polyCord[1].levelIndex=polyCord[1].levelIndex;
            yellowLayer[0].coordinates.push(...polyCord)
          }else{
            polyCord[0].x=polyCord[0].x
            polyCord[0].y=polyCord[0].y
            polyCord[0].elevation=userDetails.org_id==38||userDetails.org_id==37?2.8:8;
            polyCord[0].levelIndex=polyCord[0].levelIndex;

            polyCord[1].x=polyCord[1].x
            polyCord[1].y=polyCord[1].y
            polyCord[1].elevation=userDetails.org_id==38||userDetails.org_id==37?2.8:8;
            polyCord[1].levelIndex=polyCord[1].levelIndex;
            redLayer[0].coordinates.push(...polyCord)
          } 
          }
        }

        if(grayLayer[0].coordinates.length> 0){
          
          
          layer.push({
            "id": grayLayer[0].id,
            "polyID":'polylines-grey',
        
        });  
          space.addDataLayer({
              id: 'polylines-grey',
              type: 'polyline',
              // type: 'dotted-polyline',
              data: compose(
                map(
                  evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
                ),
                reject(p => isEmpty(p.coordinates))
              )(grayLayer),
              shape: 'triangle',
              cap:true,
              stepSize:15,
              scale: 0.5,
              color:'#413d3d',
              tooltip: d => d.name,
            })
        }
        if(yellowLayer[0].coordinates.length> 0){      
          layer.push({
            "id": yellowLayer[0].id,
            "polyID":'polylines-yellow',
        });  
          space.addDataLayer({
              id: 'polylines-yellow',
              type: 'polyline',
              // type: 'dotted-polyline',
              data: compose(
                map(
                  evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
                ),
                reject(p => isEmpty(p.coordinates))
              )(yellowLayer),
              shape: 'triangle',
              cap:true,
              stepSize:15,
              scale: 0.5,
              color:'#cf8c12',
              tooltip: d => d.name,
            })
        }
        if(redLayer[0].coordinates.length> 0){      
          layer.push({
            "id": redLayer[0].id,
            "polyID":'polylines-red',
        });  
        space.addDataLayer({
            id: 'polylines-red',
            type:'polyline' ,
            // type: 'dotted-polyline',
            data: compose(
              map(
                evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
              ),
              reject(p => isEmpty(p.coordinates))
            )(redLayer),
            shape: 'triangle',
            cap:true,
            stepSize:15,
            scale: 0.5,
            color:'#9c0808',
            tooltip: d => d.name,
        })
        }
              
      }, [space,dualLocationList,currentCount, noElevationIn2D])
      
    
      useEffect(() => {

        if (!space) {

          return
        }   

        //   let line = [...polylines];
          //let cord = defaultPoly.slice(0,currentCount+1);
          let cord = defaultPoly.slice(0,(currentCount));
          
          let filterData = defaultPoly.slice(0,(currentCount));
          
    


            for (let i = 0;i<dataUniqueLocation.length;i++){
              console.log("")
              let filterLength= locationCordinate.filter(item => item.name === dataUniqueLocation[i]) 
              let sizeUpdate= cord.filter(item=>item.name === dataUniqueLocation[i]);
              let initialSize= 4;
              if(userDetails.org_id==38 || userDetails.org_id==37){
                initialSize =1.5
              }

              let currentLocation = locationCordinate.find(item=>item.name == dataUniqueLocation[i]);
              if(currentLocation){
                updateDataLayer(currentLocation,'','',(initialSize+sizeUpdate.length/40),sizeUpdate.length==0?1:sizeUpdate.length)
              }
            }

            
          
          
      },[currentCount,space]);

    function changeTab(){

    }
    function updateDataLayer(data,url,width,widthSize,inc){
        inc=inc?inc:0;
       
    
        if(space){
        if(url){
          space.updateDataLayer({
            id: data.unique_loc_id,
            data: [{
              position: data.position, 
            }],
            icon: {
              url: url,
              width: width?width:96,
              height: width?width:96
            },
            width: data.widthSize?data.widthSize:4,
            anchor: 'top',
            tooltip: d => data.name +" : "+moment.utc(inc*1000*30).format("HH:mm:ss"),
            // onClick: d=> filterResultLocation(data.name),
          })
        
        }else{
        
          space.updateDataLayer({
            id: data.unique_loc_id,
            data: [{
              position: data.position, 
            }],
            width: widthSize?widthSize:4,
            anchor: 'top',
            tooltip: d => data.name +" : "+moment.utc(inc*1000*30).format("HH:mm:ss"),
            // onClick: d=> filterResultLocation(data.name),
          })
        }
      }
    
        
      }


    return( 
        
        <>
        {isloading? <div className="text-center m-t-lg">
                    <img src={spinnerLoader} className="m-t-lg" />
</div>:spaceID!=''?<>
{/* <button onClick={()=>setStop(!stop)}>{stop?"Play":"pause"}</button><button onClick={()=>setCount(0)}>Restart</button> */}
         <Viewer onReady={onReady} spaceID={spaceID} changeTab={changeTab} 
          /></>:""}
            <ToastContainer/>
          </>)
}
export default EmployeeTracker;