import React, { useState, useEffect,useRef } from 'react'
import { useHistory } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import '../../../siteManagement/styles/siteManagement.scss'
import DashboardLanguage from '../../../components/dashboardLanguage';
import { getSiteOverview, getSiteFootFall, getSiteAreaIndex, footfallChart, getProductivityTemperature, getProductivityMontion } from '../../actionMethods/actionMethods';

import spinnerLoader from '../../../assets/images/loader.svg'
import CommonDatePicker from '../../../common/commonDatePicker';
import { getTranslatedText } from '../../../common/utilities';
import ClockIcon from '../../../dashboard/styles/images/clock.png'

import { getLanguageTranslation, setSelectedLanguage } from '../../../dashboard/actionMethods/actionMethods';
import { tsAnyKeyword } from '@babel/types';
import { Scrollbars } from "react-custom-scrollbars";
import ReactHighcharts from 'react-highcharts'
import Highcharts from "highcharts";
import DatePicker from "react-datepicker";
import helpIcon from "../../../assets/traceplusImages/help-icon.png";
import sortIcon from '../../../assets/traceplusImages/sort.png'
import upIcon from '../../../assets/traceplusImages/up-arrow.png'
import downIcon from '../../../assets/traceplusImages/down-arrow.png'
import calenderIcon from '../../../assets/traceplusImages/calendar_icon_white.svg'
import DeviceStatus from '../../../components/DeviceStatus'
import { getPproductivityWidget,
         getPproductivityDetailOverview,
         getDetailedProductivityChart,
         getProductivityIndividualInfo,
         getIndividualProductivityList,
         getPeformanceIndividualHistory
             } from '../../actionMethods/actionMethods'

import batteryIcon from "../../../assets/traceplusImages/battery.svg";    
import {AgGridColumn, AgGridReact} from 'ag-grid-react';     
import {ReactComponent as Image404 } from  '../../../assets/images/11132-ai.svg'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Modal, Switch } from 'antd';
import { toast, ToastContainer } from 'react-toastify';
import {ReactComponent as Route } from '../../../assets/images/route.svg'
import GoldBadge from  '../../../assets/images/badgeRed.png'
// import BadgeRed from  '../../../assets/images/badgeRed.png'
import SilverBadge from  '../../../assets/images/SilverBadge.png'
import BarChartPerformance from './BarChartPerformance';

import notAvaialble from '../../../assets/images/notAvaialble.jpg'
import EmployeeTracker from './employeeTracker';
import { Select } from 'antd';
import {ReactComponent as HelpIcon} from  '../../../assets/images/circle-info-solid.svg'
import { getOnBoardEmployee } from '../../../realtimeTracker/actionMethods/actionMethods';
import axios from 'axios';
import MapboxTrackerDetail from '../../../MapBox/componenets/MapboxTrackerDetail';
import { zoom } from 'd3';
// import dummy from '../../../assets/get_productivity_detailed_overview.json'

const riskLevelColor = {
    "low": '#04e06e',
    "medium": "#ffd700",
    "high": "#ff9696"
}



function ProductivityDetails(props) {

    const { Option } = Select;
    let history =useHistory();
    // let idVal = props.match.params.id.replace(":", "");
    let dt= props.match.params.date?props.match.params.date.replace(":",""):localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()
    let date = dt?moment(dt).toDate():localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date();
    
    let StartDate= localStorage.getItem('ProductiveStart')?new Date(localStorage.getItem('ProductiveStart')):localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()  
    let EndDate= localStorage.getItem('ProductiveEnd')?new Date(localStorage.getItem('ProductiveEnd')):localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date()  
    let userDetails = JSON.parse(localStorage.getItem('userLoginDetails'))
    let userSession = userDetails ? userDetails.session : '123456789'
    let org_id = userDetails ? userDetails.org_id : 6
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";    
    let prefixURL = process.env.REACT_APP_URL
    const [selectedDate1, setSelectedDate] = useState(moment(date).format('YYYY-MM-DD'));
    const [selectedDateDisplay, setSelectedDateDisplay] = useState(moment(date).format('YYYY-MM-DD'));
    const [helpEnable,SetHelpEnable] = useState(false);
        const [idVal,SetIDVal] =useState(props.match.params.id.replace(":", ""));
    // const [dates, setDates] = useState({
    //     start: new Date(moment(StartDate).format('YYYY-MM-DD 23:59:59')),
    //     end: new Date(moment(EndDate).format('YYYY-MM-DD 23:59:59')),
    //   });

  
    const [employeeData, updateemployeeData] = useState([])
    const [selectedLangValue, updateSelectedLangValue] = useState('en')
    const [locationID, updateLocationID] = useState('')

    const [selectedDate, updateSelectedDate] = useState(date)
    const [chartLoader, setChartLoader] = useState(true)
    const [donutLoader, setDonutLoader] = useState(true)

    const [sortKey, setSortKey] = useState('locationVisited')
    const [sortType, setSortType] = useState('desc')
    
    const [sortKeylocationVisited, setSortKeylocationVisited] = useState('locationVisited')
    const [sortTypelocationVisited, setSortTypelocationVisited] = useState('desc')
    
    const [sortKeyunproductive, setSortKeyunproductive] = useState('unproductive')
    const [sortTypeunproductive, setSortTypeunproductive] = useState('desc')
  
    const [sortKeyacess, setSortKeyacess] = useState('acess')
    const [sortTypeacess, setSortTypeacess] = useState('desc')
  
    const [locationVisitedData,SetLocationVisitedData]=useState([])

    const [unproductiveVisitedData,SetUnproductiveVisitedData]=useState([])
    const [acessData,SetAcessData]=useState([])
    const [donutData,SetDonutData] =useState([]);
    const [chartData,SetChartData]=useState({series: [] });
    const [chartDataWorking,SetChartDataWorking]=useState({series: [] });


    const [selectedData,SetSelectedData]=useState([]);
    const [selectedSeries,SetSelectedSeries]=useState({name:'',data:[],time:'',date:""});
    const [selectedSeriesTime,SetSelectedSeriesTime]=useState('');


    const [individualInfo,SetIndividualInfo] = useState([])
    const [loaderWidget,SetLoaderWidget] = useState(true);  

    const [widthContainer, setWidthContainer] = useState(0);
    //const elementRef = useRef(null);   
   
    const [activeTab,SetActiveTab] = useState('productivity');
    const [productivity,SetProductivity] = useState([])
    const [nonproductivity,SetNonProductivity] = useState([])
    const [lessproductivity,SetLessProductivity] = useState([])

    const [role,SetRole] = useState([]);
    const [permission,SetPermission] =useState({})
    const [permissionAPICall,SetPermissionAPICall] = useState(false);
    const [page,SetPage] = useState([]);

    const [loaderWidgetBottom,SetLoaderWidgetBottom] =useState(true);
    const [maxValue,SetmaxValue] = useState(60); 
    const [shiftStartTime,SetShiftStartTime]= useState('');

    const [shiftEndTime,SetShiftEndTime]= useState('');
    const [cumulativeHour,SetCumulativeHour]=useState('');
    const [shiftHourWorking,SetShiftHourworking]=useState();
    const [selectBarTime,SetSelectBarTime] = useState(date);
   // const [offShiftTime,SetOffShiftTime]=useState([]);
    const [cmlWorkingHour,SetCMLWorkingHour]=useState('');
    const [cmlNonWorkingHour,SetCMLNonWorkingHour]=useState('');
    const [productiveLocation,SetProductiveLocation]=useState('');
    const [workingPercent,SetWorkingPercent] =useState('');
    const [nonworkingPercent,SetNonWorkingPercent] =useState('');
    const [activeTabShift,SetActiveTabShift]=useState(true); 
    const [slug,SetSlug]=useState(props.match.params.employee?props.match.params.employee:'employee');
    const [errorNotCheckin,SetErrorNotCheckIn]=useState('');
    const [somethingWentWrong,SetSomethingWentWrong]=useState('');
   
    const [activeHour,SetActiveHour]=useState([]);
    const [activeHourDropdown,SetActiveHourDropdown] = useState([]);
    const [activeHourChart,SetActiveHourChart]=useState([]);

    const [startBigger,SetStartBigger]=useState(false);
    const [error,SetError]=useState('');
    const [start_time,SetStartTime]=useState([]);
    const [workerID,SetWorkerID] =useState(props.match.params.id.replace(":", ""));
    const [performanceChart,SetPerformanceChart] =useState({series: [],categories:[]});
    const [performanceType,SetPerformanceType] =useState("day");
    const [performanceMonth,SetPerformanceMonth] =useState(moment().month() + 1);
    const [performancePercent,SetPerformancePercent] =useState(0);
    const [loaderEmp,SetLoaderEmp]=useState(false)
    const [sucessMessage,SetSucessMessage]= useState(false);
    const [space,SetSpace]=useState(false);
    const [config,SetConfig] = useState({})
    const [useInc,SetUseInc]=useState(0);
    const [allEmp,SetAllEmp] = useState([]);
    const [loadingPerformance,SetLoadingPerformance] = useState(true);
    const [tag_serial,SetTagSerial]= useState("");
    const [performanceMonthDisplay,SetPerformanceMonthDisplay] =useState(moment().month() + 1+"|"+moment().format("YYYY"));
    const [performanceYear,SetPerformanceYear]= useState(moment().format("YYYY"))
    const [controller, setController] = useState(new AbortController());
    const month = generateMonthArray()
    const mapboxacess = props.pages.filter(el=>el.sub_page_name && el.sub_page_name =="Employee Tracker Mapbox").length >0?true:false; 
    const [temperatureChart,SetTemperatureChart]= useState({});
    const [temperatureLoader,SetTemperatureLoader]= useState(true);
    
    const [motionChart,SetMotionChart]= useState({});
    const [motionLoader,SetMotionLoader]= useState(true);


  const isWeekday = (date) => {
        let offWeek = props.weekDays.filter(item=>!item.working).map(el=>el.day);
        let holiday=props.holiday?props.holiday:[]
        const day = moment(date).isoWeekday();
        //here sunday
        if(offWeek.indexOf(day) > -1 || holiday.find(item=>moment(item.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') ) ){
            return true     
        }else{
          //here normal days
        return false ;
        }        
  };

  function generateMonthArray() {

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1; // Month is zero-based

let months = [];

for (let year = 2023; year <= currentYear; year++) {
  for (let month = 1; month <= 12; month++) {
    if (year === currentYear && month > currentMonth) {
       
      break; 
    }
    let startDate=moment(new Date(year, month, 1).toISOString().split('T')[0]);
 
       months.push({
            month:startDate.format("MMM"),
            days:startDate.daysInMonth(),
            intMonth:parseInt(startDate.format("MM")),
            year:year,
            start:startDate.startOf('month').format('YYYY-MM-DD'),
            end:startDate.endOf('month').format('YYYY-MM-DD')}
)    // Your logic for each month

  }
}
  return months;
}

    function SetLoaderEmpHandler(val,sucess){
        SetSpace(true)
        SetSucessMessage(sucess);
        SetLoaderEmp(val);
      }


      // Callback function to receive the selected date from BarChart
    //   const handleDateSelected = (date) => {
    //       setSelectedDate(date);
    //   };

    const [emptyPerform,SetEmptyPerform] = useState('');

    const element2=useRef(null); 
    const [trackerHeight,SetTrackerHeight] = useState(0);

    useEffect(() => {
        if(permission && permission.length > 0 && element2 && element2.current){
           
          SetTrackerHeight(element2.current.getBoundingClientRect().height);
        }
        
        }, [element2,permission]);
    
    
    function isDateGreaterThanToday(b) {
        var dS = b.split("/");
        var d1 = new Date(dS[1], (+dS[0] - 1));
        var today = new Date();
        if (d1 > today) {
            return dS[1] - 1;
        } else {
            return dS[1];
        }
    }
    function baseDateMonth(b) {
        var dS = b.split("/");
        var d1 = new Date(dS[1], (+dS[0] - 1));
        var today = new Date();
        
        if (d1 > today) {
        
            return new Date(dS[1] - 1, (+dS[0] - 1));
        } else {
        
            return new Date(dS[1], (+dS[0] - 1));
        }
    }
    
    useEffect(() => {
        
        let pagePerm= props.pages.filter(el=>el.sub_page_name=="Productivity Detail")
        
        SetPermission(pagePerm);
        SetPermissionAPICall(true)
            //toast.error("TW:16 Something went wrong while fetching role information");
        
        
    },[props.pages]);
    // useEffect(()=>{
    //     let mt = month.find(el=>el.intMonth == performanceMonth);
    //     if(mt && workerID!=""){
    //         let requestBody={}
    //         requestBody.start_date = mt.start
    //         requestBody.end_date= mt.end

    //     getOnBoardEmployee(requestBody,userSession,org_id,sub_org_id).then(res=>{
    //         if(res.status==200){
    //             let regWorker= res.data.regular?res.data.regular:[]
    //             let dayWorker= res.data.daily?res.data.daily:[]
    //             let allArray = [...regWorker,...dayWorker].filter(el=>el.worker_id == workerID);
              
    //             SetAllEmp(allArray);
    //         }
    //     })
    //     }

    // },[performanceMonth,workerID])

    const [configDonut,SetConfigDonut]=useState({
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height:200
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
          },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size:"90%",
                dataLabels: {
                    enabled: false
                },
                showInLegend: false
            }
        },
        series: [{
            name: 'Productivity',
            innerSize: '60%',
            colorByPoint: true,
            data:chartData.series
        }]
    });

    const categories= [
        '12 AM','01 AM','02 AM','03 AM','04 AM','05 AM','06 AM',
        '07 AM','08 AM','09 AM','10 AM','11 AM','12 PM','01 PM',
        '02 PM','03 PM','04 PM','05 PM','06 PM',
        '07 PM','08 PM','09 PM','10 PM','11 PM',
    ] 


    const categories2= [
        '07 AM','08 AM','09 AM','10 AM','11 AM','12 PM','01 PM',
        '02 PM','03 PM','04 PM','05 PM','06 PM',
        '07 PM','08 PM','09 PM','10 PM','11 PM','12 AM','01 AM','02 AM','03 AM','04 AM','05 AM','06 AM',
    ]

    const cat2= [
        '0','1','2','3','4','5','6',
        '7','8','9','10','11','12','13',
        '14','15','16','17','18','19','20',
        '21','22','23'
    ]

    const cat3= [
        7,8,9,10,11,12,13,
        14,15,16,17,18,19,20,
        21,22,23,0,1,2,3,4,5,6
    ]


    useEffect(()=>{
     
        let configStack = {
        chart: {
            type: 'column',
            height: 275,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: activeHour,
            gridLineWidth: 1,
            gridLineDashStyle: 'longdash',


            // tickPositioner: function () {
            //     let positions = []
            //     let tick = Math.floor(this.dataMin)
            //     let increment = Math.ceil((this.dataMax - this.dataMin) / (window.innerWidth > 1279)? 7: 6 );
            //     return positions;
            // },
            labels: {
                useHTML:true,
                formatter: function () {
                    

                    if(this.value=='12 AM'){
                        return `<div class="xaxis-legend xaxis-legend-12" style="font-size: 11px;">
                                                <div class="legendtime">${this.value}</div>
                                                <div class="legendDate" date="${moment(moment(date).add(1, 'days')).format('DD-MM-YY')}"></div>
                                                </div>`;

                          
                    }else if(this.value=='07 AM'){
                        return `<div class="xaxis-legend xaxis-legend-12" style="font-size: 11px;">
                                                <div class="legendtime">${this.value}</div>
                                                <div class="legendDate"  date="${moment(date).format('DD-MM-YY')}"></div>
                                                </div>`;
                    }else{
                        return `<div class="xaxis-legend" style="font-size: 11px;">
                                                <div class="legendtime">${categories[this.value]}</div>
                                                <div class="legendDate"></div>
                                                </div>`;
                    }
                    

     
                }
            },
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        legend:{enabled:false},
        yAxis: {
            min:0,
            //max:maxValue>60?60:maxValue,
            max:3600000,
            tickAmount:7,
           
            labels: {
                formatter: function () {
           
                    
                let seconds = this.value / 1000;
                // 2- Extract hours:
                const hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
                seconds = seconds % 3600; // seconds remaining after extracting hours
                // 3- Extract minutes:
                const minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
                // 4- Keep only seconds not extracted to minutes:
                seconds = seconds % 60;
                //alert( hours+":"+minutes+":"+seconds);
                let time = hours>0 ?hours+":"+minutes+":"+seconds:minutes+":"+seconds
                if(time=="1:0:0"){
                    time="1hr"
                }
                    return  time;
                }
            },

            gridLineWidth: 1,
            gridLineDashStyle: 'longdash',
            allowDecimals:false,
            title: {
                text: ''
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        tooltip: {
            useHTML: true,
            
            formatter:  function () {

            
                var mins1=parseInt((parseInt(this.y/60000)));
                var sec1=parseInt((this.y%3600000)/1000);

                let seconds = this.y / 1000;
               
                const hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
                seconds = seconds % 3600; // seconds remaining after extracting hours
               
                const minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
               
                seconds = parseInt(seconds % 60) < 10?"0"+(seconds % 60):seconds % 60;
               
                let time = hours>0 ?hours+":"+minutes+":"+seconds:minutes+":"+seconds
                if(time=="1:0:0"){
                    time="1hr"
                }

                let time2=  moment(this.point.date, "YYYY-MM-DD").format("DD MMM")+" "+categories[this.x];
                  
                

                return `<span style="font-size: 13px;padding-right:15px;" class="tool-tip-x">${time2}</span><br/>
                         <span dx="0" class="tool-tip-name">${this.series.name} : </span><span style="font-weight:bold" dx="0" class="tool-tip-result">${time} </tspan>`;                         
                } ,
        },

        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: helpEnable?true:false,
                    formatter: function() {
                                        var mins1=parseInt((parseInt(this.y/60000)));
                var sec1=parseInt((this.y%3600000)/1000);

                let seconds = this.y / 1000;
               
                const hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
                seconds = seconds % 3600; // seconds remaining after extracting hours
               
                const minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
               
                seconds = parseInt(seconds % 60) < 10?"0"+(seconds % 60):seconds % 60;
               
                let time = hours>0 ?hours+":"+minutes+":"+seconds:minutes+":"+seconds
                if(time=="1:0:0"){
                    time="1hr"
                }


                        
                        
                        return time;
                    },
                    style: {
                    fontWeight: 'bold',
                    fontSize:"10px",
                    color: '#32325d',   
                    }
                },
                states: {
                    select: {
                        color: '#ef5e8c',
                        borderColor:'#ef5e8c',
                    },
                },    
                point: {
                    events: {
                        click: (e) => {
                            e.point.select(true);
                           
                            formatSelectedData({name:e.point.series.name,data:e.point.tooltip,time:e.point.category},startBigger);
                        
                        }
                    }
                },
    
            },

        },
        series:activeTabShift?chartDataWorking.series:chartData.series
        
    }
    
    SetConfig(configStack);
SetUseInc((prev)=>prev + 1)

    },[helpEnable,chartData,activeTabShift,chartDataWorking])



    

            function formatSelectedData(e,stime=startBigger){
                // {name:e.point.series.name,data:e.point.tooltip,time:e.point.category}

                if(e.name){
                        if(e.time > -1){
                        SetStartTime([e.time]);
                        }
                    
                    e.time= categories[e.time]
                    
                    if(stime && parseInt(categories.indexOf(e.time)) < 11){

                        e.date=moment(selectedDate, "YYYY-MM-DD").add(1,'days').format("YYYY-MM-DD");
                    }else{
                        e.date=moment(selectedDate).format("YYYY-MM-DD");
        
                    }
                    
                  
                    SetSelectedSeries(e)
                    
                }
            }

    
    var timezoneOffset = 60 * -2; // in minutes

    function toMs(timeUnformatted) {
        timeUnformatted = timeUnformatted < 10 ? ("0" + timeUnformatted) : timeUnformatted.toString();
        return Date.parse("1/1/1 " + timeUnformatted.replace(".", ":") + ":00") - Date.parse("1/1/1 00:00:00");
      }

    useEffect(()=>{
        if(permission && permission.length > 0  && errorNotCheckin==""){
        let requestBody={}
        // requestBody.startdate=getDateFormat(selectedDate)
        // requestBody.enddate=getDateFormat(selectedDate)
        if (selectedDate1) {
            requestBody.startdate=selectedDate1
        }else{
            requestBody.startdate=getDateFormat(selectedDate)
        }
        
        if (selectedDate1) {
            requestBody.enddate=selectedDate1
        }else{
            requestBody.enddate=getDateFormat(selectedDate)
        }
        
        requestBody.slug=slug
        requestBody.worker_id=idVal
        setDonutLoader(true)
        let maxVal = 0;
        let dataOFselected=[]
        
            let letSeriesData=[]

            getDetailedProductivityChart(requestBody,userSession,org_id,sub_org_id).then(res=>{
               
                if(res.status==200){
                    letSeriesData.push({
                        name: 'Productive',
                        y: Math.abs(res.data.productive),
                        color:'#90cf4e',
                    },
                    {
                        name: 'Les Productive',
                        y: Math.abs(res.data.less_productive),
                        color:'#fdbf00',
                    },{
                        name: 'Non Productive',
                        y: Math.abs(res.data.non_productive),
                        color:'#fc0001',
                    },{
                        name: 'Out of Range',
                        y: Math.abs(res.data.undefined),
                        color:'#dedee3',
                    },
                    
                    ) 
                SetDonutData(letSeriesData);
                let  configDonutEG= {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        height:180
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                      },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            size:"100%",
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: false
                        }
                    },
                    series: [{
                        name: 'Productivity',
                        innerSize: '60%',
                        colorByPoint: true,
                        data:letSeriesData,
                        cursor: 'pointer',

                    }]
                };
                SetConfigDonut(configDonutEG)
                setDonutLoader(false)
            }else if(res.status == 302){
                toast.error("TW:1  Something Went Wrong While Fetching Productivity Chart info");
                SetDonutData([])
            //resetting donut data
             let  configDonutEG= {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        height:180
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                      },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            size:"100%",
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: false
                        }
                    },
                    series: [{
                        name: 'Productivity',
                        innerSize: '60%',
                        colorByPoint: true,
                        data:[],
                        cursor: 'pointer',

                    }]
                };
                SetConfigDonut(configDonutEG)
                                             setDonutLoader(false);
                
                    // SetLoaderEmp(false);

            }else{
                toast.error("TW:1  Something Went Wrong While Fetching Productivity Chart info");
            SetErrorNotCheckIn('User is not Check in for a day, please try with some other dates');
            //toast.error("TW:1 Something Went Wrong While Fetching Productivity Chart info");
            SetDonutData([])
            //resetting donut data
             let  configDonutEG= {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        height:180
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                      },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            size:"100%",
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: false
                        }
                    },
                    series: [{
                        name: 'Productivity',
                        innerSize: '60%',
                        colorByPoint: true,
                        data:[],
                        cursor: 'pointer',

                    }]
                };
                SetConfigDonut(configDonutEG)
                SetShiftStartTime("")
                SetShiftEndTime("")
                SetShiftHourworking("")
                SetCMLWorkingHour("")
                SetCMLNonWorkingHour("")
                SetWorkingPercent("")
                SetNonWorkingPercent("")
                SetIndividualInfo({})
                SetProductivity([])
                SetNonProductivity([])
                SetLessProductivity([])





                    // Modal.error({
                    //     title: 'User is not Checkin',
                    //     content: (
                    //       <>
                    //         {res.message+" , Please Try with some other dates"}
                            
                    //       </>
                    //     ),
                    //   });
                    SetSelectedSeries({})
                SetChartDataWorking({series: [] })
                             setDonutLoader(false);
                    setChartLoader(false);
                        
                    SetLoaderEmp(false);
            // toast.error("TW:1  Something Went Wrong While Fetching Productivity Chart info");
            SetDonutData([])




                setDonutLoader(false)
            }           
           
           
            })

            getIndividualProductivityList(requestBody,userSession,org_id,sub_org_id).then(res=>{
                if(res.status==200){
                    SetProductivity(res.data.productive);
                    SetLessProductivity(res.data.less_productive)
                    SetNonProductivity(res.data.non_productive)
                }
                else if(res.status==201){
                    // toast.error("TW:1 - 1  Something Went Wrong While Fetching Productivity Chart info");

            SetErrorNotCheckIn('User is not Check in for a day, please try with some other dates');
            //toast.error("TW:1 Something Went Wrong While Fetching Productivity Chart info");
            SetDonutData([])
            //resetting donut data
             let  configDonutEG= {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        height:180
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                      },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            size:"100%",
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: false
                        }
                    },
                    series: [{
                        name: 'Productivity',
                        innerSize: '60%',
                        colorByPoint: true,
                        data:[],
                        cursor: 'pointer',

                    }]
                };
                SetConfigDonut(configDonutEG)
                SetShiftStartTime("")
                SetShiftEndTime("")
                SetShiftHourworking("")
                SetCMLWorkingHour("")
                SetCMLNonWorkingHour("")
                SetWorkingPercent("")
                SetNonWorkingPercent("")
                SetIndividualInfo({})
                SetProductivity([])
                SetNonProductivity([])
                SetLessProductivity([])





                    // Modal.error({
                    //     title: 'User is not Checkin',
                    //     content: (
                    //       <>
                    //         {res.message+" , Please Try with some other dates"}
                            
                    //       </>
                    //     ),
                    //   });
                    SetSelectedSeries({})
                SetChartDataWorking({series: [] })
                             setDonutLoader(false);
                    setChartLoader(false);
                               
                    SetLoaderEmp(false);
            // toast.error("TW:1  Something Went Wrong While Fetching Productivity Chart info");
            SetDonutData([])
                    SetErrorNotCheckIn('User is not Check in for a day, please try with some other dates')
                }
            }) 
        }

    },[selectedDate,permission,selectedDate1])

    function converttoTime(val){

        let seconds = val / 1000;
                // 2- Extract hours:
                const hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
                seconds = seconds % 3600; // seconds remaining after extracting hours
                // 3- Extract minutes:
                const minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
                // 4- Keep only seconds not extracted to minutes:
                seconds = seconds % 60;
                //alert( hours+":"+minutes+":"+seconds);
                return hours>0 ?<><span>{hours}</span>H <span>{minutes}</span>M <span>{seconds}</span>S</>:<><span>{minutes}</span>M <span>{seconds}</span>S</>


    }
    
    function converttoTimeAG(val){
        let seconds = val / 1000;
        const hours = parseInt( seconds / 3600 );
        seconds = seconds % 3600;
        const minutes = parseInt( seconds / 60 ); 
        seconds = seconds % 60;
        return hours>0 ? hours +":"+ minutes+":"+seconds:"00:"+ minutes+":"+seconds
    }
    
    // function fetchAllHour(start,end,data){
    //     const startDate = start;
    //     const endDate = end; // Assuming end_time is on the next day
    //     const hourArray = [];
    //     while (startDate < endDate) {
    //     const hourString = moment(startDate).format('H');
    //     hourArray.push({hour:hourString,date:moment(startDate).format('YYYY-MM-DD'),sortTime:moment(startDate).format('YYYY-MM-DD')+" "+hourString+":00:00" });

    //     startDate.setHours(startDate.getHours() + 1);
    //     }
    //     return hourArray;

    // }

    function fetchAllHour(start,end,data){
        const startDate = start;
        const endDate = end; // Assuming end_time is on the next day

        const hourArray = [];
        Object.keys(data).forEach(element => {
            Object.keys(data[element]).forEach(key2=>{
                hourArray.push({hour:parseInt(key2).toString() ,date:element,sortTime:element+" "+key2+":00:00"});
            });        
        });
        let sortedTIme = hourArray.sort((a,b)=>new Date(a.sortTime) - new Date(b.sortTime))
        console.log(hourArray,sortedTIme)

        return sortedTIme;

    }

    

    useEffect(()=>{

controller.abort();
const newController = new AbortController();
const fetchData = async () => {
      try {
if (permission && permission.length > 0 && errorNotCheckin === "") {
        let requestBody={}
        requestBody.startdate=selectedDate1?selectedDate1:getDateFormat(selectBarTime)
        requestBody.enddate=selectedDate1?selectedDate1:getDateFormat(selectBarTime)
        requestBody.slug=slug
        requestBody.worker_id=idVal
        SetErrorNotCheckIn("")
        setChartLoader(true);
        SetChartDataWorking({series: [] })
        let maxVal = 0;
        let dataOFselected=[]
        let shiftHour=[]
        let prodloc=[]    
    //    let res = response
        let productive=[];
        let nonproductive=[];
        let lessproductive=[];
        let undefinedproductive=[];
        let data=[];
        let allData= [] ;
        let allTIme=0;
        let allProdTime = 0;
        let acHour=[]



          setController(newController);
       const response =  await fetch(prefixURL +
              `/get_productivity_detailed_overview?session=${userSession}&org_id=${org_id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&worker_id=${requestBody.worker_id}&sub_org_id=${sub_org_id}`,
            {
              signal: newController.signal,
            })

          let res= await response.json()
            
            if(res.status==200){
                           let ShiftHourSort=[];
            let shift = res.shift && typeof res.shift == "object"?res.shift:res.shift?JSON.parse(res.shift):[]
            
            let startSft= res.shift?shift.start_time.split(':'):null
            
            let endSft= res.shift?shift.end_time.split(':'):null
            
            let cmlHour = res.shift?shift.productive_work_time:0
            let noncmlHour = res.shift?shift.wasted_work_time:0
            let numericShiftHour =parseInt(shift.shift_hours);

            let activeShiftHour = [];
            let calSTart= moment().set({hour:startSft[0],minute:startSft[1],second:startSft[2]}).toDate()
            let calEnd= moment().set({hour:endSft[0],minute:endSft[1],second:endSft[2]}).toDate()
            
            
                if(calSTart > calEnd){
                    calEnd= moment(calEnd).add(1,'days').toDate()
                }
                const durationCheckIn = moment.duration(Math.abs(calSTart - calEnd));
            
            SetShiftHourworking(res.shift.shift_hours+":"+(res.shift.shift_minutes<10?"0"+res.shift.shift_minutes:res.shift.shift_minutes));
            SetShiftStartTime(startSft?startSft[0]+":"+startSft[1]:'-');
            SetShiftEndTime(endSft?endSft[0]+":"+endSft[1]:'-')
           
            // let st = parseInt(startSft && startSft[0]?parseInt( Math.ceil(startSft[1])) > 0 && parseInt(Math.ceil(startSft[0]))!=23 ?parseInt(Math.ceil(startSft[0])) + 1:parseInt(Math.ceil(startSft[0])):0);
            // let et = parseInt(endSft && endSft[0]?parseInt(Math.ceil(endSft[1])) > 0 && parseInt(endSft[0])!=23 ?parseInt(Math.ceil(endSft[0])) + 1:parseInt(Math.ceil(endSft[0])):0);
            
            let st = parseInt(startSft && startSft[0]?parseInt(startSft[1]) > 0 && parseInt(startSft[0])!=23 ?parseInt(startSft[0]):parseInt(startSft[0]):0);
            let et = parseInt(endSft && endSft[0]?parseInt(Math.ceil(endSft[1])) > 0 && parseInt(endSft[0])!=23 ?parseInt(endSft[0]) + 1:parseInt(endSft[0]):0);
            let oft=[]
            let smaller=st>et?et:st;
            let bigger = st>et?st:et;
            
            cmlHour = moment.duration(cmlHour).asMilliseconds()
           noncmlHour= moment.duration(noncmlHour).asMilliseconds()
            SetCMLWorkingHour(converttoTime(moment.duration(cmlHour).asMilliseconds()));
            SetCMLNonWorkingHour(converttoTime(moment.duration(noncmlHour).asMilliseconds()))
            SetSomethingWentWrong("");
            SetErrorNotCheckIn('')
            let stbigger=false;
                if(et < 7){
                    SetStartBigger(true);
                }else{
                    SetStartBigger(false);
                }    

        // ShiftHourSort.sort(function(a,b){
        //      return  new Date(a) - new Date(b);
        //   });
        //   for(let i=0;i<ShiftHourSort.length;i++){
       
        //     if(i >= (numericShiftHour)){
        //         ShiftHourSort.splice(i,1);
        //     }
        //   }

          
           SetWorkingPercent(res.shift?shift.productive_work_percent:0);
           SetNonWorkingPercent(res.shift?shift.wasted_work_percent:0)

            Object.keys(res.data).forEach((key)=>{

                    const entries = Object.entries(res.data[key]);
                    entries.sort((a, b) => parseInt(a[0]) - parseInt(b[0]));
                    let data = entries.reduce((acc, el) => {
                        const key = el[0];
                        const value = el[1];
                        acc[parseInt(key)] = value;
                       
                        return acc;
                        }, {});
                    // let data = res.data[key];


                    Object.keys(data).forEach((key2)=>{

                let timeProd=0
                let lessProd=0
                let nonProd=0
                       acHour.push(key2);   
                       Object.keys(data[key2].productive).forEach((key3,i)=>{
                                     
                        let elm = data[key2].productive[key3];

                        timeProd= timeProd + Math.round(moment.duration(elm).asMilliseconds());
                         let index = prodloc.findIndex(item=>item.name==key3);
                        if(index > -1){
                            let ti = prodloc[index].time + Math.round(moment.duration(elm).asMilliseconds())                            
                            prodloc[index]={name:key,time:ti};
                        }else{
                            prodloc.push({name:key,time:Math.round(moment.duration(elm).asMilliseconds()) })
                        }                       
                     })
                    productive.push({y:timeProd,tooltip:data[key2].productive,date:key,timeNumeric:key2})
                        Object.keys(data[key2].less_productive).forEach((key3,i)=>{
                        let elm = data[key2].less_productive[key3];
                        lessProd= lessProd + Math.round(moment.duration(elm).asMilliseconds());
                    })
                    lessproductive.push({y:lessProd,tooltip:data[key2].less_productive,date:key,timeNumeric:key2})
                   
                    allProdTime = allProdTime+ lessProd;
                    Object.keys(data[key2].non_productive).forEach((key3,i)=>{
                        let elm = data[key2].non_productive[key3];
                        nonProd= nonProd + Math.round(moment.duration(elm).asMilliseconds());
                    })
                    nonproductive.push({y:nonProd,tooltip:data[key2].non_productive,date:key,timeNumeric:key2})
                if(maxVal< timeProd ){
                        maxVal= timeProd;
                        dataOFselected={name:'Productive',data:data[key2].productive,time:categories[key2],date:key,timeNumeric:key2}
                    }

                    if(maxVal<nonProd){
                        maxVal=nonProd;
                        dataOFselected={name:'Non Productive',data:data[key2].non_productive,time:categories[key2],date:key,timeNumeric:key2}
                    }

                    if(maxVal<lessProd){
                        maxVal=lessProd;
                        dataOFselected={name:'Less Productive',data:data[key2].less_productive,time:categories[key2],date:key,timeNumeric:key2}
                    }
                    });
                });
                let sDate= moment(requestBody.startdate).set({hour:st}).toDate();
                let eDate= moment(requestBody.startdate).set({hour:et}).toDate();
                if(st > et){
                    eDate = moment(requestBody.startdate).add(1,'days').set({hour:et}).toDate();
                }
               let allHour =  fetchAllHour(sDate,eDate,res.data);
               let newProd=[];
               let newLessProd = [];
               let newnonProd =[];
               let newundefined =[]
                
               for(let i=0;i<allHour.length;i++){

                let prodIndex = productive.findIndex(el=>el.timeNumeric  == allHour[i].hour )
                let nonProdIndex =nonproductive.findIndex(el=>el.timeNumeric  == allHour[i].hour )
                let lessProdIndex = lessproductive.findIndex(el=>el.timeNumeric  == allHour[i].hour )
                let undefinedIndex = undefinedproductive.findIndex(el=>el.timeNumeric == allHour[i].hour);
                //productive append zero
                if(prodIndex>-1){
                    newProd.push(productive[prodIndex]);
                }else{
                    newProd.push({y:0,tooltip:{},date:allHour[i].date,timeNumeric:allHour[i].hour})
                }
                //less prod append zero
                if(lessProdIndex>-1){
                    newLessProd.push(lessproductive[lessProdIndex]);
                }else{
                    newLessProd.push({y:0,tooltip:{},date:allHour[i].date,timeNumeric:allHour[i].hour})
                }

                 //non prod append zero
                if(nonProdIndex>-1){
                    newnonProd.push(nonproductive[nonProdIndex]);
                }else{
                    newnonProd.push({y:0,tooltip:{},date:allHour[i].date,timeNumeric:allHour[i].hour})
                }
                //undefined 
                if(undefinedIndex > -1){
                    newundefined.push(undefinedproductive[undefinedIndex])
                }else{
                    newundefined.push({y:0,tooltip:{},date:allHour[i].date,timeNumeric:allHour[i].hour})
                }

               }

              SetActiveHourDropdown(allHour)

            SetActiveHour(allHour.map(el=>el.hour));
            SetProductiveLocation(prodloc)
            console.log("allHour",allHour,newProd,newnonProd,newundefined);
            if(categories.indexOf(dataOFselected.time) > -1){   
                SetStartTime([categories.indexOf(dataOFselected.time)]);
            }
            console.log("allHour",allHour)
            SetSelectedSeries({name:dataOFselected.name,data:dataOFselected.data,time:dataOFselected.time,date:dataOFselected.date,timeNumeric:dataOFselected.timeNumeric})
            SetChartDataWorking({
                    series:[
                      {
                            name:"Out of Range",
                            data:newundefined,
                            color:"#dedee3",
                            cursor: 'pointer',
                            states: {
                                select: {
                                    
                                    color: '#ef5e8c',
                                    borderColor:'#ef5e8c',
                                },
                            },
                            allowPointSelect: true,
                            point: {
                                events: {
                                    click: (e) => {
                                        e.point.select(true);
                                       
                                        formatSelectedData({name:e.point.series.name,data:e.point.tooltip,time:e.point.category});                 
                                    
                                    }
                                }
                            }   
                    
                        },
                      {
                        name:"Non Productive",
                        data:newnonProd,
                        color:"#fc0001",  
                        cursor: 'pointer',
                        states: {
                            select: {
                                
                                color: '#ef5e8c',
                                borderColor:'#ef5e8c',
                            },
                        },
                        allowPointSelect: true,
                        point: {
                            events: {
                                click: (e) => {
                                    e.point.select(true);
                                    formatSelectedData({name:e.point.series.name,data:e.point.tooltip,time:e.point.category,date:e.point.date,timeNumeric:e.point.timeNumeric});                           
                                }
                            }
                        }
                    },
                    {
                        name:"Less Productive",
                        data:newLessProd,
                        color:"#fdbf00",
                        cursor: 'pointer',
                        states: {
                            select: {
                                
                                color: '#ef5e8c',
                                borderColor:'#ef5e8c',
                            },
                        },
                        allowPointSelect: true,
                        point: {
                            events: {
                                click: (e) => {
                                    e.point.select(true);
                                    formatSelectedData({name:e.point.series.name,data:e.point.tooltip,time:e.point.category,date:e.point.date,timeNumeric:e.point.timeNumeric});                 
                                }
                            }
                        },
                    },
                      {
                        name:"Productive",
                        data:newProd,
                        color:"#90cf4e",
                        cursor: 'pointer',
                        states: {
                            select: {
                                
                                color: '#ef5e8c',
                                borderColor:'#ef5e8c',
                            },
                        },
                        allowPointSelect: true,
                        point: {
                            events: {
                                click: (e) => {
                                    e.point.select(true);
                                    formatSelectedData({name:e.point.series.name,data:e.point.tooltip,time:e.point.category,date:e.point.date,timeNumeric:e.point.timeNumeric});                 
                                
                                }
                            }
                        },
                    },
                        ]  
                    })

               console.log("seq",{
                    series:[
                      {
                            name:"Out of Range",
                            data:newundefined,
                            color:"#dedee3",
                            cursor: 'pointer',
                            states: {
                                select: {
                                    
                                    color: '#ef5e8c',
                                    borderColor:'#ef5e8c',
                                },
                            },
                            allowPointSelect: true,
                            point: {
                                events: {
                                    click: (e) => {
                                        e.point.select(true);
                                       
                                        formatSelectedData({name:e.point.series.name,data:e.point.tooltip,time:e.point.category});                 
                                    
                                    }
                                }
                            }   
                        },
                      {
                        name:"Non Productive",
                        data:newnonProd,
                        color:"#fc0001",  
                        cursor: 'pointer',
                        states: {
                            select: {
                                
                                color: '#ef5e8c',
                                borderColor:'#ef5e8c',
                            },
                        },
                        allowPointSelect: true,
                        point: {
                            events: {
                                click: (e) => {
                                    e.point.select(true);
                                    formatSelectedData({name:e.point.series.name,data:e.point.tooltip,time:e.point.category,date:e.point.date,timeNumeric:e.point.timeNumeric});                           
                                }
                            }
                        }
                    },
                    {
                        name:"Less Productive",
                        data:newLessProd,
                        color:"#fdbf00",
                        cursor: 'pointer',
                        states: {
                            select: {
                                
                                color: '#ef5e8c',
                                borderColor:'#ef5e8c',
                            },
                        },
                        allowPointSelect: true,
                        point: {
                            events: {
                                click: (e) => {
                                    e.point.select(true);
                                    formatSelectedData({name:e.point.series.name,data:e.point.tooltip,time:e.point.category,date:e.point.date,timeNumeric:e.point.timeNumeric});                 
                                }
                            }
                        },
                    },
                      {
                        name:"Productive",
                        data:newProd,
                        color:"#90cf4e",
                        cursor: 'pointer',
                        states: {
                            select: {
                                
                                color: '#ef5e8c',
                                borderColor:'#ef5e8c',
                            },
                        },
                        allowPointSelect: true,
                        point: {
                            events: {
                                click: (e) => {
                                    e.point.select(true);
                                    formatSelectedData({name:e.point.series.name,data:e.point.tooltip,time:e.point.category,date:e.point.date,timeNumeric:e.point.timeNumeric});                 
                                
                                }
                            }
                        },
                    },
                        ]  
                    });     

                SetmaxValue(maxVal)
                // config.yAxis.max=10;
                // config.yAxis.tickAmount= 1;
                    setTimeout(function(){
                        setChartLoader(false);
                        
                    },100)
                
                

            }else if(res.status==302){
                
                SetShiftStartTime("")
                SetShiftEndTime("")
                SetShiftHourworking("")
                SetCMLWorkingHour("")
                SetCMLNonWorkingHour("")
                SetWorkingPercent("")
                SetNonWorkingPercent("")
                //SetIndividualInfo({})
                //SetProductivity([])
                //SetNonProductivity([])
                //SetLessProductivity([])
                SetSelectedSeries({})
                SetChartDataWorking({series: [] })
                            //  setDonutLoader(false);
                    setChartLoader(false);
                   
                    SetLoaderEmp(false);
            // toast.error("TW:1  Something Went Wrong While Fetching Productivity Chart info");
            // SetDonutData([])




                setDonutLoader(false)


            }
            else{
                SetSomethingWentWrong("Oops Something went Wrong");
                setChartLoader(false)
                                 
            }
          
        }
        
    }catch(error){
        if (axios.isCancel(error)) {
        
        } 
    }finally {
        // setChartLoader(false); 
                       
      }
 }
    fetchData();
   return () => {

      newController.abort(); 
    };
},[permission,selectedDate,selectedDate1])



   useEffect(()=>{
    if(permission && permission.length > 0 && errorNotCheckin==""){   
    let requestBody={}
       requestBody.slug=slug
       requestBody.worker_id=idVal;
       requestBody.startdate= getDateFormat(selectedDate1);
       requestBody.enddate= getDateFormat(selectedDate1);
       getProductivityIndividualInfo(requestBody,userSession,org_id,sub_org_id).then(res=>{
           
           if(res.status===200){
               SetIndividualInfo(res.data);
               requestBody.worker_id= res.data.worker_id
               SetTagSerial(res.data.tag_serial);
           }else if(res.status==201){
            SetErrorNotCheckIn('User is not Check in for a day, please try with some other dates')
           }
       })
    }
    
   },[selectedDate1,permission]) 

 const getMonths = (fromDate, toDate, performance) => {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    if (performance == "month") {
      let loop = 0;
      for (let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for (; monthNum <= monthLimit; monthNum++) {
          let month2 = monthNum + 1;
          let MonthAct = month.find((item) => item.intMonth == month2);
          let start = "";
          let end = "";
          let cond = "";

          if (loop == 0 && year == toYear && monthNum == monthLimit) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 1";
          } else if (loop == 0) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 2";
          } else if (year == toYear && monthNum == monthLimit) {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 3";
          } else {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 4";
          }
          loop++;

          months.push({
            year,
            month: month2,
            start: start,
            end: end,
            cond: cond,
          });
        }
      }

      return months;
    }
    // else {return getMonths(fromDate, toDate,'month');}
   else {

      return generateWeeks(fromDate, toDate);
    }
  };

  

function generateWeeks(startDate, endDate) {
  const weeks = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const weekStart = new Date(currentDate);
    const weekEnd = new Date(currentDate);
    
    if (currentDate.getDay() === 0) {
      // If it's a Sunday, start the week from Sunday
      weekEnd.setDate(currentDate.getDate() + 6); // Set to Saturday
    } else {
      // Otherwise, start the week from the given startDate
      weekEnd.setDate(currentDate.getDate() + (6 - currentDate.getDay())); // Set to Saturday
    }

    if (weekEnd > endDate) {
      // Adjust the end date for the last week
      weekEnd.setDate(endDate.getDate());
    }

    const weekInfo = {
      start: weekStart.toISOString().split('T')[0],
      end: weekEnd.toISOString().split('T')[0],
      month: (weekStart.getMonth() + 1).toString().padStart(2, '0'),
      year: weekStart.getFullYear().toString(),
      weekName: `Week ${getWeekNumber(weekStart)}`,
    };

    weeks.push(weekInfo);
    currentDate = new Date(weekEnd);
    currentDate.setDate(currentDate.getDate() + 1); // Move to next day (Sunday or the next day of the end of the last week)
  }

  return weeks;
}

function getWeekNumber(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - oneJan) / 86400000);
  return Math.ceil((days + oneJan.getDay() + 1) / 7);
}
const ColorPaleteTemp= ['#002060', '#0066ff','#01ffff' ,'green','orange','#ff6600','#ff0000']
  let zonesTemp=[{
    value: 10.1,
    color: ColorPaleteTemp[0]
  }, {
    value: 15.1,
    color: ColorPaleteTemp[1]
  }, {
    value: 20.1,
    color: ColorPaleteTemp[2]
  }, {
    value: 30.1,
    color: ColorPaleteTemp[3]
  }, {
    value: 40,
    color: ColorPaleteTemp[4]
  },{
    color: ColorPaleteTemp[5]
  }
]
let zonesActivity=[{
    value: 0.1,
    color: "red"
  }, {
    color: "green"
  }]

  function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}

useEffect(()=>{
   if(selectedDate && selectedDate!=undefined && userDetails.org_id==36){

let requestBody={
    startdate:getDateFormat(selectedDate),
    enddate:getDateFormat(selectedDate)
}
getProductivityTemperature(requestBody,userSession,org_id,sub_org_id, workerID).then(res=>{
    if(res.status===200){
    res.data.sort((a,b)=>new Date(a[1]  ) - new Date(b[1]  )  )
    SetTemperatureChart({
      chart: { type: "line" ,zoomType: 'x',height:300},
      title: { text: "" },
      xAxis: { type: "datetime" },

      yAxis: {
        title: { text: "Temperature (°C)" },
        min:0,
      },
      plotOptions: {
                  series: {
                    animation: false,
                    borderWidth: 1,
                    marker: {
                      enabled: true,
                      radius:1,
                    },
                    connectNulls:true,
                  }
              },
              legend:{enabled:false},
      series: [
        {
          name: "Temperature",
          data:res.data.map((el)=>([new Date(el[1]).getTime(), el[0] ])) ,
          zones:zonesTemp,
            tooltip: {
                valueSuffix: ' °C',
                valueDecimals:2,
            }
        },
      ],
    })

    SetTemperatureLoader(false);



    }else{
        SetTemperatureLoader(false);
        SetTemperatureChart({});
        toast.error("Something Wrong while fetching employee temperature info")
    }   

})


getProductivityMontion(requestBody,userSession,org_id,sub_org_id,workerID).then(res=>{
    if(res.status===200){
        console.log("motion chart",res.data);

    res.data.sort((a,b)=>new Date(a[1]  ) - new Date(b[1]  )  )
    SetMotionChart({
      chart: { type: "line" ,zoomType: 'x',height:300},
      title: { text: "" },
      xAxis: { type: "datetime" },
      yAxis: {
        title: { text: "Activity" },
        min:0,
        max:2,
        tickAmount:3
      },
      plotOptions: {
                  series: {
                    animation: false,
                    borderWidth: 1,
                    marker: {
                      enabled: true,
                      radius:1,
                    },
                    connectNulls:true,
                  }
              },
              legend:{enabled:false},
              tooltip: {
    formatter: function () {
      const activity = this.y === 1 ? "Moving" : "Static"; // Custom tooltip text
      return `
        <b>${Highcharts.dateFormat('%e %b %Y, %H:%M:%S', this.x)}</b><br/>
        Activity: <b>${activity}</b>
      `;
    }
  },
      series: [
        {
          name: "Activity",
          data:res.data.map((el)=>([new Date(el[1]).getTime(), el[0]==="MOVING"?1:0])) ,
          zones:zonesActivity,
          
        },
      ],
    })

    SetMotionLoader(false);


    }else{
        SetMotionLoader(false);
        SetMotionChart({});
        toast.error("Something Wrong while fetching employee motion info")
    }
})


   }


},[workerID,selectedDate])


   useEffect(()=>{

    if(permission && permission.length > 0 && workerID!=''){   
        let requestBody={}
           requestBody.slug=slug
           requestBody.worker_id=idVal;
           requestBody.startdate= getDateFormat(selectedDate);
           requestBody.enddate= getDateFormat(selectedDate);
           requestBody.worker_id= workerID

    let monthDate = month.find(item=> parseInt(item.intMonth)  == parseInt(performanceMonth) && parseInt(item.year) ==parseInt( performanceYear));
    // let monthDate 
//2023-02-10
    SetLoadingPerformance(true);
   requestBody.startdate= `${monthDate.year}-${monthDate.intMonth}-01`;
   requestBody.enddate= `${monthDate.year}-${monthDate.intMonth}-${monthDate.days}`;
   
   getPeformanceIndividualHistory(requestBody,userSession,org_id,sub_org_id).then(res2=>{
    
    let cat = []
    let series1 = []
    let series2 = []

    if(res2.status>200){
        SetEmptyPerform("No Data Found");
        SetPerformanceChart({categories:cat,
            series:[{
                name:"Total working Hour",
                data:series2,
                color:"#90cf4e"
            }]
        })
        SetLoadingPerformance(false);


    }else{

        let week=[];
        let weekCat=['W1'];
        let weekList = getMonths(new Date(monthDate.start), new Date(monthDate.end), 'week');
        
        let weekStart=[
            {'W1':{start:"",end:""}},
            {'W2':{start:"",end:""}},
            {'W3':{start:"",end:""}},
            {'W4':{start:"",end:""}},
            {'W5':{start:"",end:""}},
            {'W6':{start:"",end:""}},
            {'W7':{start:"",end:""}},
            
        ];


        let weekCount=1;
        let weekArray=[];
        weekArray.W1=[];
        let perform=[]
        if(performanceType=="week"){
           
            weekCat= weekList.map(el=>el.weekName);
            const dataArray = Object.keys(res2.data).map((date) => ({
                        date,
                        wasted_hours: res2.data[date].wasted_hours,
                        work_hours: res2.data[date].work_hours,
                }));

            for(let i = 0;i<weekList.length;i++){
                let allWaeek =  dataArray.filter(el=>moment(el.date)>= moment(weekList[i].start).set({hour:0,minute:0,second:0}) && moment(el.date) <= moment(weekList[i].end) && (el.wasted_hours > 0 || el.work_hours > 0));
           
                let avgVal =  allWaeek.reduce((a, b) => a + b.work_hours, 0);

                if(avgVal > 0){
                    avgVal =(avgVal/allWaeek.length).toFixed(2);
                }
                series1.push({y:parseFloat(avgVal) ,tooltip:moment(weekList[i].start).format('DD MMM')+" - "+moment(weekList[i].end).format('DD MMM') });
            }

            if(dataArray.reduce((a, b) => a + b.work_hours, 0) / dataArray.filter(el=>el.work_hours >0 || el.wasted_hours > 0 ).length > 0){
                SetEmptyPerform("");
            }else{
                SetEmptyPerform("No Data Found");
            }

            SetPerformancePercent(perform.reduce((a, b) => a + b, 0) / perform.filter(item=>item > 0).length ) 
            SetPerformanceChart({categories:weekCat,
                series:[{
                    name:"Total working Hour",
                    data:series1,
                    color:"#90cf4e"
                }]
            })
        }else{
            let elm=  res2.data;
  

//date loop
for(let i=1 ;i<=monthDate.days;i++){
    let loopDate=moment(`${monthDate.year}-${monthDate.intMonth}-${i}`); 
   // if(moment(loopDate).isoWeekday() == 7){
    if(isWeekday(loopDate)){
        cat.push(moment(loopDate).format('YYYY-MM-DD'));
        series2.push(0)      
    }else{
        if(res2.data[moment(loopDate).format('YYYY-MM-DD')]){
            cat.push(moment(loopDate).format('YYYY-MM-DD'));
            series2.push(res2.data[moment(loopDate).format('YYYY-MM-DD')].work_hours)
            perform.push(res2.data[moment(loopDate).format('YYYY-MM-DD')].work_hours);         
        }else{
            cat.push(moment(loopDate).format('YYYY-MM-DD'));
            series2.push(0);
        }
        //weekArray["week"+weekCount].push()
    }
}


           
            SetPerformancePercent(series2.reduce((a, b) => a + b, 0) / series2.filter(item=>item>0).length) 
            if(series2.reduce((a, b) => a + b, 0) / series2.length > 0){
                SetEmptyPerform("");
            }else{
                SetEmptyPerform("No Data Found");
            }
            SetPerformanceChart({categories:cat,
                series:[{
                    name:"Total working Hour",
                    data:series2,
                    color:"#90cf4e"
                }]
            })
                
        }

    }

SetLoadingPerformance(false)

    });
}
},[workerID,performanceMonth,performanceType,permission,performanceYear]) 

    const handleDate = (date, type) => {
        //setDates((prev) => ({ ...prev, [`${type}`]: date }));
        if(type=='start'){
            localStorage.setItem('ProductiveStart',date);
        }else{
            localStorage.setItem('ProductiveEnd',date);
        }
        
               
       };   

    function handleSiteListClick() {
        props.history.push('/productivity')
    }


    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id);
    
        if (doc) {
          doc.style.display = "block";
        }
      };

      const handleMouseLeave = (id) => {
        let doc = document.getElementById(id);
    
        if (doc) {
          doc.style.display = "none";
        }
      };

    useEffect(()=>{
        if(permission && permission.length > 0){
        let requestBody={}
        // requestBody.startdate= getDateFormat(dates.start) ;
        // requestBody.enddate=getDateFormat(dates.end);
        requestBody.startdate= getDateFormat(selectedDate) ;
        requestBody.enddate=getDateFormat(selectedDate);
        requestBody.worker_id = idVal;

        requestBody.slug=slug
        SetLoaderWidget(true)
        getPproductivityWidget(requestBody,userSession,org_id,sub_org_id).then(res=>{
            if(res.status==200){
                updateemployeeData(res.data);

            }
            SetLoaderWidget(false)
        })
    }
    },[selectedDate,permission])

    // useEffect(()=>{
    //     if(permission && permission.length > 0){
    //     ShowCardLocation();
    //     //ShowCardAcess();
    //     ShowCardUnProductive();
    //     setTimeout(function(){
    //         SetLoaderWidgetBottom(false)
    //     },500)

    //     }
    // },[employeeData,permission])

    

      const handleSortunproductive = (key) => {

        setSortKeyunproductive(key)
        setSortTypeunproductive(sortTypeunproductive === 'desc' ? 'asc' : 'desc')
        
        setSortKey(key)
        setSortType(sortTypeunproductive)

      }


      const handleSortlocationVisited = (key) => {
    
        setSortKeylocationVisited(key)
        setSortTypelocationVisited(sortTypelocationVisited === 'desc' ? 'asc' : 'desc')
        setSortKey(key)
        setSortType(sortTypelocationVisited)
    
    
      }
    const handleSortAcess = (key )=>{
    
        setSortKeyacess(key)
        setSortTypeacess(sortTypeacess === 'desc' ? 'asc' : 'desc')
        setSortKey(key)
        setSortType(sortTypeacess)
        
    }

    function capitalizeFirstLetter(string) {
        return string.toLowerCase();
    }

    
    function handleClickCard(id) {
      //   history.push(`/site-list/view/:${id}`)
           
     }
     const timeComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {



        let aMs = moment(valueA, 'HH:mm:ss').format('x')
        let bMs = moment(valueB, 'HH:mm:ss').format('x')
            if(valueA=='na' || valueB=='na')return 0;
            if (valueA == valueB) return 0;
            return (aMs > bMs) ? 1 : -1;       
     };

    
    function RedirectSiteManagement(tag_serial){

       // props.history.push(`/site-list/view/:${tag_serial}`)
    }

    
    function StatusTypeRender(params){
        var eGui = document.createElement('div');

        if(params.data.type=='non_productive'){
            eGui.innerHTML = `<span class='danger productiveTable'>${params.value}</span>`
        }else if(params.data.type=='less_productive'){
            eGui.innerHTML = `<span class='warning productiveTable'>${params.value}</span>`
        }else{
            eGui.innerHTML = `<span class='green productiveTable'>${params.value}</span>`
        }

         return eGui;
    }



  

    useEffect(() => {

        let idVal = props.match.params.id.replace(":", "")

        if (idVal) {
            let date = getDateFormat(selectedDate)

            
        }

    }, [selectedDate]);


// function AddZeroFormatter(value){
//   let sp =value.toString().split('.');
//   let inital=parseInt(sp[0])?parseInt(sp[0]):0;
//   let end= parseInt(sp[1])?parseInt(sp[1]):0;
//   // if(parseInt(sp[0]) < 10){
//   //   inital ='0'+ parseInt(sp[0]);
//   // }
//   if(parseInt(sp[1]) < 10){
//     end ='0'+ parseInt(sp[1]);
//   }
  
//   return parseFloat(inital+"."+end);
//   //return value;
// }
function AddZeroFormatter(value){
  let sp =value.toString().split('.');
  let inital=parseFloat(sp[0])?parseFloat(sp[0]):0;
  let end= parseFloat(sp[1])?parseFloat(sp[1]):0;
  
  if(parseFloat(sp[1]) < 10 && sp[1].length == 2){
    end ='0'+ parseFloat(sp[1]);
  }else{
    end = parseFloat(sp[1])+'0';
  }
  return parseFloat(inital+"."+end);
  // return parseFloat(value.toFixed(2));
  
}

    

    function getDateFormat(date) {
        return moment(date).format('YYYY-MM-DD')
    }

  
  
   async function handleDateSelect(date) {
        
        setTimeout(function(){
            updateSelectedDate(date);
            
        SetSelectBarTime(date);
        },100)
        setSelectedDateDisplay(getDateFormat(date));
        SetErrorNotCheckIn("")
      
                    let requestBody={}
            requestBody.start_date = getDateFormat(date);
            requestBody.end_date= moment(date).add(1,"days").format('YYYY-MM-DD');
            //SetIDVal(thatDay.tag_serial)
            setSelectedDate(getDateFormat(date))
        localStorage.setItem('ProductiveStart',date);
        localStorage.setItem('ProductiveEnd',date);
    
  }

    function changeLanguage(lang) {
        getLanguageTranslation(lang).then(res => {
            if (res && res.status >= 200 && res.status <= 200) {
                localStorage.setItem('languageData', JSON.stringify(res.data))
                localStorage.setItem('selectedLanguage', lang)
                props.setSelectedLanguage(lang)

            }
        })
    }



    useEffect(() => {
        if (props.language) {
            updateSelectedLangValue(props.language)
        }
    }, [props.language])

    function RedirectUser(data){

        //props.history.push(`/site-list/view/:${data.location_serial}`)

    }

    function DisplayToolTipData(){

        let data = selectedSeries;
        let arr=[];
        if(data.data!=null){

        
        Object.keys(data.data).forEach((key,i)=>{
       arr.push(
        <Row style={{ alignItems: 'center' }} key={"data"+i} onClick={()=>RedirectUser(data.data[key])}>
        <Col className="b-r" style={{ maxWidth: "70%",minWidth:"70%" }} >
            <b className="font-bold">{capitalizeFirstLetter(key)}</b>
        </Col>
        <Col className="" style={{ maxWidth: "30%",minWidth:"30%" }} >
            {/* <div className="priSriMriText">Spread Index</div> */}
            <h6 className="font-bold">{data.data[key] ? data.data[key] + '' : '-'}</h6>
        </Col>
    </Row>

    )
        })
    }

        return arr;
    }

    function onChangeStartTime(val){

        let dept= activeHour;
      
        if(val.indexOf('all')> -1){
            let value =dept;
            if((val.length -1) == value.length ){
              SetStartTime([])
            }else{
              SetStartTime(value);
            }
        }else{
          SetStartTime(val);
        }
      }


    function ShowDataTabActive(){
        let arr2=[];
        if(activeTab=='productivity'){
            arr2= productivity
        }else if(activeTab=='lessproductivity'){
            arr2= lessproductivity
        }else{
            arr2= nonproductivity
        }
    
        let arr=
            <div className="siteWidgetTable siteWidgetTable2 col-lg-12"><div className='nochildAGTab'><div className="ag-theme-alpine if cell-size-35" style={{height: 35 + ((arr2.length)*35), maxHeight:250  , width: "100%"}}>
        <AgGridReact
                 rowHeight={35}
                 headerHeight={35}
                 groupHeaderHeight={35}

                // defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:135}}
                defaultColDef={{sortable: true,resizable: true,minWidth:45,flex:1,
                suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                 rowData={arr2}
                 key={"AGTableUnprotected"}
                 onCellClicked={ (event) => handleClickCard(event.data.tag_serial) }
                 
                 overlayNoRowsTemplate={         
                     '<span class="ag-overlay-no-rows-center">No data Found !</span>'
                   }

         >
        <AgGridColumn
            field="name"
            headerName={"Location"}
           valueFormatter={(params)=>capitalizeFirstLetter(params.value) } 
        />
         
        </AgGridReact>
    </div></div></div>
    return arr;
    }



      // Callback function to receive the selected date from BarChart
      const handleDateSelected = (date) => {
        // SetSucessMessage(false);
        // SetLoaderEmp(true);
        SetSpace(false)
        
         setSelectedDateDisplay(getDateFormat(date));
         controller.abort();
        
          SetErrorNotCheckIn("")
          setSelectedDate(date);


      };


        return (
            <>{permissionAPICall?
            permission.length > 0?
            <div className="siteViewMainDiv manpowerManagementMainDiv productivityManagementDiv">
                <div className='employeeDetailsMainDiv'>
                <ToastContainer
                    autoClose={5000}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnFocusLoss={false}
                    pauseOnHover={true}
                    width={"500px"}
                />
                <Container className='header-breadcrumb'>
                <Row>
                    <Col lg={6} className={'m-t-sm'}>
                        <div className="siteViewHeaderDiv">
                            <span className="smallHeader" onClick={handleSiteListClick}>{getTranslatedText('Productivity')}</span>
                            <span className="breadCrumbArrow"> &gt; </span>
                            <span className="mediumHeader">{getTranslatedText('Employee detail')}</span>
                        </div>
                    </Col>
                    <Col lg={6} className="text-right">
         
                <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDivParent">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                                dontSave={true}
                                                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
                            />
                        </div>

                        
                    </Col>
                    </Row>
                <div className='manpowerManagementEmployeeListMainDiv manpowerManagementEmployeeListProductivity m-t-lg'>
                  
                    <Row className="">

                        <Col lg={3} className="bdrAbs tabletfull">
                            <h5>Profile</h5>
                            <div className="siteViewDetailsLeftSideDiv" >
                                <div className="headerNameDiv">{individualInfo!=null?individualInfo.name:"-"}</div>
                                <div className="subHeaderDiv">{individualInfo!=null?individualInfo.department:"-"}</div>
                                         <div className="separaterLine" style={{margin:"5px 0px"}}></div>

                                <div className="batteryMainDiv batteryMainDiv2 m-t-lg" style={{overflow:"auto"}}>
                                <div style={{float:"left"}}><img src={batteryIcon} /></div> 
                                    <div style={{float:"left",lineHeight:"20px"}}>&nbsp;{individualInfo.battery_status}{individualInfo.battery_status=='na'? '' : '%'} ({individualInfo.tag_serial})<br/>
                                    </div>
                                </div>
                                <div className="recommendMainDiv">
                                    {/* <h5 className="font-bold text-white">{getTranslatedText('Recommend')}</h5> */}

                                    <div className="tab-view tab-view-2">
                                            <div className={activeTab=='productivity'?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt"}  onClick={()=>SetActiveTab('productivity') }>Productivity</div>
                                            <div className={activeTab=='nonproductivity'?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt"} onClick={()=>SetActiveTab('nonproductivity') }>Non Productivity</div>
                                            <div className={activeTab=='lessproductivity'?"tab tab-activity cursor-pt active":"tab tab-activity cursor-pt"} onClick={()=>SetActiveTab('lessproductivity') }>Less Productivity</div>
                                        </div>
                                    {ShowDataTabActive()}
                                </div>

                            </div>
                        </Col>
                        <Col lg={9} className='left-no-space-resp tabletfull'>

                            <div className="siteViewRightSideDiv">
                            
                                <Row className='responsiveSpace'>
                                    <Col lg={4} className=''>
                                        
                                        <h5>Activity: <strong className='styledDate'>{moment(selectedDateDisplay).format('DD MMM YYYY')}</strong></h5>   
                                        <div className='align-right ct-data ct-data-site ct-data-site-2 pos-relative-data' style={{width:"100%",left:"0px"}}>
                                            <div className="ct-graph-data ct-graph-data-false" style={{marginTop:"10px",float:"left",left:"0px" }}>
                                                    <Row  style={{ alignItems: 'center' }}>
                                                        <Col className="b-r" style={{ maxWidth: "40%",minWidth:"40%" }} >
                                                            <b className="font-bold" style={{fontWeight:"bold"}}>Shift 
                                                            {/* <span className='viewIcon' style={{marginLeft:"10px"}} onClick={()=> window.open(`/employee-tracker/${idVal}/all`, "_blank")}><Route/></span>  */}
                                                            </b>
                                                        </Col>
                                                        <Col className="b-r col" style={{ maxWidth: "40%",minWidth:"40%" }} >
                                                            {/* <div className="priSriMriText">Spread Index</div> */}
                                                            <h6 className="font-bold" style={{fontWeight:"bold"}}>{shiftStartTime} - {shiftEndTime}</h6>
                                                        </Col>
                                                        <Col className="" style={{ maxWidth: "20%",minWidth:"20%" }} >
                                                           <h6 className="font-bold" style={{fontWeight:"bold"}}>{shiftHourWorking}</h6>
                                                        </Col>

                                                    </Row>
                                                    <Row className='text-whiteSpace' style={{ alignItems: 'center' }}>
                                                        <Col className="b-r" style={{ maxWidth: "40%",minWidth:"40%",lineHeight:1.1 }} >
                                                            <b className="font-bold" style={{lineHeight:1.3}}>Total Working Hour</b>
                                                        </Col>
                                                        <Col className="b-r col" style={{ maxWidth: "40%",minWidth:"40%" }} >
                                                           <h6 className="font-bold">{cmlWorkingHour}</h6>
                                                        </Col>
                                                        <Col className="" style={{ maxWidth: "20%",minWidth:"20%" }} >
                                                           <h6 className="font-bold">{workingPercent==''?'0 %':workingPercent+' %'}</h6>
                                                        </Col>
                                                        
                                                    </Row>
                                                    <Row className='text-whiteSpace' style={{ alignItems: 'center' }}>
                                                        <Col className="b-r" style={{ maxWidth: "40%",minWidth:"40%",lineHeight:1.1 }} >
                                                            <b className="font-bold" style={{lineHeight:1.3}}>Total Wasted Hour</b>
                                                        </Col>
                                                        <Col className="b-r col" style={{ maxWidth: "40%",minWidth:"40%" }} >
                                                           
                                                            <h6 className="font-bold">{cmlNonWorkingHour}</h6>
                                                        </Col>
                                                        <Col className="" style={{ maxWidth: "20%",minWidth:"20%" }} >
                                                           <h6 className="font-bold">{nonworkingPercent==''?'0 %':nonworkingPercent+" %"}</h6>
                                                        </Col>
                                                    </Row>
                                            </div>
                                        </div>         

                                    </Col>
                                    <Col lg={4} className='donutChart'>
                
                <div className='donut-inner'>
                {donutLoader?
                            <div className="text-center">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> :<>
                    <div className="wt-100">
                      {/* <h6>Productivity</h6> */}
                    </div>
                    <div className='donutInnerTable'>
                    <div className="wt-70"><ReactHighcharts config={configDonut} key={'donutchart'} /></div>
                    <div className="wt-30">
                        <div className='legendarea'>
                            <div className='legendList'><span style={{background:"#90cf4e"}}></span > <span className='textTitle'>Productive</span></div>
                            <div className='legendList'><span style={{background:"#fdbf00"}}></span> <span className='textTitle'> Less Productive</span></div>
                            <div className='legendList'><span style={{background:"#fc0001"}}></span> <span className='textTitle'>Non Productive</span></div>
                            <div className='legendList'><span style={{background:"#dedee3"}}></span> <span className='textTitle'>Out of Range</span></div>
                        </div>
                    </div>
                    </div>
                    
                    </>}
                </div>
            </Col> 
                                    
                                    <Col lg={4}  className="badgeArea" style={{textAlign:"center"}} >
                                        <div className='badgeOuter'>
                                        {performancePercent<60 && !chartLoader?
                                        <><img src={notAvaialble} className="notAvailable" style={{maxWidth:"100px",float:"none"}} /><h6>Badge Not Available</h6></>:""}
                                        {performancePercent>=60 && performancePercent<80 && !chartLoader?<><img src={SilverBadge} style={{maxWidth:"100px",float:"none"}} /><h6>Silver Badge</h6></>:""}
                                        {performancePercent>80 && !chartLoader ? <><img src={GoldBadge} style={{maxWidth:"100px",float:"none"}} /><h6>Gold Badge</h6></>:""}
                                    </div>
                        
                        
                    </Col>

                                </Row>
                                <Row className='responsiveSpace'>

                                       
            <Col lg={8} className=''>
            {chartLoader?
                            <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> :<>
                                <div className='StackCHartSelect'>
                        <div className={helpEnable?"helpIconAnimate active":"helpIconAnimate"} onClick={()=>SetHelpEnable(!helpEnable)}><HelpIcon/>{helpEnable?<div className="helpToolTip">Hide Value</div>:<div className="helpToolTip">Display Value</div>}</div>            
                                    {/* <Switch checkedChildren="Enable" unCheckedChildren="Disable" defaultChecked checked={activeTabShift} onChange={(val)=>SetActiveTabShift(val)} /> */}
                                </div>
                    <div className="stackChart">
                        
                         { 
                    errorNotCheckin!=""?
                    <h5 className='errorStyle' >{errorNotCheckin}</h5>:

                    <ReactHighcharts config={config} key={'Stackedchart'}  /> }
                 </div></>}
            </Col>
            <Col lg={4} className='align-right ct-data ct-data-site pos-relative-data'>
            <div className="ct-graph-data ct-graph-data-false " key={"selectedData"} 
                        style={{height:selectedSeries.data!=null?selectedSeries.data.length> 0?((selectedSeries.data.length+ 2 )*25):"":"",width:"100%",left:"0px" }}
                        >
                            
                        <div>
                         <div className='smaller-header'>       
                         <div className='header-left' style={{width:"55%"}}>           
                         {selectedSeries.name}
                         
                        </div>
                        <div className='header-right' style={{width:"45%"}}><strong style={{fontWeight:"500"}}>{selectedSeries.time}
                          </strong>
                          {/* {selectedSeries.time?<span className='viewIcon' style={{marginLeft:"10px"}} onClick={()=> window.open(`/employee-tracker/${idVal}/${cat2[categories.indexOf(selectedSeries.time)]}`, "_blank")}><Route/></span>:""} */}
                          </div>                         
                        </div>
                            <Scrollbars style={{height:"225px"}}>
                                {DisplayToolTipData() }
                             </Scrollbars></div>

                        </div>
            </Col>

                                </Row>

                            </div>
                        </Col>
                    </Row>
                </div>    
                <div className='manpowerManagementEmployeeListMainDiv manpowerManagementEmployeeListProductivity' style={{marginTop:"10px"}}>
                    <Row style={{ minHeight:"275px" }}>
                        <Col lg={6} className="bdrAbs tabletfull"  ref={element2}>
                            <div className='topHeaderArea'>
                                <Row>
                                    <Col lg={3}>
                                    <h5>Movement</h5>
                                    </Col>
                                    <Col lg={6}>

                                    {space?loaderEmp? 
                                    <div className='loaderStyle loaderStyle2' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4> 
                    
                    

                  </div></div>:sucessMessage?<div className='loaderStyle loaderStyle2 loadStyleSucess' style={{background:"#fff",paddingLeft:"12px"}}><div className="loadingInfo">
                    <h4>Ready</h4> 
                    
                    

                  </div></div>:<div className='loaderStyle loadStyleError' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>Oops! This Hour data not available</h4> 
                  </div></div>:""}


                                    </Col>
                                    <Col lg={3}>
                                    <div className='inlineBlockFilterRight'>
                                            <Select
                                                placeholder="Select Time"
                                                optionFilterProp="children"
                                                style={{width:150}}
                                                onChange={(val)=>{onChangeStartTime(val);}}
                                                value={start_time}
                                                key="EndTimeV1"
                                                dropdownClassName={'smallerDrop'}
                                                //disabled={shiftStarttime==''||ShiftEndtime==''}
                                                mode={'multiple'}
                                            >
                                            <Option value='all' key={"All shift Hour v1 All"}>All Shift Hour</Option>
                                                {
                                                activeHour.map((item)=>{

                                                    return <Option value={item} key={"Employee V2 Active Hour"+item}>{item+":00"}</Option>
                                                })
                                                }
                                                
                                            </Select>
                                            </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                            {
                                chartLoader?                            <div className="text-center m-t-lg">
                                <img src={spinnerLoader} className="m-t-lg" />
                            </div>:mapboxacess?<MapboxTrackerDetail 
                                {...props}
                                activeHourDropdown={activeHourDropdown}
                                date={selectedDate1}
                                employeeID={idVal}
                                
                                productivity={productivity}
                                lessproductivity={lessproductivity}
                                nonproductivity={nonproductivity}
                                tag_serial={tag_serial}
                                // start_time = {[categories.indexOf(selectedSeries.time)]}
                                start_time = {start_time}             
                                startBigger={startBigger} 
                                selectedSeries = {selectedSeries}
                                selectedDate={selectedDate1}
                                loaderEmp={loaderEmp}
                                loaderStart={()=>SetLoaderEmp(true)}
                                loaderEnd={()=>SetLoaderEmp(false)}
                                SetLoaderEmpHandler={SetLoaderEmpHandler}
                            /> :
                            <EmployeeTracker
                                permission={permission}
                                activeHourDropdown={activeHourDropdown}
                                date={selectedDate1}
                                employeeID={idVal}
                                productivity={productivity}
                                lessproductivity={lessproductivity}
                                nonproductivity={nonproductivity}
                                tag_serial={tag_serial}
                                // start_time = {[categories.indexOf(selectedSeries.time)]}
                                start_time = {start_time}             
                                startBigger={startBigger} 
                                selectedSeries = {selectedSeries}
                                selectedDate={selectedDate1}
                                SetLoaderEmpHandler={SetLoaderEmpHandler}


                             />
                            }                    
                       </div>
                        </Col>
                        <Col lg={6} className='tabletfull'>
                            <Row>
                                <Col lg={5}><h5>Performance</h5></Col>
                                <Col lg={4}>
                                    <div className="tabDiv" style={{textAlign:'right'}}>
                                        <div className='performanceType'>
                                            <div onClick={()=>SetPerformanceType('day') } className={performanceType=='day'?'tabBtn tab-transparent activePerformance active':'tabBtn tab-transparent'}>Daily</div>
                                            <div onClick={()=>SetPerformanceType('week') } className={performanceType=='week'?'tabBtn tab-transparent activePerformance active':'tabBtn tab-transparent'}>Weekly</div>
                                        </div>
                                    </div>

                                 </Col>
                                 <Col lg= {3}>

                                 <Select
                                            placeholder="Select Month"
                                            optionFilterProp="children"
                                            style={{width:100}}
                                            onChange={(val,label)=>{
                                              
                                                SetPerformanceYear(label.year);
                                              
                                                SetPerformanceMonth(label.month);
                                                SetPerformanceMonthDisplay(val);   
                                            }
                                            }
                                            value={performanceMonthDisplay}
                                            key="performanceMonthEndTimeV1"
                                            dropdownClassName={'smallerDrop smallerDroplessWidth'}
                                            //disabled={shiftStarttime==''||ShiftEndtime==''}
                                            
                                        >
                                        <Option value='' key={" performanceMonth All shift Hour v1 All"}>Select</Option>
                                            {
                                            month.map((item)=>{
                                                return <Option value={item.intMonth+"|"+item.year} month={item.intMonth} year={item.year} key={"month Employee V2 Active Hour"+item.month+item.year}>{item.month+" "+item.year}</Option>
                                            })
                                            }          
                                    </Select>
                                 </Col>
                            </Row>
                        {emptyPerform!=""?<div className='errorMsg'>Data Not Available</div>:""}

                        {loadingPerformance?<div className="text-center m-t-lg">
                                <img src={spinnerLoader} className="m-t-lg" />
                            </div>:<BarChartPerformance
                                            categories={performanceChart.categories}
                                            series={performanceChart.series}
                                            seriesName={"Performance"}
                                            performanceType={performanceType}
                                            height={mapboxacess? trackerHeight > 400?trackerHeight:400:trackerHeight}
                                            onDateSelected={handleDateSelected}
                                            selectedDate={selectedDate1}
                                            activeMonth={month.find(item=>item.intMonth  == performanceMonth)}
                                            // onDateSelected={handleDateSelected}
                                        />}
                                        



                        </Col>
                      </Row>
                  
                   
                    </div>
                    {userDetails.org_id==36?
                    <div className='manpowerManagementEmployeeListMainDiv manpowerManagementEmployeeListProductivity' style={{marginTop:"10px"}}>
                    <Row style={{ minHeight:"275px" }}>
                        <Col lg={6} className="bdrAbs tabletfull"  ref={element2}>
                            <div className='topHeaderArea'>
                                <Row>
                                    <Col lg={12}>
                                    <h5>Temperature (°C)</h5>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                            {
                                temperatureLoader?                            <div className="text-center m-t-lg">
                                <img src={spinnerLoader} className="m-t-lg" />
                            </div>:<><ReactHighcharts config={temperatureChart} key={'StackedchartTemperature'}  /> </> 
                            }                    
                       </div>
                        </Col>
                        <Col lg={6} className='tabletfull'>
                            <Row>
                                <Col lg={5}><h5>Activity</h5></Col>
                                <Col lg={4}>
                                    <div className="tabDiv" style={{textAlign:'right'}}>
                                        
                                    </div>

                                 </Col>
                                 <Col lg= {3}>

                                 </Col>
                            </Row>

                        {motionLoader?<div className="text-center m-t-lg">
                                <img src={spinnerLoader} className="m-t-lg" />
                            </div>:
                            <ReactHighcharts config={motionChart} key={'StackedchartTemperature'}  />
}
                                        



                        </Col>
                      </Row>
                  
                   
                    </div>:""}
                </Container>
            </div>
            </div>:<Col><div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
            </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                    <img src={spinnerLoader} />
                                </Col>}</>
        )
    }




const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, { setSelectedLanguage })(withRouter(ProductivityDetails))


