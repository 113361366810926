import axios from "axios";
let prefixURL = process.env.REACT_APP_URL;

//date=2024-11-20&org_id=36&sub_org_id=0&session=trace123456789
export function getJobCardList(sessionID, org_id,sub_org_id,date,) {    
    return axios.get(prefixURL + `/periscope/get_jobcard_list?session=${sessionID}&date=${date}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getJobCardLog(sessionID, org_id,sub_org_id,job_id) {    
    return axios.get(prefixURL + `/periscope/job_card_log?session=${sessionID}&job_id=${job_id}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getJobCardDetail(sessionID, org_id,sub_org_id,tag_serial) {    
    return axios.get(prefixURL + `/periscope/get_jobcard_details?session=${sessionID}&tag_serial=${tag_serial}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function updateJobCardStart(requestBody){   
        return axios.post(prefixURL + `/periscope/update_job_start`,requestBody)
        .then(res => res.data).catch(err => err)
}
export function updateJobCardStop(requestBody){   
        return axios.post(prefixURL + `/periscope/update_job_stop`,requestBody)
        .then(res => res.data).catch(err => err)
}
export function createJobCard(requestBody){   
        return axios.post(prefixURL + `/periscope/create_jobcard`,requestBody)
        .then(res => res.data).catch(err => err)
}

export function updateJobCard(requestBody){   
        return axios.post(prefixURL + `/periscope/update_jobcard`,requestBody)
        .then(res => res.data).catch(err => err)
}

